import React, { useContext, useState, useEffect } from 'react'

import ClaroPayContext from '../../context/ClaroPayContext'

import CarouselSwiper from '../../components/CarouselSwiper'
import SelectedOffer from '../../components/SelectedOffer'
import PaymentButton from '../../components/PaymentButton'
import MoreOptions from '../../components/MoreOptions'
import Categories from '../../components/Categories'
import NextButton from '../../components/NextButton'

import ClaroPayPaymentOptions from '../ClaroPayPaymentOptions'
import ClaroPayPaymentOptionsWithBi from '../ClaroPayPaymentOptionsWithBi'

import Country from '../../constants/Country'

const ClaroPruebas = () => {
  const claroPayContext = useContext(ClaroPayContext)

  const {
    pruebasData,
    clearData,
    isVisibleSectionAddNewCard,
    isEnabledPayWithSavedCard,
    isEnabledPayWithNewCard,
    selectAccountBi,
    iIsVisibleAddNewAccountBi,
    canClickButton,
  } = claroPayContext

  const { countryCode } = Country()

  const [state, setState] = useState({
    packs: [],
    pack: {
      amount: 0,
      currency: 0,
      subtitle: '',
      validity: '',
      socialMedia: {
        title: '',
        icons: [],
      },
      description: [],
      mainDescription: '',
    },
    topup: [],
    bundle: [],
    currentPosition: 0,
    currentCategory: 'bundle',
    selectedProduct: false,
    indicator: false,
  })

  const [currentRealIndex, setCurrentRealIndex] = useState(0)
  const [activeSwiper, setActiveSwiper] = useState(false)

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setActiveSwiper(true)
    }, 1)
    return () => clearTimeout(timeoutId)
  }, [])

  useEffect(() => {
    const arrProducts = pruebasData.products

    const bundle = arrProducts
      .filter(product => product.productType === 'BUNDLE')
      .toSorted((a, b) => b.amount - a.amount)

    let topup
    if (countryCode !== '504') {
      topup = arrProducts
        .filter(
          product =>
            product.productType === 'TOPUP' && product.productId !== 'TOPUP200',
        )
        .toSorted((a, b) => b.amount - a.amount)
    } else {
      topup = arrProducts
        .filter(product => product.productType === 'TOPUP')
        .toSorted((a, b) => b.amount - a.amount)
    }

    const pack = bundle[0]
    const packs = bundle
    const currentPackages = pack.productType === 'BUNDLE' ? bundle : topup
    const currentCategory = pack.productType === 'BUNDLE' ? 'bundle' : 'topup'
    const currentPosition = currentPackages.indexOf(pack)

    setState(prevState => ({
      ...prevState,
      bundle,
      topup,
      pack,
      packs,
      currentPackages,
      currentCategory,
      currentPosition,
    }))
  }, [pruebasData])

  const handleSlideChange = swiper => {
    const newRealIndex = swiper.realIndex
    if (newRealIndex !== currentRealIndex) {
      setCurrentRealIndex(newRealIndex)
      const pack = state.packs[newRealIndex]
      setState(prevState => ({
        ...prevState,
        pack,
        currentPosition: newRealIndex,
      }))
    }
  }

  const handleSelectedProduct = () => {
    setState(prevState => ({
      ...prevState,
      selectedProduct: !prevState.selectedProduct,
    }))

    clearData()
  }

  const handleCategory = category => {
    setCurrentRealIndex(0)
    setActiveSwiper(false)
    setTimeout(() => {
      setActiveSwiper(true)
    }, 1)
    const packCategory = category === 'bundle' ? state.bundle : state.topup
    setState(prevState => ({
      ...prevState,
      currentCategory: category,
      currentPosition: 0,
      pack: packCategory[0],
      packs: packCategory,
    }))
  }

  const {
    packs,
    bundle,
    pack,
    currentCategory,
    selectedProduct,
    indicator,
  } = state

  const textBtn = `Pagar ${pack.currency}${pack.amount}`

  return (
    <div className="claro__container__pruebas">
      {!selectedProduct ? (
        <>
          <Categories
            currentCategory={currentCategory}
            handleCategory={handleCategory}
            bundle={bundle}
          />

          {activeSwiper && (
            <CarouselSwiper
              selectedProduct={selectedProduct}
              handleSlideChange={handleSlideChange}
              pack={pack}
              packs={packs}
              currentCategory={currentCategory}
            />
          )}

          <NextButton
            textBtn="Continuar"
            onClick={handleSelectedProduct}
            enabled
          />
        </>
      ) : (
        <>
          <SelectedOffer pack={pack} currentCategory={currentCategory} />

          <MoreOptions handleSelectedProduct={handleSelectedProduct} />

          {countryCode === '507' ? (
            <ClaroPayPaymentOptionsWithBi />
          ) : (
            <ClaroPayPaymentOptions />
          )}

          <PaymentButton
            selectAccountBi={selectAccountBi}
            iIsVisibleAddNewAccountBi={iIsVisibleAddNewAccountBi}
            indicator={indicator}
            countryCode={countryCode}
            textBtn={textBtn}
            handlePaymentNewAccountBi={null}
            handlePaymentAccountBi={null}
            canClickButton={canClickButton}
            handlePayment={null}
            isVisibleSectionAddNewCard={isVisibleSectionAddNewCard}
            isEnabledPayWithNewCard={isEnabledPayWithNewCard}
            isEnabledPayWithSavedCard={isEnabledPayWithSavedCard}
          />
        </>
      )}
    </div>
  )
}

export default ClaroPruebas
