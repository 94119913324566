import localRoutes from '../../constants/routes'

const handleRedirectHome = () => {
  const {
    routesClaroPay: { claroHome },
  } = localRoutes

  window.location.href = window.location.origin + claroHome
}

export default handleRedirectHome
