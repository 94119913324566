import React, { useContext, useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'

import ClaroPayContext from '../../context/ClaroPayContext'

import Country from '../../constants/Country'
import localRoutes from '../../constants/routes'
import {
  MSISDN_MINECO,
  AUTH_TOKENS_MINECO,
} from '../../constants/ClaroPayConstants'

import ClaroPayPaymentOptionsWithBi from '../ClaroPayPaymentOptionsWithBi'
import ClaroPayPaymentOptions from '../ClaroPayPaymentOptions'

import PaymentButton from '../../components/PaymentButton'
import { generarUuid } from '../../components/GenerarUuid'
import useTouchHandler from '../../components/UseTouch'
import LoadingScreen from '../../components/LoadingScreen'
import SelectedOffer from '../../components/SelectedOffer'
import CarouselControls from '../../components/CarouselControls'

import { clearLocalStorage, setLocalStorage } from '../../services/storage'

const ClaroBecasMineco = () => {
  const history = useHistory()

  const claroPayContext = useContext(ClaroPayContext)

  const { countryCode } = Country()

  const {
    isVisibleSectionAddNewCard,
    isEnabledPayWithSavedCard,
    isEnabledPayWithNewCard,
    setProcessingPayment,
    setIsVisibleSectionAddNewCard,
    setResponseService,
    isValidatedFieldsToPayWithNewCard,
    isValidatedFieldsToPayWithSavedCard,
    minecoBecasData,
    setMinecoBecasData,
    setMinecoBecasPackSelected,
    payWithSavedCardMineco,
    payWithNewCardMineco,
    getMinecoBecasData,
    selectAccountBi,
    iIsVisibleAddNewAccountBi,
    setIsVisibleAddNewPaymentMethod,
    payWithNewAccountBi,
    payWithAccountBiMineco,
    setIsVisibleAddNewAccountBi,
    canClickButton,
    setCanClickButton,
  } = claroPayContext
  const {
    routesClaroPay: { claroPayPaymentProcess },
  } = localRoutes

  const [state, setState] = useState({
    currentPosition: 0,
    currentCategory: 'bundle',
    pack: {
      amount: 0,
      currency: 0,
      subtitle: '',
      validity: '',
      socialMedia: { icons: [] },
      description: [],
      mainDescription: '',
    },
    topup: [],
    bundle: [],
    msisdn: '',
    creditCardList: [],
    alias: [],
    indicator: false,
  })

  const [isLoading, setIsLoading] = useState(false)

  const setDefaultValues = (obj, key, defaultValue) => {
    if (
      typeof obj[key] === 'undefined' ||
      (typeof obj[key] !== 'object' && !obj[key])
    ) {
      obj[key] = defaultValue
    }
  }

  useEffect(() => {
    setIsLoading(true)
    getMinecoBecasData()
      .then(response => {
        const { responseContent } = response

        setDefaultValues(responseContent, 'creditCardList', [])
        setDefaultValues(responseContent, 'alias', [])
        setDefaultValues(responseContent, 'indicator', false)

        const {
          products,
          creditcards,
          msisdn,
          alias,
          indicator,
        } = responseContent

        setMinecoBecasData({
          products: products,
          creditCardList: creditcards,
          msisdn: msisdn,
          alias: alias,
          indicator: indicator,
        })
        setLocalStorage(MSISDN_MINECO, msisdn)
        setMinecoBecasPackSelected(products[0])

        setState(prevState => ({
          ...prevState,
          bundle: products,
          msisdn: msisdn,
          pack: products[0],
          currentPackages: products,
          creditCardList: creditcards,
          alias: alias,
          indicator: indicator,
        }))
        setIsLoading(false)
      })
      .catch(error => {
        clearLocalStorage(AUTH_TOKENS_MINECO)
        clearLocalStorage(MSISDN_MINECO)
        window.location.href = window.location
      })
  }, [])

  const handleNextProduct = action => {
    const { currentPosition, bundle } = state

    const totalItems = bundle.length

    let position = currentPosition

    if (action === 'next') {
      position = (currentPosition + 1) % totalItems
    } else if (action === 'prev') {
      position = (currentPosition - 1 + totalItems) % totalItems
    }

    setMinecoBecasPackSelected(bundle[position])

    setState(prevState => ({
      ...prevState,
      currentPosition: position,
      pack: bundle[position],
    }))
  }

  const { handleTouchStart, handleTouchMove, handleTouchEnd } = useTouchHandler(
    handleNextProduct,
  )

  const handlePayment = async () => {
    setCanClickButton(false)
    setResponseService(null)
    const uuid = generarUuid()
    setProcessingPayment(true)
    setIsVisibleSectionAddNewCard(false)
    history.push(claroPayPaymentProcess)
    let response
    const isPaymentValid = isVisibleSectionAddNewCard
      ? isValidatedFieldsToPayWithNewCard()
      : isValidatedFieldsToPayWithSavedCard()

    if (isPaymentValid) {
      response = isVisibleSectionAddNewCard
        ? await payWithNewCardMineco(state.pack.productId, uuid)
        : await payWithSavedCardMineco(state.pack.productId, uuid)
    }
    setResponseService(response)
  }

  const handlePaymentAccountBi = async () => {
    setCanClickButton(false)
    setResponseService(null)
    const uuid = generarUuid()
    setProcessingPayment(true)
    setIsVisibleAddNewPaymentMethod(false)
    history.push(claroPayPaymentProcess)
    const response = await payWithAccountBiMineco(state.pack.productId, uuid)
    setResponseService(response)
  }

  const handlePaymentNewAccountBi = async () => {
    setIsVisibleAddNewPaymentMethod(false)
    setResponseService(null)
    setProcessingPayment(true)
    setIsVisibleAddNewAccountBi(true)
    history.push(claroPayPaymentProcess)
    payWithNewAccountBi(state.pack.productId)
  }

  const {
    currentPosition,
    currentCategory,
    pack,
    topup,
    bundle,
    indicator,
  } = state

  if (!pack && minecoBecasData === null) {
    return null
  }

  const textBtn = `Pagar ${pack.currency}${pack.amount}`

  const primerNodo = pack.description[0] || {}
  const segundoNodo = pack.description[1] || {}

  const primerNodoText = primerNodo.text || 'N/A'
  const primerNodoTitle = primerNodo.title || 'N/A'
  const segundoNodoText = segundoNodo.text || 'N/A'
  const segundoTitle = segundoNodo.title || 'N/A'

  const formattedText = `${primerNodoText} ${primerNodoTitle} + ${segundoNodoText} ${segundoTitle}`

  pack.mainDescription = formattedText

  return (
    <>
      {isLoading ? (
        <div className="claro__container">
          <LoadingScreen />
        </div>
      ) : (
        <div className="claro__container__becasMineco">
          <div
            className="claro__container__touch"
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            <SelectedOffer pack={pack} currentCategory={currentCategory} />

            <CarouselControls
              currentCategory={currentCategory}
              bundle={bundle}
              topup={topup}
              currentPosition={currentPosition}
              handleNextProduct={handleNextProduct}
              className="becasMineco"
            />
          </div>

          {countryCode === '507' ? (
            <ClaroPayPaymentOptionsWithBi />
          ) : (
            <ClaroPayPaymentOptions />
          )}

          <PaymentButton
            selectAccountBi={selectAccountBi}
            iIsVisibleAddNewAccountBi={iIsVisibleAddNewAccountBi}
            indicator={indicator}
            countryCode={countryCode}
            textBtn={textBtn}
            handlePaymentNewAccountBi={handlePaymentNewAccountBi}
            handlePaymentAccountBi={handlePaymentAccountBi}
            canClickButton={canClickButton}
            handlePayment={handlePayment}
            isVisibleSectionAddNewCard={isVisibleSectionAddNewCard}
            isEnabledPayWithNewCard={isEnabledPayWithNewCard}
            isEnabledPayWithSavedCard={isEnabledPayWithSavedCard}
          />
        </div>
      )}
    </>
  )
}

export default ClaroBecasMineco
