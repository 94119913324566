import React, { useContext, useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'

import ClaroPayContext from '../../context/ClaroPayContext'

import { labels } from '../../constants/ClaroPayConstants'
import localRoutes from '../../constants/routes'
import Country from '../../constants/Country'

import ClaroPayPaymentOptions from '../ClaroPayPaymentOptions'
import ClaroPayPaymentOptionsWithBi from '../ClaroPayPaymentOptionsWithBi'

import PaymentButton from '../../components/PaymentButton'
import { generarUuid } from '../../components/GenerarUuid'

import up from '../../assets/img/up-arrow.png'
import down from '../../assets/img/down-arrow.png'

const ClaroPagaTuPrestamo = () => {
  const history = useHistory()

  const claroPayContext = useContext(ClaroPayContext)

  const { countryCode } = Country()

  const { VIEW_MORE_BENEFITS, VIEW_LESS_BENEFITS } = labels

  const {
    isVisibleSectionAddNewCard,
    isEnabledPayWithSavedCard,
    isEnabledPayWithNewCard,
    setProcessingPayment,
    setIsVisibleSectionAddNewCard,
    setResponseService,
    isValidatedFieldsToPayWithNewCard,
    isValidatedFieldsToPayWithSavedCard,
    pagaTuPrestamoData,
    payWithSavedCardPagaTuPrestamo,
    payWithNewCardPagaTuPrestamo,
    selectAccountBi,
    iIsVisibleAddNewAccountBi,
    setIsVisibleAddNewPaymentMethod,
    payWithNewAccountBi,
    payWithAccountBiPagaTuPrestamo,
    setIsVisibleAddNewAccountBi,
    sendMetrics,
    code,
    canClickButton,
    setCanClickButton,
    setPagaTuPrestamoData,
    getImage,
    setCurrentCategoryMetrics,
  } = claroPayContext

  const [state, setState] = useState({
    msisdn: '',
    debt: {
      amountOfDebt: 0,
      amountPayable: 0,
      dateOfPurchase: ',',
      currency: 0,
      productDescription: '',
    },
    pack: {
      amount: 0,
      currency: 0,
      subtitle: '',
      validity: '',
      socialMedia: { icons: [] },
      description: [],
      mainDescription: '',
    },
    totalAmount: 0,
    totalAmountPayment: 0,
    alias: [],
    indicator: false,
    combo: true,
  })
  const [detallesDebt, setDetalleDebt] = useState(true)
  const [detallesCombo, setDetalleCombo] = useState(true)

  useEffect(() => {
    const { msisdn, debt, pack, alias, indicator, combo } = pagaTuPrestamoData
    let totalAmount = debt.amountPayable + pack.amount
    let totalAmountPayment = combo
      ? debt.amountPayable + pack.amount
      : debt.amountPayable

    setCurrentCategoryMetrics(combo ? 'Combo-' : 'Recarga-')

    setState(prevState => ({
      ...prevState,
      msisdn,
      debt,
      pack,
      alias,
      totalAmount,
      totalAmountPayment,
      indicator,
      combo,
    }))
  }, [state.combo])

  useEffect(() => {
    const metricsData = {
      metricType: 'home',
      metricName: 'Home',
    }

    if (code) {
      sendMetrics(metricsData)
    }
  }, [code])

  const viewMoreInfoDebt = () => {
    const metricsData = {
      metricType: 'CheckOut_',
      metricName: 'VerDetalle',
    }
    detallesDebt && sendMetrics(metricsData)
    setDetalleDebt(!detallesDebt)
  }

  const viewMoreInfoCombo = () => {
    const metricsData = {
      metricType: 'CheckOut_',
      metricName: 'VerDetalle',
    }
    detallesCombo && sendMetrics(metricsData)
    setDetalleCombo(!detallesCombo)
  }

  const {
    routesClaroPay: { claroPayPaymentProcess },
  } = localRoutes

  const metricBotonPagar = () => {
    const metricsData = {
      metricType: 'CheckOut_',
      metricName: 'BotonPagar',
    }
    sendMetrics(metricsData)
  }

  const handlePayment = async () => {
    setCanClickButton(false)
    setResponseService(null)
    const uuid = generarUuid()
    setProcessingPayment(true)
    setIsVisibleSectionAddNewCard(false)
    metricBotonPagar()
    history.push(claroPayPaymentProcess)
    let response
    const isPaymentValid = isVisibleSectionAddNewCard
      ? isValidatedFieldsToPayWithNewCard()
      : isValidatedFieldsToPayWithSavedCard()

    if (isPaymentValid) {
      response = isVisibleSectionAddNewCard
        ? await payWithNewCardPagaTuPrestamo(state.combo, uuid)
        : await payWithSavedCardPagaTuPrestamo(state.combo, uuid)
    }
    setResponseService(response)
  }

  const metricBotonPagarBi = () => {
    const metricsData = {
      metricType: 'CheckOut_',
      metricName: 'BotonPagar-Cuenta',
    }
    sendMetrics(metricsData)
  }

  const handlePaymentAccountBi = async () => {
    setCanClickButton(false)
    setResponseService(null)
    const uuid = generarUuid()
    setProcessingPayment(true)
    setIsVisibleAddNewPaymentMethod(false)
    metricBotonPagarBi()
    history.push(claroPayPaymentProcess)
    const response = await payWithAccountBiPagaTuPrestamo(state.combo, uuid)
    setResponseService(response)
  }

  const handlePaymentNewAccountBi = async () => {
    setIsVisibleAddNewPaymentMethod(false)
    setResponseService(null)
    setProcessingPayment(true)
    setIsVisibleAddNewAccountBi(true)
    metricBotonPagarBi()
    history.push(claroPayPaymentProcess)
    payWithNewAccountBi(state.combo)
  }

  const handleCombo = active => {
    if (active) {
      const metricsData = {
        metricType: 'Seleccion',
        metricName: 'CheckOut_Combo-Seleccion',
      }
      sendMetrics(metricsData)
    } else {
      const metricsData = {
        metricType: 'Seleccion',
        metricName: 'CheckOut_Recarga-Seleccion',
      }
      sendMetrics(metricsData)
    }
    setState(prevState => ({
      ...prevState,
      combo: active,
    }))
    const newPagaTuPrestamoData = {
      ...pagaTuPrestamoData,
      combo: active,
    }
    setPagaTuPrestamoData(newPagaTuPrestamoData)
    setDetalleCombo(true)
    setDetalleDebt(true)
  }

  const {
    pack,
    debt,
    combo,
    indicator,
    totalAmount,
    totalAmountPayment,
  } = state

  const icons = pack?.socialMedia?.[0]?.icons || []

  const textBtn = `Pagar ${debt.currency}${totalAmountPayment}`

  return (
    <div className="claro__container__pagaTuPrestamo">
      <div className="claro__container__pagaTuPrestamo__header">
        Selecciona la Opción para Pago de tu deuda de Claro Te Presta:
      </div>

      <div
        className={`claro__container__pagaTuPrestamo__options
            ${combo ? '' : 'inactive'}`}
        onClick={combo ? undefined : () => handleCombo(true)}
      >
        <div
          className={`claro__container__pagaTuPrestamo__options__header ${
            combo ? '' : 'inactive'
          }`}
        >
          <div className="claro__container__pagaTuPrestamo__options__header__title">
            Superpack + Pago Deuda Pendiente
          </div>
          <div className="claro__container__pagaTuPrestamo__options__header__subtitle">
            {pack.subtitle} - {pack.validity}
          </div>
        </div>

        <div className="claro__container__pagaTuPrestamo__options__body">
          {detallesCombo ? (
            <div className="claro__container__pagaTuPrestamo__options__body__noDetails">
              <div className="claro__container__pagaTuPrestamo__options__body__noDetails__title">
                Total a Pagar
              </div>
              <div className="claro__container__pagaTuPrestamo__options__body__noDetails__amount">
                {debt.currency}
                {totalAmount}
              </div>
            </div>
          ) : (
            <div className="claro__container__pagaTuPrestamo__options__body__details">
              <div className="claro__container__pagaTuPrestamo__options__body__details__debt">
                <div className="claro__container__pagaTuPrestamo__options__body__details__debt__header">
                  <div className="claro__container__pagaTuPrestamo__options__body__details__debt__header__title">
                    {debt.productDescription}
                  </div>
                  <div className="claro__container__pagaTuPrestamo__options__body__details__debt__header__amount">
                    {debt.currency}
                    {debt.amountPayable}
                  </div>
                </div>

                <div className="claro__container__pagaTuPrestamo__options__body__details__debt__body">
                  <div className="claro__container__pagaTuPrestamo__options__body__details__debt__body__title">
                    <p>Pagas tu Préstamo Pendiente</p>
                    <p>En Claro Te Presta</p>
                  </div>
                  <div className="claro__container__pagaTuPrestamo__options__body__details__debt__body__info">
                    <p>Fecha de Compra: {debt.dateOfPurchase}</p>
                    <p>
                      Monto de la Deuda: {debt.currency}
                      {debt.amountPayable}
                    </p>
                  </div>
                </div>
              </div>

              {pack && (
                <div className="claro__container__pagaTuPrestamo__options__body__details__pack">
                  <div className="claro__container__pagaTuPrestamo__options__body__details__pack__header">
                    <div className="claro__container__pagaTuPrestamo__options__body__details__pack__header__title">
                      {pack.title}
                    </div>
                    <div className="claro__container__pagaTuPrestamo__options__body__details__pack__header__amount">
                      {pack.currency}
                      {pack.amount}
                    </div>
                  </div>

                  <div className="claro__container__pagaTuPrestamo__options__body__details__pack__description">
                    {pack.subtitle} - {pack.validity}
                  </div>
                  <div className="claro__container__pagaTuPrestamo__options__body__details__pack__description">
                    {pack.mainDescription}
                  </div>

                  <div className="claro__container__pagaTuPrestamo__options__body__details__pack__socialMedia">
                    {icons.map(item => (
                      <img key={item} src={getImage(item)} alt="socialMedia" />
                    ))}
                  </div>
                </div>
              )}

              <div className="claro__container__pagaTuPrestamo__options__body__details__total">
                <div className="claro__container__pagaTuPrestamo__options__body__details__total__title">
                  Total a pagar
                </div>
                <div className="claro__container__pagaTuPrestamo__options__body__details__total__amount">
                  {debt.currency}
                  {totalAmount}
                </div>
              </div>
            </div>
          )}
        </div>

        <div
          className={`claro__container__pagaTuPrestamo__options__viewMore ${
            combo ? 'active' : 'inactive'
          }`}
          onClick={combo ? viewMoreInfoCombo : null}
        >
          <u> {detallesCombo ? VIEW_MORE_BENEFITS : VIEW_LESS_BENEFITS}</u>
          <img
            src={detallesCombo ? down : up}
            alt="arrow"
            style={{
              filter: combo ? '' : 'grayscale(100%)',
            }}
          />
        </div>
      </div>

      <div
        className={`claro__container__pagaTuPrestamo__options
          ${!combo ? '' : 'inactive'}`}
        onClick={combo ? () => handleCombo(false) : undefined}
      >
        <div
          className={`claro__container__pagaTuPrestamo__options__header ${
            !combo ? '' : 'inactive'
          }`}
        >
          <div className="claro__container__pagaTuPrestamo__options__header__title">
            Pago Deuda Pendiente
          </div>
        </div>

        <div className="claro__container__pagaTuPrestamo__options__body">
          {detallesDebt ? (
            <div className="claro__container__pagaTuPrestamo__options__body__noDetails">
              <div className="claro__container__pagaTuPrestamo__options__body__noDetails__title">
                Total a Pagar
              </div>
              <div className="claro__container__pagaTuPrestamo__options__body__noDetails__amount">
                {debt.currency}
                {debt.amountPayable}
              </div>
            </div>
          ) : (
            <div className="claro__container__pagaTuPrestamo__options__body__details">
              <div className="claro__container__pagaTuPrestamo__options__body__details__debt">
                <div className="claro__container__pagaTuPrestamo__options__body__details__debt__header">
                  <div className="claro__container__pagaTuPrestamo__options__body__details__debt__header__title">
                    {debt.productDescription}
                  </div>
                  <div className="claro__container__pagaTuPrestamo__options__body__details__debt__header__amount">
                    {debt.currency}
                    {debt.amountPayable}
                  </div>
                </div>

                <div className="claro__container__pagaTuPrestamo__options__body__details__debt__body">
                  <div className="claro__container__pagaTuPrestamo__options__body__details__debt__body__title">
                    <p>Pagas tu Préstamo Pendiente</p>
                    <p>En Claro Te Presta</p>
                  </div>
                  <div className="claro__container__pagaTuPrestamo__options__body__details__debt__body__info">
                    <p>Fecha de Compra: {debt.dateOfPurchase}</p>
                    <p>
                      Monto de la Deuda: {debt.currency}
                      {debt.amountPayable}
                    </p>
                  </div>
                </div>
              </div>

              <div className="claro__container__pagaTuPrestamo__options__body__details__total">
                <div className="claro__container__pagaTuPrestamo__options__body__details__total__title">
                  Total a pagar
                </div>
                <div className="claro__container__pagaTuPrestamo__options__body__details__total__amount">
                  {debt.currency}
                  {debt.amountPayable}
                </div>
              </div>
            </div>
          )}
        </div>

        <div
          className={`claro__container__pagaTuPrestamo__options__viewMore ${
            !combo ? 'active' : 'inactive'
          }`}
          onClick={combo ? null : viewMoreInfoDebt}
        >
          <u>{detallesDebt ? VIEW_MORE_BENEFITS : VIEW_LESS_BENEFITS}</u>
          <img
            src={detallesDebt ? down : up}
            alt="arrow"
            style={{
              filter: !combo ? '' : 'grayscale(100%)',
            }}
          />
        </div>
      </div>

      {countryCode === '507' ? (
        <ClaroPayPaymentOptionsWithBi />
      ) : (
        <ClaroPayPaymentOptions />
      )}

      <PaymentButton
        selectAccountBi={selectAccountBi}
        iIsVisibleAddNewAccountBi={iIsVisibleAddNewAccountBi}
        indicator={indicator}
        countryCode={countryCode}
        textBtn={textBtn}
        handlePaymentNewAccountBi={handlePaymentNewAccountBi}
        handlePaymentAccountBi={handlePaymentAccountBi}
        canClickButton={canClickButton}
        handlePayment={handlePayment}
        isVisibleSectionAddNewCard={isVisibleSectionAddNewCard}
        isEnabledPayWithNewCard={isEnabledPayWithNewCard}
        isEnabledPayWithSavedCard={isEnabledPayWithSavedCard}
      />
    </div>
  )
}

export default ClaroPagaTuPrestamo
