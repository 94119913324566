const routes = {
  routesClaroPay: {
    claroTyC: '/terms-and-conditions',
    claroTyCADM: '/tyc',
    claroPrivacity: '/privacity',
    claroPayPaymentOptions: '/landing/payment-options',
    claroPayPaymentProcess: '/landing/payment-process',
    claroBillingProcess: '/landing/billing-process',
    claroAdmProcess: '/landing/adm-process',
    claroRecurrence: '/landing/recurrence',
    claroWhoCalledMe: '/landing/who-called-me',
    claroBuyNowPayLater: '/landing/buy-now-pay-later',
    claroPagaTuPrestamo: '/landing/wallet-recovery',
    claroBecasMineco: '/becas',
    claroCallBackUssd: '/landing/ussd',
    claroBilling: '/landing/billing',
    claroAdm: '/landing/adm',
    claroSessionBi: '/landing/sesion',
    claroDeleteAccountBi: '/landing/delete-account-bi',
    claroDemoBi: '/bi',
    claroFailureBi: '/landing/failure',
    claroSendARecharge: '/landing/send-a-recharge',
    claroHome: '/home',
    claroHomeAuth: '/auth',
    claroHomePayment: '/home-payment',
    claroHomeHe: '/homehe',
    claroPruebas: '/landing/pruebas',
  },
}

export default routes
