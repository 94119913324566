import React, { useState } from 'react'

const CustomSwitch = ({ isSelected, onClick, text }) => {
  const [selected, setSelected] = useState(isSelected)

  return (
    <div
      className="claro__container__payment__options__remember"
      onClick={() => {
        onClick(!selected)
        setSelected(!selected)
      }}
    >
      <div
        role="button"
        className={`claro__container__payment__options__remember__button ${
          selected ? 'selected' : ''
        }`}
      >
        <span
          className={`claro__container__payment__options__remember__button__circle ${
            selected ? 'selected' : ''
          }`}
        />
      </div>
      <div className="claro__container__payment__options__remember__text">
        {text}
      </div>
    </div>
  )
}
export default CustomSwitch
