import React, { useContext, useEffect } from 'react'

import ClaroPayContext from '../../context/ClaroPayContext'

import { clearLocalStorage, getLocalStorage } from '../../services/storage'

import SavedCard from '../../components/SavedCard'
import NewCard from '../../components/NewCard'

import {
  labels,
  PROCESO_PAGO,
  METRICS_SESSION,
  PAYMENT_PROCESS,
} from '../../constants/ClaroPayConstants'

import circleAccepted from '../../assets/img/circle_accepted.svg'
import circleNotAccepted from '../../assets/img/circle_not_accepted.svg'
import arrow from '../../assets/img/arrow.svg'

const ClaroPayPaymentOptions = () => {
  const claroPayContext = useContext(ClaroPayContext)

  const { ADDNEWCARD, PAYMENT_TITLE } = labels

  const {
    recurrenceData,
    selectedSavedCard,
    setSelectedSavedCard,
    setSaveCard,
    setYear,
    setMonth,
    setSaveNewCard,
    setYearNewCard,
    setMonthNewCard,
    setCvv,
    numberCard,
    setNumberCard,
    isVisibleSectionAddNewCard,
    setIsVisibleSectionAddNewCard,
    setMaxLengthCvv,
    setMaxLengthNumberCard,
    callMeData,
    bnplData,
    pagaTuPrestamoData,
    minecoBecasData,
    cbkUssdData,
    homeData,
    sendMetrics,
    code,
    sendARechargeData,
    setProcessingPayment,
    processingPayment,
    setResponseService,
    responseService,
    setCanClickButton,
    setCodeBilling,
  } = claroPayContext

  useEffect(() => {
    setProcessingPayment(false)
    setResponseService(null)
    clearLocalStorage(METRICS_SESSION)
    clearLocalStorage(PAYMENT_PROCESS)
    setCodeBilling(null)
    const procesoPago = getLocalStorage(PROCESO_PAGO)
    if (procesoPago) {
      setCanClickButton(false)
      const timeoutId = setTimeout(() => {
        setCanClickButton(true)
        clearLocalStorage(PROCESO_PAGO)
      }, 30000)
      return () => clearTimeout(timeoutId)
    } else {
      setCanClickButton(true)
    }
  }, [responseService, processingPayment])

  let creditCardList = []

  creditCardList =
    bnplData?.creditCardList ||
    pagaTuPrestamoData?.creditCardList ||
    minecoBecasData?.creditCardList ||
    cbkUssdData?.creditCardList ||
    homeData?.tokenList ||
    callMeData?.creditCardList ||
    recurrenceData?.creditCardList ||
    sendARechargeData?.creditCardList ||
    []

  if (creditCardList) {
    for (let i = 0; i < creditCardList.length; i++) {
      creditCardList[i].position = i + 1
    }
  }

  const isAnyDataPresent = () => {
    return (
      pagaTuPrestamoData ||
      cbkUssdData ||
      homeData ||
      callMeData ||
      recurrenceData ||
      sendARechargeData
    )
  }

  const handleAddNewCard = () => {
    if (!isVisibleSectionAddNewCard && isAnyDataPresent()) {
      const metricsData = {
        metricType: 'CheckOut_',
        metricName: `NuevaTarjeta-${creditCardList.length + 1}`,
      }
      sendMetrics(metricsData)
    }
    setIsVisibleSectionAddNewCard(!isVisibleSectionAddNewCard)
    setSelectedSavedCard({})
    setMonth('')
    setYear('')
    setSaveCard(false)
    setMonthNewCard('')
    setYearNewCard('')
    setCvv('')
    setNumberCard('')
    setSaveNewCard(false)
  }

  useEffect(() => {
    if (numberCard !== '' && numberCard.length >= 1) {
      let firstNumber = numberCard.charAt(0)
      if (firstNumber === '3') {
        setMaxLengthCvv(4)
        setMaxLengthNumberCard(15)
      } else {
        setMaxLengthCvv(3)
        setMaxLengthNumberCard(16)
      }
    }
  }, [numberCard])

  useEffect(() => {
    if (creditCardList.length === 0) {
      if (isAnyDataPresent()) {
        const metricsData = {
          metricType: 'CheckOut_',
          metricName: `NuevaTarjeta-${creditCardList.length + 1}`,
        }
        handleSendMetrics(metricsData)
      }
      setIsVisibleSectionAddNewCard(true)
    } else if (creditCardList.length === 1) {
      if (isAnyDataPresent()) {
        const metricsData = {
          metricType: 'CheckOut_',
          metricName: `PagarTC-${creditCardList.length}`,
        }
        handleSendMetrics(metricsData)
      }
      setIsVisibleSectionAddNewCard(false)
      setSelectedSavedCard(creditCardList[0])
    } else {
      setIsVisibleSectionAddNewCard(false)
    }
  }, [code, homeData])

  const handleSendMetrics = metricsData => {
    if (homeData || code) {
      sendMetrics(metricsData)
    }
  }

  return (
    <div className="claro__container__payment__options">
      <div className="claro__container__payment__options__title">
        {PAYMENT_TITLE}
      </div>

      {creditCardList !== null && (
        <>
          {creditCardList.map(item => {
            const isVisible = isVisibleSectionAddNewCard
              ? false
              : item.tokenId === selectedSavedCard.tokenId

            return (
              <SavedCard
                key={item.tokenId}
                isVisible={isVisible}
                creditCard={item}
                sendMetrics={isAnyDataPresent() ? sendMetrics : undefined}
              />
            )
          })}
        </>
      )}

      {creditCardList.length < 3 && (
        <div
          className="claro__container__payment__options__newCard"
          onClick={handleAddNewCard}
        >
          <img
            src={`${
              isVisibleSectionAddNewCard ? circleAccepted : circleNotAccepted
            }`}
            alt={ADDNEWCARD}
          />
          {ADDNEWCARD}
          <img
            className="claro__container__payment__options__newCard__arrow"
            src={arrow}
            alt="arrow"
          />
        </div>
      )}

      {creditCardList.length < 3 && isVisibleSectionAddNewCard && (
        <NewCard
          creditCardList={creditCardList}
          sendMetrics={isAnyDataPresent() ? sendMetrics : undefined}
          optionType="claroPayPaymentOptions"
        />
      )}
    </div>
  )
}

export default ClaroPayPaymentOptions
