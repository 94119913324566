import React from 'react'

import { Link, useLocation } from 'react-router-dom'

import localRoutes from '../../constants/routes'

import internationalCards from '../../assets/img/internationalCards.svg'
import visa from '../../assets/img/visa-logo.svg'
import mastercard from '../../assets/img/mastercard.svg'
import amex from '../../assets/img/amex-logo.svg'
import sslSecure from '../../assets/img/ssl-secure.png'
import security from '../../assets/img/security.svg'
import bi from '../../assets/img/logoBiAzul.png'

const Footer = () => {
  const location = useLocation()
  const isPruebasLanding = location.pathname === '/landing/pruebas'

  const {
    routesClaroPay: { claroTyC, claroPrivacity },
  } = localRoutes

  return (
    <div className="claro__footer">
      <div className="claro__footer__acceptCard">
        <div className="claro__footer__acceptCard__logo">
          <img src={internationalCards} alt="international cards logo" />
        </div>
        <div className="claro__footer__acceptCard__text">
          <span className="claro__footer__acceptCard__text textBold">
            Aceptamos{' '}
          </span>
          Tarjetas de Crédito y Débito Emitidas Internacionales
        </div>
      </div>
      <div className="claro__footer__logos">
        <img src={visa} alt="visa logo" />
        <img src={mastercard} alt=" mastercard logo" />
        <img src={amex} alt="american express logo" />
        {isPruebasLanding && (
          <img className="claro__footer__logos__bi" src={bi} alt="Bi logo" />
        )}
        <img src={sslSecure} alt="ssl logo" />
        <img src={security} alt="security logo" />
      </div>
      <div className="claro__footer__termsAndConditionsOrPrivacity">
        <Link
          to={claroPrivacity}
          className="claro__footer__termsAndConditionsOrPrivacity__text"
        >
          Aviso de privacidad
        </Link>
        <Link
          to={claroTyC}
          className="claro__footer__termsAndConditionsOrPrivacity__text"
        >
          Términos y condiciones
        </Link>
      </div>
    </div>
  )
}

export default Footer
