import React from 'react'

import Categories from '../Categories'
import { getImage } from '../GetImage'
import useTouchHandler from '../UseTouch'
import CarouselControls from '../CarouselControls'
import SelectedOffer from '../SelectedOffer'
import NextButton from '../NextButton'

const CarouselPackages = ({
  currentCategory,
  handleCategory,
  pack,
  packSig,
  packFinal,
  handleNextProduct,
  bundle,
  topup,
  currentPosition,
  handleSelectedProduct,
}) => {
  const { handleTouchStart, handleTouchMove, handleTouchEnd } = useTouchHandler(
    handleNextProduct,
  )

  const packs = [
    {
      id: 'pack1',
      pack: pack,
      icons: pack?.socialMedia?.[0]?.icons || [],
      onClick: null,
    },
    {
      id: 'pack2',
      pack: packSig,
      icons: packSig?.socialMedia?.[0]?.icons || [],
      onClick: () => handleNextProduct('next'),
    },
    {
      id: 'pack3',
      pack: packFinal,
      icons: packFinal?.socialMedia?.[0]?.icons || [],
      onClick: null,
    },
  ]

  return (
    <>
      <Categories
        currentCategory={currentCategory}
        handleCategory={handleCategory}
        bundle={bundle}
      />

      <div
        className="claro__container__touch"
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <SelectedOffer pack={pack} currentCategory={currentCategory} />

        <div className="claro__container__selectedOffer__min">
          {packs.map(({ id, pack, icons, onClick }) => (
            <div
              key={id}
              className="claro__container__selectedOffer__min__content"
              onClick={onClick}
            >
              <div
                className={`claro__container__selectedOffer__min__content__header ${
                  currentCategory === 'topup' ? 'topup' : 'bundle'
                }`}
              >
                <p>
                  {pack.title}
                  {currentCategory === 'bundle' && (
                    <>{` ${pack.subtitle} - ${pack.validity}`}</>
                  )}
                </p>
              </div>
              <div
                className={`claro__container__selectedOffer__min__content__body${
                  currentCategory === 'topup' ? '__topup' : '__bundle'
                }`}
              >
                {currentCategory === 'topup' && (
                  <div className="claro__container__selectedOffer__min__content__body__topup__amount">
                    {pack.currency}
                    {pack.amount}
                  </div>
                )}
                {currentCategory === 'bundle' && (
                  <>
                    <div className="claro__container__selectedOffer__min__content__body__bundle__socialMedia">
                      {icons.length > 0 &&
                        icons.map(item => (
                          <img
                            key={item}
                            src={getImage(item)}
                            alt="social-icon"
                          />
                        ))}
                    </div>
                    <div className="claro__container__selectedOffer__min__content__body__bundle__amount">
                      {pack.currency}
                      {pack.amount}
                    </div>
                    <div className="claro__container__selectedOffer__min__content__body__bundle__description">
                      {pack.mainDescription || ' '}
                    </div>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>

        <CarouselControls
          currentCategory={currentCategory}
          bundle={bundle}
          topup={topup}
          currentPosition={currentPosition}
          handleNextProduct={handleNextProduct}
        />
      </div>

      <NextButton textBtn="Continuar" onClick={handleSelectedProduct} enabled />
    </>
  )
}

export default CarouselPackages
