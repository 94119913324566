import React, { useContext, useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'

import ClaroPayContext from '../../context/ClaroPayContext'

import ClaroPayPaymentOptions from '../ClaroPayPaymentOptions'
import ClaroPayPaymentOptionsWithBi from '../ClaroPayPaymentOptionsWithBi'

import PaymentButton from '../../components/PaymentButton'
import { generarUuid } from '../../components/GenerarUuid'
import handleRedirectHome from '../../components/HandleRedirectHome'
import NextButton from '../../components/NextButton'
import CarouselPackages from '../../components/CarouselPackages'
import SelectedOffer from '../../components/SelectedOffer'
import MoreOptions from '../../components/MoreOptions'

import localRoutes from '../../constants/routes'
import Country from '../../constants/Country'

import acceptedTerms from '../../assets/img/acceptedTerms.svg'
import selectMisdnImage from '../../assets/img/authMsisdn.svg'
import circleAccepted from '../../assets/img/circle_accepted.svg'
import circleNotAccepted from '../../assets/img/circle_not_accepted.svg'

const ClaroSendARecharge = () => {
  const history = useHistory()

  const claroPayContext = useContext(ClaroPayContext)

  const { countryCode } = Country()

  const {
    isVisibleSectionAddNewCard,
    isEnabledPayWithSavedCard,
    isEnabledPayWithNewCard,
    sendARechargeData,
    setProcessingPayment,
    setIsVisibleSectionAddNewCard,
    payWithSavedCardSendARecharge,
    payWithNewCardSendARecharge,
    payWithAccountBiSendARecharge,
    setResponseService,
    isValidatedFieldsToPayWithNewCard,
    isValidatedFieldsToPayWithSavedCard,
    selectAccountBi,
    iIsVisibleAddNewAccountBi,
    setIsVisibleAddNewPaymentMethod,
    payWithNewAccountBi,
    setIsVisibleAddNewAccountBi,
    setCurrentCategoryMetrics,
    clearData,
    sendMetrics,
    code,
    canClickButton,
    setCanClickButton,
  } = claroPayContext

  const {
    routesClaroPay: { claroPayPaymentProcess },
  } = localRoutes

  const [state, setState] = useState({
    pack: {
      amount: 0,
      currency: 0,
      subtitle: '',
      validity: '',
      socialMedia: { icons: [] },
      description: [],
      mainDescription: '',
    },
    topup: [],
    bundle: [],
    currentPosition: 0,
    currentCategory: 'bundle',
    msisdnPayer: '',
    packSig: {
      amount: 0,
      currency: 0,
      subtitle: '',
      validity: '',
      socialMedia: { icons: [] },
      description: [],
      mainDescription: '',
    },
    statusPayer: false,
    packFinal: {
      amount: 0,
      currency: 0,
      subtitle: '',
      validity: '',
      socialMedia: { icons: [] },
      description: [],
      mainDescription: '',
    },
    selectedProduct: false,
    alias: [],
    indicator: false,
    menu: true,
    msisdns: [],
  })

  const [selectMsisdn, setSelectMsisdn] = useState()

  useEffect(() => {
    const bundle = sendARechargeData.recommendedProducts.bundle.toSorted(
      (a, b) => b.amount - a.amount,
    )

    let topup
    if (countryCode !== '504') {
      topup = sendARechargeData.recommendedProducts.topup
        .filter(product => product.productId !== 'TOPUP200')
        .toSorted((a, b) => b.amount - a.amount)
    } else {
      topup = sendARechargeData.recommendedProducts.topup.toSorted(
        (a, b) => b.amount - a.amount,
      )
    }

    const msisdnPayer = sendARechargeData.msisdnPayer
    const statusPayer = sendARechargeData.statusPayer
    const alias = sendARechargeData.alias
    const indicator = sendARechargeData.indicator
    let msisdns = []

    if (sendARechargeData.msisdns) {
      msisdns = sendARechargeData.msisdns
    }

    if (statusPayer) {
      const nuevoMsisdn = { msisdn: msisdnPayer }

      const existeMsisdn = msisdns.some(item => item.msisdn === msisdnPayer)

      if (!existeMsisdn) {
        msisdns.push(nuevoMsisdn)
      }
    }

    if (msisdns.length === 0) {
      handleRedirectHome()
    }

    const pack = bundle[0]
    const currentPackages = bundle
    const currentCategory = 'bundle'
    const currentPosition = currentPackages.indexOf(pack)
    const packIndex = bundle.findIndex(product => product === pack)
    const packSigIndex = (packIndex === -1 ? 1 : packIndex + 1) % bundle.length
    const packFinalIndex =
      (packIndex === -1 ? 2 : packIndex + 2) % bundle.length

    setCurrentCategoryMetrics('Paquetes-')

    setState(prevState => ({
      ...prevState,
      bundle,
      topup,
      pack,
      msisdnPayer,
      statusPayer,
      currentPackages,
      currentCategory,
      currentPosition,
      packSig: bundle[packSigIndex],
      packFinal: bundle[packFinalIndex],
      alias,
      indicator,
      msisdns,
    }))
  }, [sendARechargeData])

  useEffect(() => {
    const metricsDataHome = {
      metricType: 'home',
      metricName: 'Home',
    }
    const metricsDataCategory = {
      metricType: 'category',
      metricName:
        state.currentCategory === 'bundle' ? 'Superpacks' : 'Recargas',
    }

    if (code) {
      sendMetrics(metricsDataHome)
      sendMetrics(metricsDataCategory)
    }
  }, [code])

  const handleSelectedMsisdn = () => {
    clearData()
    setState(prevState => ({
      ...prevState,
      menu: !prevState.menu,
    }))
  }

  const metricBotonPagar = () => {
    const metricsData = {
      metricType: 'CheckOut_',
      metricName: 'BotonPagar',
    }
    sendMetrics(metricsData)
  }

  const handlePayment = async () => {
    setCanClickButton(false)
    setResponseService(null)
    const uuid = generarUuid()
    setProcessingPayment(true)
    setIsVisibleSectionAddNewCard(false)
    metricBotonPagar()
    const msisdn = selectMsisdn
    history.push(claroPayPaymentProcess)
    let response
    const isPaymentValid = isVisibleSectionAddNewCard
      ? isValidatedFieldsToPayWithNewCard()
      : isValidatedFieldsToPayWithSavedCard()

    if (isPaymentValid) {
      response = isVisibleSectionAddNewCard
        ? await payWithNewCardSendARecharge(state.pack, msisdn, uuid)
        : await payWithSavedCardSendARecharge(state.pack, msisdn, uuid)
    }
    setResponseService(response)
  }

  const metricBotonPagarBi = () => {
    const metricsData = {
      metricType: 'CheckOut_',
      metricName: 'BotonPagar-Cuenta',
    }
    sendMetrics(metricsData)
  }

  const handlePaymentAccountBi = async () => {
    setCanClickButton(false)
    setResponseService(null)
    const uuid = generarUuid()
    setProcessingPayment(true)
    setIsVisibleAddNewPaymentMethod(false)
    metricBotonPagarBi()
    const msisdn = selectMsisdn
    history.push(claroPayPaymentProcess)
    const response = await payWithAccountBiSendARecharge(
      state.pack,
      msisdn,
      uuid,
    )
    setResponseService(response)
  }

  const handlePaymentNewAccountBi = async () => {
    setIsVisibleAddNewPaymentMethod(false)
    setResponseService(null)
    setProcessingPayment(true)
    setIsVisibleAddNewAccountBi(true)
    metricBotonPagarBi()
    const msisdn = selectMsisdn
    history.push(claroPayPaymentProcess)
    payWithNewAccountBi(state.pack.productId, msisdn)
  }

  const handleSelectedProduct = () => {
    setState(prevState => ({
      ...prevState,
      selectedProduct: !prevState.selectedProduct,
    }))

    if (state.selectedProduct) {
      const metricsData = {
        metricType: 'CheckOut_',
        metricName: 'MasOpciones',
      }
      sendMetrics(metricsData)
    } else {
      const metricsData = {
        metricType: 'BtnContinuar',
        metricName:
          'Botón_' +
          (state.currentCategory === 'bundle' ? 'Paquetes' : 'Recargas') +
          '_Continuar',
      }
      sendMetrics(metricsData)
    }

    clearData()
  }

  const handleCategory = category => {
    setCurrentCategoryMetrics(category === 'bundle' ? 'Paquetes-' : 'Recarga-')
    const metricsData = {
      metricType: 'category',
      metricName: category === 'bundle' ? 'Superpacks' : 'Recargas',
    }
    sendMetrics(metricsData)

    const packCategory = category === 'bundle' ? state.bundle : state.topup
    setState(prevState => ({
      ...prevState,
      currentCategory: category,
      currentPosition: 0,
      pack: packCategory[0],
      packSig: packCategory.length >= 2 ? packCategory[1] : packCategory[0],
      packFinal: packCategory.length >= 3 ? packCategory[2] : packCategory[0],
    }))
  }

  const handleSelectMsisdn = msisdn => {
    if (selectMsisdn && selectMsisdn === msisdn) {
      setSelectMsisdn(null)
    } else {
      setSelectMsisdn(msisdn)
    }
  }

  const handleNextProduct = action => {
    const { currentPosition, currentCategory, topup, bundle } = state
    const packCategory = currentCategory === 'bundle' ? bundle : topup
    const totalItems = packCategory.length

    let position = currentPosition
    let nextPosition, nextPositionFinal

    if (action === 'next') {
      position = (currentPosition + 1) % totalItems
      const metricsData = {
        metricType: 'Carrousel',
        metricName:
          (state.currentCategory === 'bundle' ? 'Paquetes' : 'Recargas') +
          '_Carrousel_Flecha-Derecha',
      }
      sendMetrics(metricsData)
    } else if (action === 'prev') {
      position = (currentPosition - 1 + totalItems) % totalItems
      const metricsData = {
        metricType: 'Carrousel',
        metricName:
          (state.currentCategory === 'bundle' ? 'Paquetes' : 'Recargas') +
          '_Carrousel_Flecha-Izquierda',
      }
      sendMetrics(metricsData)
    }

    nextPosition = (position + 1) % totalItems
    nextPositionFinal = (nextPosition + 1) % totalItems

    setState(prevState => ({
      ...prevState,
      currentPosition: position,
      pack: packCategory[position],
      packSig: packCategory[nextPosition],
      packFinal: packCategory[nextPositionFinal],
    }))
  }

  const {
    currentPosition,
    currentCategory,
    pack,
    packSig,
    packFinal,
    bundle,
    topup,
    msisdnPayer,
    selectedProduct,
    indicator,
    menu,
    msisdns,
  } = state

  const textBtn = `Pagar ${pack.currency}${pack.amount}`

  return (
    <div className="claro__container__sendARecharge">
      {!menu ? (
        <>
          {selectMsisdn && (
            <div className="claro__container__selectRechargeNumber">
              <div className="claro__container__selectRechargeNumber__option">
                <div className="claro__container__selectRechargeNumber__option__image">
                  <img src={acceptedTerms} alt="acceptedTerms" />
                </div>
                <div className="claro__container__selectRechargeNumber__option__number">
                  Recargar{' '}
                  {selectMsisdn === msisdnPayer
                    ? 'mi número'
                    : `el: ${selectMsisdn.slice(3)}`}{' '}
                </div>
                <div
                  className="claro__container__selectRechargeNumber__option__changeNumber"
                  onClick={handleSelectedMsisdn}
                >
                  Cambiar Número
                </div>
              </div>
            </div>
          )}

          {!selectedProduct ? (
            <CarouselPackages
              currentCategory={currentCategory}
              handleCategory={handleCategory}
              pack={pack}
              packSig={packSig}
              packFinal={packFinal}
              handleNextProduct={handleNextProduct}
              bundle={bundle}
              topup={topup}
              currentPosition={currentPosition}
              handleSelectedProduct={handleSelectedProduct}
            />
          ) : (
            <>
              <SelectedOffer pack={pack} currentCategory={currentCategory} />

              <MoreOptions handleSelectedProduct={handleSelectedProduct} />

              {countryCode === '507' ? (
                <ClaroPayPaymentOptionsWithBi />
              ) : (
                <ClaroPayPaymentOptions />
              )}

              <PaymentButton
                selectAccountBi={selectAccountBi}
                iIsVisibleAddNewAccountBi={iIsVisibleAddNewAccountBi}
                indicator={indicator}
                countryCode={countryCode}
                textBtn={textBtn}
                handlePaymentNewAccountBi={handlePaymentNewAccountBi}
                handlePaymentAccountBi={handlePaymentAccountBi}
                canClickButton={canClickButton}
                handlePayment={handlePayment}
                isVisibleSectionAddNewCard={isVisibleSectionAddNewCard}
                isEnabledPayWithNewCard={isEnabledPayWithNewCard}
                isEnabledPayWithSavedCard={isEnabledPayWithSavedCard}
              />
            </>
          )}
        </>
      ) : (
        <div className="claro__container__sendARecharge__selectMsisdn">
          <div className="claro__container__sendARecharge__selectMsisdn__header">
            <div className="claro__container__sendARecharge__selectMsisdn__header__text">
              Recarga Aquí...
            </div>
          </div>
          <div className="claro__container__sendARecharge__selectMsisdn__body">
            <div className="claro__container__sendARecharge__selectMsisdn__body__text">
              Envía a tu familiar o amigo un paquete/recarga.
              <br /> <br />
              Selecciona al número:
            </div>
            <div className="claro__container__sendARecharge__selectMsisdn__body__image">
              <img src={selectMisdnImage} alt="selectMisdnImage" />
            </div>
            {msisdns.map(item => {
              const isSelected = selectMsisdn === item.msisdn
              return (
                <div
                  className="claro__container__sendARecharge__selectMsisdn__body__option"
                  key={item.msisdn}
                >
                  <div
                    className="claro__container__sendARecharge__selectMsisdn__body__option__info"
                    onClick={() => handleSelectMsisdn(item.msisdn)}
                  >
                    <div>
                      <img
                        src={isSelected ? circleAccepted : circleNotAccepted}
                        alt={item.msisdn}
                      />
                    </div>

                    <div>
                      {item.msisdn === msisdnPayer
                        ? 'Mi número'
                        : item.msisdn.slice(3)}
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
          <NextButton
            textBtn="Continuar"
            enabled={selectMsisdn}
            onClick={handleSelectedMsisdn}
          />
        </div>
      )}
    </div>
  )
}

export default ClaroSendARecharge
