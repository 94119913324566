import React, { useContext, useEffect } from 'react'

import ClaroPayContext from '../../context/ClaroPayContext'

import { RESPONSE_CODE } from '../../constants/responseCode'

import handleRedirectHome from '../../components/HandleRedirectHome'
import NextButton from '../../components/NextButton'
import Billing from '../../components/Billing'

const ClaroBilling = () => {
  const claroPayContext = useContext(ClaroPayContext)

  const { billingData, sendMetrics, getImage } = claroPayContext

  const {
    FAIL_SERVER_CLARO_BILLING,
    SESSION_CODE_EXPIRED_BILLING,
    BILLING_HAS_ALREADY_BEEN_GENERATED,
    OK_CLARO_BILLING,
  } = RESPONSE_CODE

  useEffect(() => {
    if (billingData) {
      const metricsData = {
        metricType: 'home',
        metricName: 'Home',
      }
      sendMetrics(metricsData)
      if (
        billingData.code === FAIL_SERVER_CLARO_BILLING.code ||
        billingData.code === SESSION_CODE_EXPIRED_BILLING.code ||
        billingData.code === BILLING_HAS_ALREADY_BEEN_GENERATED.code
      ) {
        setTimeout(() => {
          handleRedirectHome()
        }, 6000)
      }
    }
  }, [billingData])

  return (
    <>
      {billingData && (
        <div className="claro__container__process">
          {billingData.code === OK_CLARO_BILLING.code && (
            <div className="claro__container__process__sucess">
              <Billing />
            </div>
          )}

          {(billingData.code === FAIL_SERVER_CLARO_BILLING.code ||
            billingData.code === SESSION_CODE_EXPIRED_BILLING.code ||
            billingData.code === BILLING_HAS_ALREADY_BEEN_GENERATED.code) && (
            <>
              <div className="claro__container__process__error">
                {billingData.img !== null && billingData.img !== undefined && (
                  <img
                    className="claro__container__process__image"
                    src={getImage(billingData.img)}
                    alt="error"
                  />
                )}
                <p className="claro__container__process__generalMessage">
                  {billingData.description}
                </p>
              </div>
              <NextButton
                textBtn={billingData.title_button}
                onClick={handleRedirectHome}
                enabled
              />
            </>
          )}
        </div>
      )}
    </>
  )
}

export default ClaroBilling
