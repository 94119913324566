import React from 'react'

import { getImage } from '../GetImage'

const SelectedOffer = ({ pack, currentCategory }) => {
  const icons = pack?.socialMedia?.[0]?.icons || []

  return (
    <div className="claro__container__selectedOffer">
      <div
        className={`claro__container__selectedOffer__header ${
          currentCategory === 'topup' ? 'topup' : 'bundle'
        }`}
      >
        {pack.title}
        {currentCategory === 'bundle' && (
          <>
            <br />
            {`${pack.subtitle} - ${pack.validity}`}
          </>
        )}
      </div>

      <div className="claro__container__selectedOffer__body">
        {currentCategory === 'topup' && (
          <div className="claro__container__selectedOffer__body__amountTopup">
            {pack.currency}
            {pack.amount}
          </div>
        )}

        {currentCategory === 'bundle' && (
          <>
            <div className="claro__container__selectedOffer__body__socialMedia">
              {icons.length > 0 && (
                <>
                  {icons.map(item => (
                    <img key={item} src={getImage(item)} alt="social-icon" />
                  ))}
                </>
              )}
            </div>
            <div className="claro__container__selectedOffer__body__amountBundle">
              {pack.currency}
              {pack.amount}
            </div>
            <div className="claro__container__selectedOffer__body__description">
              {pack.mainDescription || ' '}
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default SelectedOffer
