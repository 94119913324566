import React, { useContext, useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'

import ClaroPayContext from '../../context/ClaroPayContext'

import PaymentButton from '../../components/PaymentButton'
import { generarUuid } from '../../components/GenerarUuid'

import ClaroPayPaymentOptions from '../ClaroPayPaymentOptions'
import ClaroPayPaymentOptionsWithBi from '../ClaroPayPaymentOptionsWithBi'

import Country from '../../constants/Country'
import localRoutes from '../../constants/routes'
import { labels } from '../../constants/ClaroPayConstants'

import Phone from '../../assets/img/Phone.svg'
import up from '../../assets/img/up-arrow.png'
import down from '../../assets/img/down-arrow.png'

const ClaroBuyNowPayLater = () => {
  const history = useHistory()

  const claroPayContext = useContext(ClaroPayContext)

  const { VIEW_MORE_DETAILS, VIEW_LESS_DETAILS } = labels

  const { countryCode } = Country()

  const {
    isVisibleSectionAddNewCard,
    isEnabledPayWithSavedCard,
    isEnabledPayWithNewCard,
    setProcessingPayment,
    setIsVisibleSectionAddNewCard,
    payWithSavedCardBnpl,
    payWithNewCardBnpl,
    setResponseService,
    isValidatedFieldsToPayWithNewCard,
    isValidatedFieldsToPayWithSavedCard,
    bnplData,
    selectAccountBi,
    iIsVisibleAddNewAccountBi,
    setIsVisibleAddNewPaymentMethod,
    payWithNewAccountBi,
    payWithAccountBiBnpl,
    setIsVisibleAddNewAccountBi,
    canClickButton,
    setCanClickButton,
  } = claroPayContext

  const [state, setState] = useState({
    detalles: true,
    debt: {
      amountOfDebt: 0,
      currency: '',
      amountPayable: 0,
      dateOfPurchase: '',
      productDescription: '',
    },
    msisdn: '',
    alias: [],
    indicator: false,
  })

  useEffect(() => {
    const { debt, msisdn, alias, indicator } = bnplData

    setState(prevState => ({
      ...prevState,
      msisdn,
      debt,
      alias,
      indicator,
    }))
  }, [bnplData])

  const viewMoreInfo = () => {
    setState(prevState => ({
      ...prevState,
      detalles: !state.detalles,
    }))
  }

  const {
    routesClaroPay: { claroPayPaymentProcess },
  } = localRoutes

  const handlePayment = async () => {
    setCanClickButton(false)
    setResponseService(null)
    const uuid = generarUuid()
    setProcessingPayment(true)
    setIsVisibleSectionAddNewCard(false)
    history.push(claroPayPaymentProcess)
    let response
    const isPaymentValid = isVisibleSectionAddNewCard
      ? isValidatedFieldsToPayWithNewCard()
      : isValidatedFieldsToPayWithSavedCard()

    if (isPaymentValid) {
      response = isVisibleSectionAddNewCard
        ? await payWithNewCardBnpl(uuid)
        : await payWithSavedCardBnpl(uuid)
    }
    setResponseService(response)
  }

  const handlePaymentAccountBi = async () => {
    setCanClickButton(false)
    setResponseService(null)
    const uuid = generarUuid()
    setProcessingPayment(true)
    setIsVisibleAddNewPaymentMethod(false)
    history.push(claroPayPaymentProcess)
    const response = await payWithAccountBiBnpl(uuid)
    setResponseService(response)
  }

  const handlePaymentNewAccountBi = async () => {
    setIsVisibleAddNewPaymentMethod(false)
    setResponseService(null)
    setProcessingPayment(true)
    setIsVisibleAddNewAccountBi(true)
    history.push(claroPayPaymentProcess)
    payWithNewAccountBi()
  }

  const { debt, indicator } = state

  const textBtn = `Pagar ${debt.currency}${debt.amountPayable}`

  return (
    <div className="claro__container__bnpl">
      <div className="claro__container__bnpl__options">
        <div className="claro__container__bnpl__options__body">
          <div className="claro__container__bnpl__options__body__image">
            <img src={Phone} alt="icon" />
          </div>
          <div className="claro__container__bnpl__options__body__title">
            COMPRA PENDIENTE DE CANCELAR
          </div>
          <div className="claro__container__bnpl__options__body__subtitle">
            Comprado por medio de LLamada
          </div>
          <div className="claro__container__bnpl__options__body__description">
            Monto a Pagar
          </div>
          <div className="claro__container__bnpl__options__body__amount">
            {debt.currency}
            {debt.amountPayable}
          </div>

          {!state.detalles && (
            <div className="claro__container__bnpl__options__body__details">
              <p>
                <span>Fecha de Compra: </span>
                {debt.dateOfPurchase}
              </p>
              <p>
                <span>Descripción de Paquete: </span>
                {debt.productDescription}
              </p>
              <p>
                <span>Monto de la Deuda: </span>
                {debt.currency}
                {debt.amountOfDebt}
              </p>
            </div>
          )}
        </div>

        <div
          className="claro__container__bnpl__options__viewMore"
          onClick={viewMoreInfo}
        >
          <u>{state.detalles ? VIEW_MORE_DETAILS : VIEW_LESS_DETAILS}</u>
          <img src={`${state.detalles ? down : up}`} alt="arrow" />
        </div>
      </div>

      {countryCode === '507' ? (
        <ClaroPayPaymentOptionsWithBi />
      ) : (
        <ClaroPayPaymentOptions />
      )}

      <PaymentButton
        selectAccountBi={selectAccountBi}
        iIsVisibleAddNewAccountBi={iIsVisibleAddNewAccountBi}
        indicator={indicator}
        countryCode={countryCode}
        textBtn={textBtn}
        handlePaymentNewAccountBi={handlePaymentNewAccountBi}
        handlePaymentAccountBi={handlePaymentAccountBi}
        canClickButton={canClickButton}
        handlePayment={handlePayment}
        isVisibleSectionAddNewCard={isVisibleSectionAddNewCard}
        isEnabledPayWithNewCard={isEnabledPayWithNewCard}
        isEnabledPayWithSavedCard={isEnabledPayWithSavedCard}
      />
    </div>
  )
}

export default ClaroBuyNowPayLater
