import React, { useState, useContext, useEffect } from 'react'

import { Redirect } from 'react-router-dom'

import ClaroPayContext from '../../context/ClaroPayContext'

import { RESPONSE_CODE } from '../../constants/responseCode'
import BACK_END_ROUTES from '../../constants/api_backend_url_supplier'
import Country from '../../constants/Country'
import {
  MSISDN_HOME,
  AUTH_TOKENS_HOME,
} from '../../constants/ClaroPayConstants'

import { postAxiosService } from '../../services/postAxiosService'
import { setLocalStorage } from '../../services/storage'

import Login from '../../components/Login'
import handleRedirectHome from '../../components/HandleRedirectHome'

import authMsisdn from '../../assets/img/authMsisdn.svg'

const ClaroHomeAuth = () => {
  const {
    OK,
    OTRO_OPERADOR,
    NO_PREPAGO,
    ANTIFRAUDE,
    ANTIFRAUDE_KO,
    BLACKLIST,
    TIMEOUT,
    CODIGO_NO_VALIDO,
    CODIGO_EXPIRADO,
    ERROR_INTENTOS,
    REENVIO_SMS,
    ERROR_CODE,
    TOO_MANY_REQUESTS,
    FAIL_SERVER_CLARO_PAY,
  } = RESPONSE_CODE

  const {
    isAuthenticated,
    sendMetrics,
    setOpcionSeleccionada,
    isLoading,
  } = useContext(ClaroPayContext)

  const { countryCode } = Country()

  const [redirect, setRedirect] = useState(false)

  const [valueMsisdn, setValueMsisdn] = useState('')
  const [valueMsisdnValidated, setValueMsisdnValidated] = useState()
  const [errorMessageMsisdn, setErrorMessageMsisdn] = useState('')
  const [responseDescriptionMsisdn, setResponseDescriptionMsisdn] = useState()
  const [
    responseDescriptionMsisdnResend,
    setResponseDescriptionMsisdnResend,
  ] = useState()

  const [valueCode, setValueCode] = useState('')
  const [errorMessageCode, setErrorMessageCode] = useState('')
  const [isButtonEnabledMsisdn, setIsButtonEnabledMsisdn] = useState(false)
  const [isButtonEnabledCode, setIsButtonEnabledCode] = useState(false)
  const [responseDescriptionCode, setResponseDescriptionCode] = useState()

  useEffect(() => {
    setOpcionSeleccionada(null)
    if (!isAuthenticated) {
      const metricsData = {
        metricType: 'Login',
        metricName: 'Login',
      }
      sendMetrics(metricsData)
    }
  }, [])

  const focusPhoneNumberInput = async () => {
    await new Promise(resolve => setTimeout(resolve, 10000))
    const phoneNumberInput = document.getElementById('phoneNumberInput')
    if (phoneNumberInput) {
      phoneNumberInput.focus()
      phoneNumberInput.click()
    }
  }

  const handleChangeMsisdn = e => {
    let numericValue = e.target.value.replace(/\D/g, '')
    numericValue = numericValue.slice(0, 8)
    setValueMsisdn(numericValue)

    if (numericValue.length !== 8) {
      setErrorMessageMsisdn('El número debe tener 8 dígitos')
    } else {
      setErrorMessageMsisdn('')
    }
    setResponseDescriptionMsisdn(null)
    setIsButtonEnabledMsisdn(numericValue.length === 8)
  }

  const handleChangeCode = e => {
    let numericValue = e.target.value.replace(/\D/g, '')
    numericValue = numericValue.slice(0, 6)
    setValueCode(numericValue)

    if (numericValue.length !== 6) {
      setErrorMessageCode('El código debe tener 6 dígitos')
    } else {
      setErrorMessageCode('')
    }
    setResponseDescriptionCode(null)
    setResponseDescriptionMsisdnResend(null)
    setIsButtonEnabledCode(numericValue.length === 6)
  }

  const sendMetricsErrorConfirmMsisdn = async () => {
    const metricsData = {
      metricType: 'Login',
      metricName: 'Login_Error',
    }
    sendMetrics(metricsData)
    focusPhoneNumberInput()
  }

  const confirmMsisdn = async () => {
    try {
      setIsButtonEnabledMsisdn(false)
      const data = {
        msisdn: countryCode + valueMsisdn,
      }
      const url = BACK_END_ROUTES.homeMsisdn
      const response = await postAxiosService(url, data)
      const metricsData = {
        metricType: 'Login',
        metricName: 'Login_BotonContinuar',
      }
      sendMetrics(metricsData)
      return await processConfirmMsisdnResponse(response)
    } catch (error) {
      sendMetricsErrorConfirmMsisdn()
      return await processConfirmMsisdnResponse({
        responseCode: FAIL_SERVER_CLARO_PAY.code,
      })
    }
  }

  const processConfirmMsisdnResponse = async response => {
    switch (response.responseCode) {
      case OK.code:
        setValueMsisdnValidated(response.responseContent.msisdn)
        setResponseDescriptionMsisdn(null)
        return OK

      case OTRO_OPERADOR.code:
        setResponseDescriptionMsisdn(OTRO_OPERADOR.description)
        sendMetricsErrorConfirmMsisdn()
        return OTRO_OPERADOR

      case NO_PREPAGO.code:
        setResponseDescriptionMsisdn(NO_PREPAGO.description)
        sendMetricsErrorConfirmMsisdn()
        return NO_PREPAGO

      case ANTIFRAUDE.code:
        setResponseDescriptionMsisdn(ANTIFRAUDE.description)
        sendMetricsErrorConfirmMsisdn()
        return ANTIFRAUDE

      case ANTIFRAUDE_KO.code:
        setResponseDescriptionMsisdn(ANTIFRAUDE_KO.description)
        sendMetricsErrorConfirmMsisdn()
        return ANTIFRAUDE_KO

      case BLACKLIST.code:
        setResponseDescriptionMsisdn(BLACKLIST.description)
        sendMetricsErrorConfirmMsisdn()
        return BLACKLIST

      case TIMEOUT.code:
        setResponseDescriptionMsisdn(TIMEOUT.description)
        sendMetricsErrorConfirmMsisdn()
        return TIMEOUT

      case ERROR_CODE.code:
        setResponseDescriptionMsisdn(ERROR_CODE.description)
        sendMetricsErrorConfirmMsisdn()
        return ERROR_CODE

      case ERROR_INTENTOS.code:
        setResponseDescriptionMsisdn(ERROR_INTENTOS.description)
        sendMetricsErrorConfirmMsisdn()
        return ERROR_INTENTOS

      case FAIL_SERVER_CLARO_PAY.code:
        setResponseDescriptionMsisdn(FAIL_SERVER_CLARO_PAY.description)
        sendMetricsErrorConfirmMsisdn()
        return FAIL_SERVER_CLARO_PAY

      default:
        setResponseDescriptionMsisdn(TOO_MANY_REQUESTS.description)
        sendMetricsErrorConfirmMsisdn()
        return TOO_MANY_REQUESTS
    }
  }

  const sendMetricsErrorConfirmCode = async () => {
    const metricsData = {
      metricType: 'Login_Token',
      metricName: 'Login_Token-Error',
    }
    sendMetrics(metricsData)
  }

  const confirmCode = async () => {
    try {
      setIsButtonEnabledCode(false)
      const data = {
        msisdn: valueMsisdnValidated,
        code: valueCode,
      }
      const url = BACK_END_ROUTES.homeSms
      const response = await postAxiosService(url, data)
      const metricsData = {
        metricType: 'Login_Token',
        metricName: 'Login_Token-BotonValidar',
      }
      sendMetrics(metricsData)
      return await processAuthCodeResponse(response)
    } catch (error) {
      sendMetricsErrorConfirmCode()
      return await processAuthCodeResponse({
        responseCode: FAIL_SERVER_CLARO_PAY.code,
      })
    }
  }

  const processAuthCodeResponse = async response => {
    switch (response.responseCode) {
      case OK.code:
        setLocalStorage(AUTH_TOKENS_HOME, response.responseContent.accessToken)
        setLocalStorage(
          'refreshTokenHome',
          response.responseContent.refreshToken,
        )
        setLocalStorage(MSISDN_HOME, valueMsisdnValidated)
        setRedirect(true)
        return OK

      case CODIGO_NO_VALIDO.code:
        setResponseDescriptionCode(CODIGO_NO_VALIDO.description)
        sendMetricsErrorConfirmCode()
        return CODIGO_NO_VALIDO

      case CODIGO_EXPIRADO.code:
        setResponseDescriptionCode(CODIGO_EXPIRADO.description)
        sendMetricsErrorConfirmCode()
        return CODIGO_EXPIRADO

      case TIMEOUT.code:
        setResponseDescriptionCode(TIMEOUT.description)
        sendMetricsErrorConfirmCode()
        return TIMEOUT

      case ERROR_INTENTOS.code:
        setResponseDescriptionCode(ERROR_INTENTOS.description)
        sendMetricsErrorConfirmCode()
        return ERROR_INTENTOS

      case FAIL_SERVER_CLARO_PAY.code:
        setResponseDescriptionCode(FAIL_SERVER_CLARO_PAY.description)
        sendMetricsErrorConfirmCode()
        return FAIL_SERVER_CLARO_PAY

      default:
        setResponseDescriptionCode(TOO_MANY_REQUESTS.description)
        sendMetricsErrorConfirmCode()
        return TOO_MANY_REQUESTS
    }
  }

  const fixPhoneNumber = () => {
    setValueMsisdnValidated(null)
    setValueCode('')
    setValueMsisdn('')

    setErrorMessageMsisdn('')
    setErrorMessageCode('')
    setResponseDescriptionMsisdn()
    setResponseDescriptionMsisdnResend()
    setResponseDescriptionCode()
    setIsButtonEnabledCode(false)
  }

  const resendCode = async () => {
    try {
      const data = {
        msisdn: valueMsisdnValidated,
      }
      const url = BACK_END_ROUTES.homeMsisdn
      const response = await postAxiosService(url, data)
      const metricsData = {
        metricType: 'Login',
        metricName: 'Login_Token-Reenvio',
      }
      sendMetrics(metricsData)
      return await processAuthResendCodeResponse(response)
    } catch (error) {
      return await processAuthResendCodeResponse({
        responseCode: FAIL_SERVER_CLARO_PAY.code,
      })
    }
  }

  const processAuthResendCodeResponse = async response => {
    switch (response.responseCode) {
      case REENVIO_SMS.code:
        setResponseDescriptionMsisdnResend(REENVIO_SMS.description)
        return REENVIO_SMS

      case OTRO_OPERADOR.code:
        setResponseDescriptionMsisdnResend(OTRO_OPERADOR.description)
        return OTRO_OPERADOR

      case NO_PREPAGO.code:
        setResponseDescriptionMsisdnResend(NO_PREPAGO.description)
        return NO_PREPAGO

      case ANTIFRAUDE.code:
        setResponseDescriptionMsisdnResend(ANTIFRAUDE.description)
        return ANTIFRAUDE

      case ANTIFRAUDE_KO.code:
        setResponseDescriptionMsisdnResend(ANTIFRAUDE_KO.description)
        return ANTIFRAUDE_KO

      case BLACKLIST.code:
        setResponseDescriptionMsisdnResend(BLACKLIST.description)
        return BLACKLIST

      case TIMEOUT.code:
        setResponseDescriptionMsisdnResend(TIMEOUT.description)
        return TIMEOUT

      case ERROR_CODE.code:
        setResponseDescriptionMsisdnResend(ERROR_CODE.description)
        return ERROR_CODE

      case ERROR_INTENTOS.code:
        setResponseDescriptionMsisdnResend(ERROR_INTENTOS.description)
        return ERROR_INTENTOS

      case FAIL_SERVER_CLARO_PAY.code:
        setResponseDescriptionMsisdnResend(FAIL_SERVER_CLARO_PAY.description)
        return FAIL_SERVER_CLARO_PAY

      default:
        setResponseDescriptionMsisdnResend(TOO_MANY_REQUESTS.description)
        return TOO_MANY_REQUESTS
    }
  }

  useEffect(() => {
    if (redirect) {
      handleRedirectHome()
    }
  }, [redirect])

  return (
    <>
      {isAuthenticated && <Redirect to="/home" />}

      {!isLoading && (
        <div className="claro__container__homeAuth">
          <Login
            authMsisdn={authMsisdn}
            valueMsisdn={valueMsisdn}
            valueMsisdnValidated={valueMsisdnValidated}
            valueCode={valueCode}
            handleChangeMsisdn={handleChangeMsisdn}
            handleChangeCode={handleChangeCode}
            confirmMsisdn={confirmMsisdn}
            fixPhoneNumber={fixPhoneNumber}
            resendCode={resendCode}
            confirmCode={confirmCode}
            isButtonEnabledMsisdn={isButtonEnabledMsisdn}
            isButtonEnabledCode={isButtonEnabledCode}
            errorMessageMsisdn={errorMessageMsisdn}
            errorMessageCode={errorMessageCode}
            responseDescriptionMsisdn={responseDescriptionMsisdn}
            setResponseDescriptionMsisdn={setResponseDescriptionMsisdn}
            responseDescriptionCode={responseDescriptionCode}
            setResponseDescriptionCode={setResponseDescriptionCode}
            responseDescriptionMsisdnResend={responseDescriptionMsisdnResend}
            setResponseDescriptionMsisdnResend={
              setResponseDescriptionMsisdnResend
            }
          />
        </div>
      )}
    </>
  )
}

export default ClaroHomeAuth
