import React, { useContext, useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'

import ClaroPayContext from '../../context/ClaroPayContext'

import {
  setLocalStorage,
  getLocalStorage,
  clearLocalStorage,
} from '../../services/storage'

import {
  PRODUCT_SELECTED_HOME,
  MENU_REDIRECT,
  MENU_CATEGORY,
} from '../../constants/ClaroPayConstants'
import localRoutes from '../../constants/routes'

import CarouselPackages from '../../components/CarouselPackages'

import Country from '../../constants/Country'

const ClaroHome = () => {
  const history = useHistory()

  const { countryCode } = Country()

  const {
    routesClaroPay: { claroHomeAuth, claroHomePayment },
  } = localRoutes

  const {
    isLoading,
    setHomeProduct,
    setCurrentCategoryMetrics,
    clearData,
    sendMetrics,
    getProducts,
    products,
    isAuthenticated,
    homeProduct,
    setOpcionSeleccionada,
    redirectMenu,
  } = useContext(ClaroPayContext)

  const [state, setState] = useState({
    pack: {
      amount: 0,
      currency: 0,
      subtitle: '',
      validity: '',
      socialMedia: {
        title: '',
        icons: [],
      },
      description: [],
      mainDescription: '',
    },
    topup: [],
    bundle: [],
    currentPosition: 0,
    currentCategory: 'bundle',
    packSig: {
      amount: 0,
      currency: 0,
      subtitle: '',
      validity: '',
      socialMedia: {
        title: '',
        icons: [],
      },
      description: [],
      mainDescription: '',
    },
    packFinal: {
      amount: 0,
      currency: 0,
      subtitle: '',
      validity: '',
      socialMedia: {
        title: '',
        icons: [],
      },
      description: [],
      mainDescription: '',
    },
    alias: [],
    indicator: false,
  })

  const getPack = (menuCategory, bundle, topup) => {
    if (menuCategory) {
      return menuCategory === 'Superpacks' && bundle.length > 0
        ? bundle[0]
        : topup[0]
    } else {
      return bundle.length > 0 ? bundle[0] : topup[0]
    }
  }

  const getPackDetails = (pack, bundle, topup) => {
    const currentPackages = pack.productType === 'BUNDLE' ? bundle : topup
    const currentCategory = pack.productType === 'BUNDLE' ? 'bundle' : 'topup'
    const currentPosition = currentPackages.indexOf(pack)

    setOpcionSeleccionada(
      currentCategory === 'bundle' ? 'Superpacks' : 'Recargas',
    )

    const packIndex =
      pack.productType === 'BUNDLE'
        ? bundle.findIndex(product => product === pack)
        : topup.findIndex(product => product === pack)
    const packSigIndex =
      (packIndex === -1 ? 1 : packIndex + 1) %
      (pack.productType === 'BUNDLE' ? bundle.length : topup.length)
    const packFinalIndex =
      (packIndex === -1 ? 2 : packIndex + 2) %
      (pack.productType === 'BUNDLE' ? bundle.length : topup.length)

    setCurrentCategoryMetrics(
      currentCategory === 'bundle' ? 'Paquetes-' : 'Recarga-',
    )

    return {
      currentPackages,
      currentCategory,
      currentPosition,
      packSig:
        pack.productType === 'BUNDLE'
          ? bundle[packSigIndex]
          : topup[packSigIndex],
      packFinal:
        pack.productType === 'BUNDLE'
          ? bundle[packFinalIndex]
          : topup[packFinalIndex],
    }
  }

  const fetchProducts = async () => {
    try {
      const { productsData, calledService } = await getProducts()

      if (Object.keys(productsData).length > 0) {
        const arrProducts = productsData.products

        const bundle = arrProducts
          .filter(product => product.productType === 'BUNDLE')
          .toSorted((a, b) => b.amount - a.amount)

        let topup
        if (countryCode !== '504') {
          topup = arrProducts
            .filter(
              product =>
                product.productType === 'TOPUP' &&
                product.productId !== 'TOPUP200',
            )
            .toSorted((a, b) => b.amount - a.amount)
        } else {
          topup = arrProducts
            .filter(product => product.productType === 'TOPUP')
            .toSorted((a, b) => b.amount - a.amount)
        }

        const menuCategory = getLocalStorage(MENU_CATEGORY) || null
        const pack = getPack(menuCategory, bundle, topup)
        menuCategory && clearLocalStorage(MENU_CATEGORY)

        const {
          currentPackages,
          currentCategory,
          currentPosition,
          packSig,
          packFinal,
        } = getPackDetails(pack, bundle, topup)

        const metricsDataHome = { metricType: 'home', metricName: 'Home' }
        const metricsDataCategory = {
          metricType: 'category',
          metricName: currentCategory === 'bundle' ? 'Superpacks' : 'Recargas',
        }

        if (Object.keys(homeProduct).length === 0 && calledService) {
          sendMetrics(metricsDataHome)
          sendMetrics(metricsDataCategory)
        }

        setState({
          bundle,
          topup,
          pack,
          currentPackages,
          currentCategory,
          currentPosition,
          packSig,
          packFinal,
        })
      }
    } catch (error) {}
  }

  const menuRedirect = getLocalStorage(MENU_REDIRECT)
  const productSelected = JSON.parse(getLocalStorage(PRODUCT_SELECTED_HOME))

  useEffect(() => {
    if (menuRedirect) {
      if (isAuthenticated) {
        redirectMenu(menuRedirect)
      } else {
        history.push(claroHomeAuth)
      }
    } else if (productSelected) {
      if (isAuthenticated) {
        history.push(claroHomePayment)
      } else {
        history.push(claroHomeAuth)
      }
    } else {
      fetchProducts()
    }
  }, [])

  const handleSelectedProduct = async () => {
    setHomeProduct(pack)
    const product = pack
    clearData()
    const metricsData = {
      metricType: 'BtnContinuar',
      metricName:
        'Botón_' +
        (state.currentCategory === 'bundle' ? 'Paquetes' : 'Recargas') +
        '_Continuar',
    }
    if (isAuthenticated) {
      sendMetrics(metricsData)
      const ahora = new Date()
      const expiracion = ahora.getTime() + 30 * 60000
      const objetoConExpiracion = { product, expiracion: expiracion }
      setLocalStorage(
        PRODUCT_SELECTED_HOME,
        JSON.stringify(objetoConExpiracion),
      )
      history.push(claroHomePayment)
    } else {
      sendMetrics(metricsData)
      const ahora = new Date()
      const expiracion = ahora.getTime() + 30 * 60000
      const objetoConExpiracion = { product, expiracion: expiracion }
      setLocalStorage(
        PRODUCT_SELECTED_HOME,
        JSON.stringify(objetoConExpiracion),
      )
      window.location.href = window.location.origin
    }
  }

  const handleCategory = category => {
    setOpcionSeleccionada(category === 'bundle' ? 'Superpacks' : 'Recargas')

    setCurrentCategoryMetrics(category === 'bundle' ? 'Paquetes-' : 'Recarga-')
    const metricsData = {
      metricType: 'category',
      metricName: category === 'bundle' ? 'Superpacks' : 'Recargas',
    }
    sendMetrics(metricsData)

    const packCategory = category === 'bundle' ? state.bundle : state.topup
    setState(prevState => ({
      ...prevState,
      currentCategory: category,
      currentPosition: 0,
      pack: packCategory[0],
      packSig: packCategory.length >= 2 ? packCategory[1] : packCategory[0],
      packFinal: packCategory.length >= 3 ? packCategory[2] : packCategory[0],
    }))
  }

  const handleNextProduct = action => {
    const { currentPosition, currentCategory, topup, bundle } = state
    const packCategory = currentCategory === 'bundle' ? bundle : topup
    const totalItems = packCategory.length

    let position = currentPosition
    let nextPosition, nextPositionFinal

    if (action === 'next') {
      position = (currentPosition + 1) % totalItems
      const metricsData = {
        metricType: 'Carrousel',
        metricName:
          (state.currentCategory === 'bundle' ? 'Paquetes' : 'Recargas') +
          '_Carrousel_Flecha-Derecha',
      }
      sendMetrics(metricsData)
    } else if (action === 'prev') {
      position = (currentPosition - 1 + totalItems) % totalItems
      const metricsData = {
        metricType: 'Carrousel',
        metricName:
          (state.currentCategory === 'bundle' ? 'Paquetes' : 'Recargas') +
          '_Carrousel_Flecha-Izquierda',
      }
      sendMetrics(metricsData)
    }

    nextPosition = (position + 1) % totalItems
    nextPositionFinal = (nextPosition + 1) % totalItems

    setState(prevState => ({
      ...prevState,
      currentPosition: position,
      pack: packCategory[position],
      packSig: packCategory[nextPosition],
      packFinal: packCategory[nextPositionFinal],
    }))
  }

  const {
    currentPosition,
    currentCategory,
    pack,
    packSig,
    packFinal,
    bundle,
    topup,
  } = state

  return (
    <>
      {!isLoading && products && !menuRedirect && !productSelected && (
        <div className="claro__container__home">
          <CarouselPackages
            currentCategory={currentCategory}
            handleCategory={handleCategory}
            pack={pack}
            packSig={packSig}
            packFinal={packFinal}
            handleNextProduct={handleNextProduct}
            bundle={bundle}
            topup={topup}
            currentPosition={currentPosition}
            handleSelectedProduct={handleSelectedProduct}
          />
        </div>
      )}
    </>
  )
}

export default ClaroHome
