import React, { useContext, useEffect } from 'react'

import CustomSwitch from '../CustomSwitch'
import { lastTwoDigitsYear, penultimateTwoDigitsYear } from '../DateYear'
import { nextInput } from '../NextInput'
import { validateIsInteger } from '../ValidateIsInteger'

import { labels } from '../../constants/ClaroPayConstants'

import ClaroPayContext from '../../context/ClaroPayContext'

import circleAccepted from '../../assets/img/circle_accepted.svg'
import circleNotAccepted from '../../assets/img/circle_not_accepted.svg'
import hiddenNumberCard from '../../assets/img/hiddenNumberCard.svg'

const SavedCard = ({ isVisible, creditCard, sendMetrics }) => {
  const claroPayContext = useContext(ClaroPayContext)

  const { LAST_DIGITS, EXPIRATION_DATE, REMEMBER_DATE } = labels

  const {
    setSelectedSavedCard,
    setIsVisibleSectionAddNewCard,
    setIsVisibleAddNewPaymentMethod,
    setIsVisibleAddNewAccountBi,
    setSelectAccountBi,
    getImage,
    month,
    setMonth,
    year,
    setYear,
    saveCard,
    setSaveCard,
  } = claroPayContext

  useEffect(() => {
    const img = new Image()
    img.src = hiddenNumberCard
  }, [])

  useEffect(() => {
    const img = new Image()
    img.src = circleAccepted
  }, [])

  const handleChangeMonth = event => {
    const number = event.target.value
    if (validateIsInteger(number)) {
      if (number.length <= 2) {
        if (number >= 0 && number < 13) {
          setMonth(number.trim())
          if (number.length === 2) {
            nextInput(event)
          }
        }
      }
    }
  }

  const handleChangeYear = event => {
    const number = event.target.value
    if (validateIsInteger(number)) {
      if (number.length <= 2) {
        if (
          (year === '' && number >= penultimateTwoDigitsYear) ||
          number >= lastTwoDigitsYear ||
          (year !== '' && year.length === 2) ||
          (year !== '' && number === '')
        ) {
          setYear(number)
          if (number.length === 2) {
            nextInput(event)
          }
        }
      }
    }
  }

  const handleChangeSwitch = value => {
    if (sendMetrics !== undefined && value) {
      const metricsData = {
        metricType: 'CheckOut_',
        metricName: `TCFecha-${creditCard.position}`,
      }
      sendMetrics(metricsData)
    }
    setSaveCard(value)
  }

  const handleVisibility = () => {
    if (!isVisible) {
      if (sendMetrics !== undefined) {
        const metricsData = {
          metricType: 'CheckOut_',
          metricName: `PagarTC-${creditCard.position}`,
        }
        sendMetrics(metricsData)
      }
      setSelectedSavedCard(creditCard)
    } else {
      setSelectedSavedCard({})
    }
    setIsVisibleAddNewPaymentMethod(false)
    setIsVisibleAddNewAccountBi(false)
    setIsVisibleSectionAddNewCard(false)
    setSelectAccountBi(null)
    setMonth('')
    setYear('')
    setSaveCard(false)
  }

  return (
    <>
      {!isVisible ? (
        <div
          className="claro__container__payment__options__savedCard"
          onClick={handleVisibility}
        >
          <img
            src={`${isVisible ? circleAccepted : circleNotAccepted}`}
            alt={`${LAST_DIGITS}: ${creditCard.lastDigits}`}
          />
          {`${LAST_DIGITS}: ${creditCard.lastDigits}`}
          <div className="claro__container__payment__options__savedCard__logo">
            <img src={getImage(creditCard.icon)} alt="icon" />
          </div>
        </div>
      ) : (
        <>
          <div className="claro__container__payment__options__savedCard__selected">
            <div className="claro__container__payment__options__savedCard__selected__header">
              <div
                className="claro__container__payment__options__savedCard__selected__header__circle"
                onClick={handleVisibility}
              >
                <img
                  src={`${isVisible ? circleAccepted : circleNotAccepted}`}
                  alt={`${LAST_DIGITS}: ${creditCard.lastDigits}`}
                />
              </div>
              <div className="claro__container__payment__options__savedCard__selected__header__content">
                <div className="claro__container__payment__options__savedCard__selected__header__content__logo">
                  <img src={getImage(creditCard.icon)} alt="icon" />
                </div>
                <div className="claro__container__payment__options__savedCard__selected__header__content__hiddenNumber">
                  <img src={hiddenNumberCard} alt="hidden number" />
                </div>
                <div className="claro__container__payment__options__savedCard__selected__header__content__number">
                  {creditCard.lastDigits}
                </div>
              </div>
            </div>

            <div className="claro__container__payment__options__savedCard__selected__body">
              <div className="claro__container__payment__options__savedCard__selected__body__text">
                Vigencia
              </div>

              <form className="claro__container__payment__options__savedCard__selected__body__form">
                <div className="claro__container__payment__options__savedCard__selected__body__form__inputs">
                  <input
                    placeholder="00"
                    maxLength="2"
                    type="text"
                    inputMode="numeric"
                    name="month"
                    value={month}
                    autoFocus
                    onChange={handleChangeMonth}
                  />{' '}
                  /{' '}
                  <input
                    placeholder="00"
                    maxLength="2"
                    type="text"
                    inputMode="numeric"
                    name="year"
                    value={year}
                    onChange={handleChangeYear}
                  />
                </div>
                <div className="claro__container__payment__options__savedCard__selected__body__form__text">
                  {EXPIRATION_DATE}
                </div>
              </form>
            </div>
          </div>
          <div className="claro__container__payment__options__savedCard__selected__remember">
            <CustomSwitch
              isSelected={saveCard}
              onClick={handleChangeSwitch}
              text={REMEMBER_DATE}
            />
          </div>
        </>
      )}
    </>
  )
}
export default SavedCard
