import React from 'react'

import CustomButton from '../CustomButton'
import logoBiButton from '../../assets/img/logoBiBlanco.png'

const PaymentButton = ({
  selectAccountBi,
  iIsVisibleAddNewAccountBi,
  indicator,
  countryCode,
  textBtn,
  handlePaymentNewAccountBi,
  handlePaymentAccountBi,
  canClickButton,
  handlePayment,
  isVisibleSectionAddNewCard,
  isEnabledPayWithNewCard,
  isEnabledPayWithSavedCard,
}) => {
  return (
    <div className="claro__container__button">
      {(selectAccountBi !== null || iIsVisibleAddNewAccountBi) &&
      indicator &&
      countryCode === '507' ? (
        <CustomButton
          text={textBtn}
          onClick={
            iIsVisibleAddNewAccountBi
              ? handlePaymentNewAccountBi
              : handlePaymentAccountBi
          }
          customClass="claro__container__button__paymentBi"
          enabled={canClickButton}
          img={logoBiButton}
        />
      ) : (
        <CustomButton
          text={textBtn}
          onClick={handlePayment}
          customClass="claro__container__button__payment"
          enabled={
            (isVisibleSectionAddNewCard
              ? isEnabledPayWithNewCard
              : isEnabledPayWithSavedCard) && canClickButton
          }
        />
      )}
    </div>
  )
}

export default PaymentButton
