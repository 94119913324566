import React, { useContext } from 'react'

import { useHistory } from 'react-router-dom'

import ClaroPayContext from '../../context/ClaroPayContext'

import localRoutes from '../../constants/routes'

import ClaroPayPaymentOptionsWithBi from '../ClaroPayPaymentOptionsWithBi'

import PaymentButton from '../../components/PaymentButton'
import { generarUuid } from '../../components/GenerarUuid'
import SelectedOffer from '../../components/SelectedOffer'

import productDemoBi from '../../assets/img/productWCM.svg'

const DemoBi = () => {
  const history = useHistory()

  const claroPayContext = useContext(ClaroPayContext)

  const {
    isVisibleSectionAddNewCard,
    isEnabledPayWithSavedCard,
    isEnabledPayWithNewCard,
    setProcessingPayment,
    setResponseService,
    selectAccountBi,
    iIsVisibleAddNewAccountBi,
    setIsVisibleAddNewPaymentMethod,
    payWithAccountBiDemoBi,
    payWithNewAccountBi,
    setIsVisibleAddNewAccountBi,
    canClickButton,
    setCanClickButton,
    demoBiData,
  } = claroPayContext

  const {
    routesClaroPay: { claroPayPaymentProcess },
  } = localRoutes

  const handlePaymentAccountBi = async () => {
    setCanClickButton(false)
    setResponseService(null)
    const uuid = generarUuid()
    setProcessingPayment(true)
    setIsVisibleAddNewPaymentMethod(false)
    history.push(claroPayPaymentProcess)
    const response = await payWithAccountBiDemoBi(uuid)
    setResponseService(response)
  }

  const handlePaymentNewAccountBi = async () => {
    setIsVisibleAddNewPaymentMethod(false)
    setResponseService(null)
    setProcessingPayment(true)
    setIsVisibleAddNewAccountBi(true)
    history.push(claroPayPaymentProcess)
    payWithNewAccountBi()
  }

  const pack = demoBiData?.pack || {}
  const textBtn = `Pagar ${pack.currency || ''}${pack.amount || ''}`
  const currentCategory =
    demoBiData?.pack.productType === 'TOPUP' ? 'topup' : 'bundle'
  const countryCode = '507'
  const indicator = demoBiData?.indicator || false

  return (
    <div className="claro__container__demoBi">
      <div className="claro__container__demoBi__header">
        <img
          className="claro__container__demoBi__header__image"
          src={productDemoBi}
          alt="demoBi"
        />
        <div className="claro__container__demoBi__header__title">
          Recomendado para ti
        </div>
      </div>

      <SelectedOffer pack={pack} currentCategory={currentCategory} />

      <ClaroPayPaymentOptionsWithBi />

      <PaymentButton
        selectAccountBi={selectAccountBi}
        iIsVisibleAddNewAccountBi={iIsVisibleAddNewAccountBi}
        indicator={indicator}
        countryCode={countryCode}
        textBtn={textBtn}
        handlePaymentNewAccountBi={handlePaymentNewAccountBi}
        handlePaymentAccountBi={handlePaymentAccountBi}
        canClickButton={canClickButton}
        handlePayment={null}
        isVisibleSectionAddNewCard={isVisibleSectionAddNewCard}
        isEnabledPayWithNewCard={isEnabledPayWithNewCard}
        isEnabledPayWithSavedCard={isEnabledPayWithSavedCard}
      />
    </div>
  )
}

export default DemoBi
