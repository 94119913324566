import React, { useContext, useState, useEffect } from 'react'

import { useHistory } from 'react-router-dom'

import ClaroPayContext from '../../context/ClaroPayContext'

import { RESPONSE_CODE } from '../../constants/responseCode'
import BACK_END_ROUTES from '../../constants/api_backend_url_supplier'
import Country from '../../constants/Country'
import {
  AUTH_TOKENS_ADM,
  PRODUCT_AUTH_ADM,
  MSISDN_ADM,
  UUID_PROCESS_ADM,
  PROCESO_PAGO,
} from '../../constants/ClaroPayConstants'
import localRoutes from '../../constants/routes'

import { postAxiosService } from '../../services/postAxiosService'
import { getAxiosService } from '../../services/getAxiosService'
import {
  setLocalStorage,
  clearLocalStorage,
  getLocalStorage,
} from '../../services/storage'

import { generarUuid } from '../../components/GenerarUuid'
import useTouchHandler from '../../components/UseTouch'
import CustomButton from '../../components/CustomButton'
import Login from '../../components/Login'

import authMsisdn from '../../assets/img/authMsisdn.svg'
import ArrowLeft from '../../assets/img/arrowLeft.svg'
import ArrowRight from '../../assets/img/arrow.svg'

const ClaroAdm = () => {
  const history = useHistory()

  const claroPayContext = useContext(ClaroPayContext)

  const {
    admData,
    setAdmData,
    setTokenAdm,
    tokenAdm,
    productsAdm,
    setProductsAdm,
    setResponseService,
    setProcessingPayment,
    activeAdm,
    setSelectedPackAdm,
    loadingAdm,
    setLoadingAdm,
    code,
    sendMetrics,
    setCanClickButton,
    canClickButton,
    setExitFunctionAdm,
    notAdm,
    setNotAdm,
    getImage,
    formatNumero,
    isAuthenticatedAdm,
  } = claroPayContext

  const {
    OK,
    OTRO_OPERADOR,
    NO_PREPAGO,
    ANTIFRAUDE,
    ANTIFRAUDE_KO,
    BLACKLIST,
    TIMEOUT,
    CODIGO_NO_VALIDO,
    CODIGO_EXPIRADO,
    ERROR_INTENTOS,
    REENVIO_SMS,
    OK_ADM,
    PAGA_TU_PRESTAMO_ADM,
    SESSION_CODE_EXPIRED_ADM,
    NOT_PERMISSIONS_ADM,
    ERROR_CODE,
    TOO_MANY_REQUESTS,
    DOES_NOT_APPLY,
    FAIL_SERVER_CLARO_PAY,
  } = RESPONSE_CODE

  const {
    routesClaroPay: { claroAdmProcess },
  } = localRoutes

  const { countryCode } = Country()

  const [valueMsisdn, setValueMsisdn] = useState('')
  const [valueMsisdnValidated, setValueMsisdnValidated] = useState()
  const [errorMessageMsisdn, setErrorMessageMsisdn] = useState('')
  const [responseDescriptionMsisdn, setResponseDescriptionMsisdn] = useState()
  const [
    responseDescriptionMsisdnResend,
    setResponseDescriptionMsisdnResend,
  ] = useState()

  const [valueCode, setValueCode] = useState('')
  const [errorMessageCode, setErrorMessageCode] = useState('')
  const [isButtonEnabledMsisdn, setIsButtonEnabledMsisdn] = useState(false)
  const [isButtonEnabledCode, setIsButtonEnabledCode] = useState(false)
  const [responseDescriptionCode, setResponseDescriptionCode] = useState()

  const [state, setState] = useState({
    currentPosition: 0,
    pack: {
      title: '',
      description: [],
      validity: '',
      amount: 0,
      currency: '',
      productId: '',
    },
  })

  const [arrProducts, setArrProducts] = useState(null)

  const exit = () => {
    fixPhoneNumber()
    clearLocalStorage(AUTH_TOKENS_ADM)
    clearLocalStorage(MSISDN_ADM)
    clearLocalStorage(PRODUCT_AUTH_ADM)
    clearLocalStorage(UUID_PROCESS_ADM)
    setTokenAdm({
      valid: false,
      token: '',
    })
    setAdmData([])
    setProductsAdm([])
    setSelectedPackAdm(null)
    setArrProducts(null)
    setNotAdm(false)
  }

  useEffect(() => {
    setExitFunctionAdm(() => exit)
    clearLocalStorage(UUID_PROCESS_ADM)
    if (isAuthenticatedAdm) {
      const productAuthAdm = getLocalStorage(PRODUCT_AUTH_ADM)
      if (productAuthAdm) {
        productoAdm()
      } else if (admData.responseCode === PAGA_TU_PRESTAMO_ADM.code) {
        setProcessingPayment(true)
        setSelectedPackAdm(null)
        setResponseService(PAGA_TU_PRESTAMO_ADM)
        history.push(claroAdmProcess)
      } else if (admData.responseCode === SESSION_CODE_EXPIRED_ADM.code) {
        exit()
      } else if (admData.responseCode === NOT_PERMISSIONS_ADM.code) {
        setProcessingPayment(true)
        setSelectedPackAdm(null)
        setResponseService(NOT_PERMISSIONS_ADM)
        history.push(claroAdmProcess)
      }
    }
  }, [])

  useEffect(() => {
    const metricsDataHome = {
      metricType: 'home',
      metricName: 'Home',
    }

    if (code) {
      sendMetrics(metricsDataHome)
    }
  }, [code])

  const productoAdm = async () => {
    const Authorization =
      getLocalStorage(AUTH_TOKENS_ADM) !== undefined &&
      getLocalStorage(AUTH_TOKENS_ADM) !== null
        ? { Authorization: `Bearer ${getLocalStorage(AUTH_TOKENS_ADM)}` }
        : {}
    setTokenAdm({ valid: true, token: getLocalStorage(AUTH_TOKENS_ADM) })
    try {
      setLoadingAdm(true)
      const url = BACK_END_ROUTES.admData
      if (url !== null) {
        const headers = { ...Authorization }
        const response = await getAxiosService(url, { headers })

        switch (response.responseCode) {
          case OK_ADM.code:
            fixPhoneNumber()
            setProductsAdm(response.responseContent)
            return

          case PAGA_TU_PRESTAMO_ADM.code:
            setProcessingPayment(true)
            setSelectedPackAdm(null)
            setResponseService(PAGA_TU_PRESTAMO_ADM)
            history.push(claroAdmProcess)
            return

          case NOT_PERMISSIONS_ADM.code:
            setProcessingPayment(true)
            setSelectedPackAdm(null)
            setResponseService(NOT_PERMISSIONS_ADM)
            history.push(claroAdmProcess)
            return

          case DOES_NOT_APPLY.code:
            setNotAdm(true)
            fixPhoneNumber()
            clearLocalStorage(AUTH_TOKENS_ADM)
            clearLocalStorage(MSISDN_ADM)
            clearLocalStorage(PRODUCT_AUTH_ADM)
            clearLocalStorage(UUID_PROCESS_ADM)
            setTokenAdm({
              valid: false,
              token: '',
            })
            setAdmData([])
            setProductsAdm([])
            setSelectedPackAdm(null)
            setArrProducts(null)
            return

          default:
            return exit()
        }
      }
    } catch (error) {
      exit()
    } finally {
      setLoadingAdm(false)
    }
  }

  useEffect(() => {
    const productAuthAdm = getLocalStorage(PRODUCT_AUTH_ADM)
    if (productAuthAdm && productsAdm && productsAdm.pack) {
      setArrProducts(productsAdm.pack)
    } else if (
      !productAuthAdm &&
      admData &&
      admData.responseContent &&
      admData.responseContent.pack
    ) {
      setArrProducts(admData.responseContent.pack)
    }
  }, [productsAdm, admData])

  useEffect(() => {
    if (isAuthenticatedAdm) {
      if (arrProducts) {
        const pack = arrProducts[0]

        setState(prevState => ({
          ...prevState,
          pack,
        }))
      }
    }
  }, [arrProducts])

  const focusPhoneNumberInput = async () => {
    await new Promise(resolve => setTimeout(resolve, 10000))
    const phoneNumberInput = document.getElementById('phoneNumberInput')
    if (phoneNumberInput) {
      phoneNumberInput.focus()
      phoneNumberInput.click()
    }
  }

  useEffect(() => {
    const handleTokenAndStorage = (admData, randomToken) => {
      const productAuthAdm = getLocalStorage(PRODUCT_AUTH_ADM)
      if (
        !productAuthAdm &&
        admData &&
        admData.responseCode === 0 &&
        admData.responseContent &&
        admData.responseContent.pack
      ) {
        const { msisdn } = admData.responseContent
          ? admData.responseContent
          : {}

        setLocalStorage(AUTH_TOKENS_ADM, randomToken)
        setLocalStorage(MSISDN_ADM, msisdn)
        setTokenAdm({
          valid: true,
          token: randomToken,
        })
      }
    }

    const randomToken = generarUuid()
    handleTokenAndStorage(admData, randomToken)
  }, [admData, setTokenAdm])

  const confirmMsisdn = async () => {
    try {
      setIsButtonEnabledMsisdn(false)
      const data = {
        msisdn: countryCode + valueMsisdn,
      }
      const url = BACK_END_ROUTES.admMsisdn
      const response = await postAxiosService(url, data)
      return await processConfirmMsisdnResponse(response)
    } catch (error) {
      return await processConfirmMsisdnResponse({
        responseCode: FAIL_SERVER_CLARO_PAY.code,
      })
    }
  }

  const processConfirmMsisdnResponse = async response => {
    switch (response.responseCode) {
      case OK.code:
        setValueMsisdnValidated(response.responseContent.msisdn)
        setResponseDescriptionMsisdn(null)
        return OK

      case OTRO_OPERADOR.code:
        setResponseDescriptionMsisdn(OTRO_OPERADOR.description)
        focusPhoneNumberInput()
        return OTRO_OPERADOR

      case NO_PREPAGO.code:
        setResponseDescriptionMsisdn(NO_PREPAGO.description)
        focusPhoneNumberInput()
        return NO_PREPAGO

      case ANTIFRAUDE.code:
        setResponseDescriptionMsisdn(ANTIFRAUDE.description)
        focusPhoneNumberInput()
        return ANTIFRAUDE

      case ANTIFRAUDE_KO.code:
        setResponseDescriptionMsisdn(ANTIFRAUDE_KO.description)
        focusPhoneNumberInput()
        return ANTIFRAUDE_KO

      case BLACKLIST.code:
        setResponseDescriptionMsisdn(BLACKLIST.description)
        focusPhoneNumberInput()
        return BLACKLIST

      case TIMEOUT.code:
        setResponseDescriptionMsisdn(TIMEOUT.description)
        focusPhoneNumberInput()
        return TIMEOUT

      case ERROR_CODE.code:
        setResponseDescriptionMsisdn(ERROR_CODE.description)
        focusPhoneNumberInput()
        return ERROR_CODE

      case ERROR_INTENTOS.code:
        setResponseDescriptionMsisdn(ERROR_INTENTOS.description)
        focusPhoneNumberInput()
        return ERROR_INTENTOS

      case FAIL_SERVER_CLARO_PAY.code:
        setResponseDescriptionMsisdn(FAIL_SERVER_CLARO_PAY.description)
        focusPhoneNumberInput()
        return FAIL_SERVER_CLARO_PAY

      default:
        setResponseDescriptionMsisdn(TOO_MANY_REQUESTS.description)
        focusPhoneNumberInput()
        return TOO_MANY_REQUESTS
    }
  }

  const confirmCode = async () => {
    try {
      setIsButtonEnabledCode(false)
      const data = {
        msisdn: valueMsisdnValidated,
        code: valueCode,
      }
      const url = BACK_END_ROUTES.admCode
      const response = await postAxiosService(url, data)
      return await processAuthCodeResponse(response)
    } catch (error) {
      return await processAuthCodeResponse({
        responseCode: FAIL_SERVER_CLARO_PAY.code,
      })
    }
  }

  const processAuthCodeResponse = async response => {
    switch (response.responseCode) {
      case OK.code:
        setLocalStorage(AUTH_TOKENS_ADM, response.responseContent.accessToken)
        setLocalStorage(MSISDN_ADM, valueMsisdnValidated)
        setLocalStorage(PRODUCT_AUTH_ADM, true)
        productoAdm()
        return OK

      case CODIGO_NO_VALIDO.code:
        setResponseDescriptionCode(CODIGO_NO_VALIDO.description)
        return CODIGO_NO_VALIDO

      case CODIGO_EXPIRADO.code:
        setResponseDescriptionCode(CODIGO_EXPIRADO.description)
        return CODIGO_EXPIRADO

      case TIMEOUT.code:
        setResponseDescriptionCode(TIMEOUT.description)
        return TIMEOUT

      case ERROR_INTENTOS.code:
        setResponseDescriptionCode(ERROR_INTENTOS.description)
        return ERROR_INTENTOS

      case FAIL_SERVER_CLARO_PAY.code:
        setResponseDescriptionCode(FAIL_SERVER_CLARO_PAY.description)
        return FAIL_SERVER_CLARO_PAY

      default:
        setResponseDescriptionCode(TOO_MANY_REQUESTS.description)
        return TOO_MANY_REQUESTS
    }
  }

  const resendCode = async () => {
    try {
      const data = {
        msisdn: valueMsisdnValidated,
      }
      const url = BACK_END_ROUTES.admMsisdn
      const response = await postAxiosService(url, data)
      return await processAuthResendCodeResponse(response)
    } catch (error) {
      return await processAuthResendCodeResponse({
        responseCode: FAIL_SERVER_CLARO_PAY.code,
      })
    }
  }

  const processAuthResendCodeResponse = async response => {
    switch (response.responseCode) {
      case REENVIO_SMS.code:
        setResponseDescriptionMsisdnResend(REENVIO_SMS.description)
        return REENVIO_SMS

      case OTRO_OPERADOR.code:
        setResponseDescriptionMsisdnResend(OTRO_OPERADOR.description)
        return OTRO_OPERADOR

      case NO_PREPAGO.code:
        setResponseDescriptionMsisdnResend(NO_PREPAGO.description)
        return NO_PREPAGO

      case ANTIFRAUDE.code:
        setResponseDescriptionMsisdnResend(ANTIFRAUDE.description)
        return ANTIFRAUDE

      case ANTIFRAUDE_KO.code:
        setResponseDescriptionMsisdnResend(ANTIFRAUDE_KO.description)
        return ANTIFRAUDE_KO

      case BLACKLIST.code:
        setResponseDescriptionMsisdnResend(BLACKLIST.description)
        return BLACKLIST

      case TIMEOUT.code:
        setResponseDescriptionMsisdnResend(TIMEOUT.description)
        return TIMEOUT

      case ERROR_CODE.code:
        setResponseDescriptionMsisdnResend(ERROR_CODE.description)
        return ERROR_CODE

      case ERROR_INTENTOS.code:
        setResponseDescriptionMsisdnResend(ERROR_INTENTOS.description)
        return ERROR_INTENTOS

      case FAIL_SERVER_CLARO_PAY.code:
        setResponseDescriptionMsisdnResend(FAIL_SERVER_CLARO_PAY.description)
        return FAIL_SERVER_CLARO_PAY

      default:
        setResponseDescriptionMsisdnResend(TOO_MANY_REQUESTS.description)
        return TOO_MANY_REQUESTS
    }
  }

  const { pack } = state
  const concatenatedTexts = pack?.description?.reduce((acc, item, idx) => {
    const itemKey = `text-${idx}`

    if (idx !== 0) {
      acc.push(
        <span key={`separator-${itemKey}`}>
          {' '}
          +<br />
        </span>,
      )
    }

    acc.push(<span key={itemKey}>{item.text}</span>)
    return acc
  }, [])

  const icons = pack?.description?.map(item => item.iconTitle)

  const formattedAmount = formatNumero(pack?.amount)

  useEffect(() => {
    setProcessingPayment(false)
    const procesoPago = getLocalStorage(PROCESO_PAGO)
    if (procesoPago) {
      setCanClickButton(false)
      setTimeout(() => {
        setCanClickButton(true)
        clearLocalStorage(PROCESO_PAGO)
      }, 30000)
    } else {
      setCanClickButton(true)
    }
    setResponseService(null)
  }, [])

  const handleChangeMsisdn = e => {
    let numericValue = e.target.value.replace(/\D/g, '')
    numericValue = numericValue.slice(0, 8)
    setValueMsisdn(numericValue)

    if (numericValue.length !== 8) {
      setErrorMessageMsisdn('El número debe tener 8 dígitos')
    } else {
      setErrorMessageMsisdn('')
    }
    setResponseDescriptionMsisdn(null)
    setIsButtonEnabledMsisdn(numericValue.length === 8)
  }

  const handleChangeCode = e => {
    let numericValue = e.target.value.replace(/\D/g, '')
    numericValue = numericValue.slice(0, 6)
    setValueCode(numericValue)

    if (numericValue.length !== 6) {
      setErrorMessageCode('El código debe tener 6 dígitos')
    } else {
      setErrorMessageCode('')
    }
    setResponseDescriptionCode(null)
    setResponseDescriptionMsisdnResend(null)
    setIsButtonEnabledCode(numericValue.length === 6)
  }

  const fixPhoneNumber = () => {
    setValueMsisdnValidated(null)
    setValueCode('')
    setValueMsisdn('')

    setErrorMessageMsisdn('')
    setErrorMessageCode('')
    setResponseDescriptionMsisdn()
    setResponseDescriptionMsisdnResend()
    setResponseDescriptionCode()
    setIsButtonEnabledCode(false)
  }

  const handleActive = async () => {
    setCanClickButton(false)
    setResponseService(null)
    const uuid = generarUuid()
    const metricsData = {
      metricType: 'Botón_Aceptar',
      metricName: 'Botón_Aceptar',
    }
    sendMetrics(metricsData)
    clearLocalStorage(UUID_PROCESS_ADM)
    setProcessingPayment(true)
    setSelectedPackAdm(pack)
    const productId = pack.productId
    setLocalStorage(UUID_PROCESS_ADM, uuid)
    history.push(claroAdmProcess)
    const response = await activeAdm(productId)
    setResponseService(response)
  }

  const handleNextProduct = action => {
    const { currentPosition } = state

    const totalItems = arrProducts.length

    let position = currentPosition

    if (action === 'next') {
      position = (currentPosition + 1) % totalItems
      const metricsData = {
        metricType: 'Carrousel',
        metricName: 'ADM_Carrousel_Flecha-Derecha',
      }
      sendMetrics(metricsData)
    } else if (action === 'prev') {
      position = (currentPosition - 1 + totalItems) % totalItems
      const metricsData = {
        metricType: 'Carrousel',
        metricName: 'ADM_Carrousel_Flecha-Izquierda',
      }
      sendMetrics(metricsData)
    }

    setState(prevState => ({
      ...prevState,
      currentPosition: position,
      pack: arrProducts[position],
    }))
  }

  const { handleTouchStart, handleTouchMove, handleTouchEnd } = useTouchHandler(
    handleNextProduct,
  )

  return (
    <>
      {!loadingAdm && !notAdm && (
        <>
          {isAuthenticatedAdm && tokenAdm.valid && arrProducts ? (
            <div className="claro__container__adm">
              <div className="claro__container__adm__title">
                Claro Te Presta
              </div>

              <div
                className="claro__container__adm__content"
                onTouchStart={arrProducts.length > 1 ? handleTouchStart : null}
                onTouchMove={arrProducts.length > 1 ? handleTouchMove : null}
                onTouchEnd={arrProducts.length > 1 ? handleTouchEnd : null}
              >
                {arrProducts.length > 1 && (
                  <img
                    className="claro__container__adm__content__arrow"
                    src={ArrowLeft}
                    onClick={() => handleNextProduct('prev')}
                    alt="arrow left"
                  />
                )}

                <div className="claro__container__adm__content__offer">
                  <div className="claro__container__adm__content__offer__header">
                    {pack.title}
                  </div>

                  <div className="claro__container__adm__content__offer__body">
                    <div className="claro__container__adm__content__offer__body__description">
                      {concatenatedTexts}
                    </div>

                    <div className="claro__container__adm__content__offer__body__imageDescription">
                      {icons.length > 0 && (
                        <>
                          {icons.map(item => (
                            <img key={item} src={getImage(item)} alt="icon" />
                          ))}
                        </>
                      )}
                    </div>

                    <div className="claro__container__adm__content__offer__body__amount">
                      {pack.currency}
                      {formattedAmount}
                    </div>

                    <div className="claro__container__adm__content__offer__body__validity">
                      {` Vigencia ${pack.validity}`}
                    </div>
                  </div>

                  <div className="claro__container__adm__content__offer__footer">
                    Confirmas la compra de este paquete de{' '}
                    <span>Claro Te Presta </span>
                    que se descontará de tu próxima recarga.
                  </div>
                </div>

                {arrProducts.length > 1 && (
                  <img
                    className="claro__container__adm__content__arrow"
                    src={ArrowRight}
                    onClick={() => handleNextProduct('next')}
                    alt="arrow right"
                  />
                )}
              </div>

              <div className="claro__container__button">
                <CustomButton
                  text="Activar"
                  onClick={handleActive}
                  customClass="claro__container__button__payment"
                  enabled={canClickButton}
                />
              </div>
            </div>
          ) : (
            <Login
              authMsisdn={authMsisdn}
              valueMsisdn={valueMsisdn}
              valueMsisdnValidated={valueMsisdnValidated}
              valueCode={valueCode}
              handleChangeMsisdn={handleChangeMsisdn}
              handleChangeCode={handleChangeCode}
              confirmMsisdn={confirmMsisdn}
              fixPhoneNumber={fixPhoneNumber}
              resendCode={resendCode}
              confirmCode={confirmCode}
              isButtonEnabledMsisdn={isButtonEnabledMsisdn}
              isButtonEnabledCode={isButtonEnabledCode}
              errorMessageMsisdn={errorMessageMsisdn}
              errorMessageCode={errorMessageCode}
              responseDescriptionMsisdn={responseDescriptionMsisdn}
              setResponseDescriptionMsisdn={setResponseDescriptionMsisdn}
              responseDescriptionCode={responseDescriptionCode}
              setResponseDescriptionCode={setResponseDescriptionCode}
              responseDescriptionMsisdnResend={responseDescriptionMsisdnResend}
              setResponseDescriptionMsisdnResend={
                setResponseDescriptionMsisdnResend
              }
            />
          )}
        </>
      )}
    </>
  )
}

export default ClaroAdm
