const Categories = ({ currentCategory, handleCategory, bundle }) => {
  return (
    <div className="claro__container__categories">
      {Object.keys(bundle).length > 0 && (
        <div
          className={`claro__container__categories__option ${
            currentCategory === 'bundle' ? 'bundle' : ''
          }`}
          onClick={
            currentCategory !== 'bundle' ? () => handleCategory('bundle') : null
          }
        >
          <div className="claro__container__categories__option__text">
            Superpacks
          </div>
        </div>
      )}
      <div className="claro__container__categories__option" />
      <div
        className={`claro__container__categories__option ${
          currentCategory === 'topup' ? 'topup' : ''
        }`}
        onClick={
          currentCategory !== 'topup' ? () => handleCategory('topup') : null
        }
      >
        <div className="claro__container__categories__option__text">
          Recargas
        </div>
      </div>
    </div>
  )
}

export default Categories
