import React, { useEffect } from 'react'

import Country from '../../constants/Country'

export default function Privacity({ history }) {
  const { domain } = Country()

  const domainUppercase = domain.toUpperCase()

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [])

  const handleRedirect = () => {
    history.go(-1)
  }

  return (
    <div className="claro__container__termsAndConditionsOrPrivacity">
      <div className="claro__container__termsAndConditionsOrPrivacity__goHome">
        <div onClick={handleRedirect}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14.509"
            height="25.377"
            viewBox="0 0 14.509 25.377"
          >
            <path
              className="icon"
              id="Icon_ionic-ios-arrow-forward"
              data-name="Icon ionic-ios-arrow-forward"
              d="M21.382,18.88l-9.6-9.6a1.806,1.806,0,0,1,0-2.561,1.829,1.829,0,0,1,2.569,0L25.228,17.6a1.81,1.81,0,0,1,.053,2.5L14.356,31.044a1.814,1.814,0,0,1-2.569-2.561Z"
              transform="translate(25.756 31.573) rotate(180)"
              fill="#2f2f2f"
            />
          </svg>
          Regresar
        </div>
      </div>
      <h1>
        AVISO DE PRIVACIDAD RELACIONADO CON LOS DATOS PERSONALES, RECABADOS POR
        FONYOU TELECOM SERVICES, S.A. DE C.V. (EN ADELANTE DENOMINADA EN ESTE
        AVISO COMO “FONYOU”)
      </h1>

      <h3>1.- GENERALES</h3>
      <ul>
        <li>
          <p>
            1.1. FONYOU ES UNA SOCIEDAD COMPROMETIDA Y RESPETUOSA DE LOS
            DERECHOS SOBRE LOS DATOS PERSONALES DE LAS PERSONAS FÍSICAS,
            RECONOCIDOS EN EL ARTÍCULO 16 FRACCIÓN II DE LA CONSTITUCIÓN
            POLÍTICA DE LOS ESTADOS UNIDOS MEXICANOS, ASÍ COMO DE LAS
            DISPOSICIONES DE LA LEY FEDERAL DE PROTECCIÓN DE DATOS PERSONALES EN
            POSESIÓN DE LOS PARTICULARES. POR LO ANTERIOR, PONE A SU DISPOSICIÓN
            EL PRESENTE AVISO DE PRIVACIDAD EN ARAS DE QUE EL TITULAR DE LOS
            DATOS PERSONALES, SE ENCUENTRE FACULTADO A EJERCITAR SU DERECHO A LA
            AUTODETERMINACIÓN INFORMATIVA.
          </p>
        </li>
        <li>
          <p>
            1.2. AL INGRESAR Y UTILIZAR EL PORTAL DE INTERNET, CUYO NOMBRE DE
            DOMINIO ES {domainUppercase}, Y SUS RESPECTIVOS SUBDOMINIOS, EN
            ADELANTE DENOMINADO COMO LA PÁGINA DE INTERNET, PROPIEDAD DE FONYOU,
            ASÍ COMO EL SERVICIO DE RECARGAS DE SALDO, USTED (EL TITULAR)
            DECLARA QUE ESTÁ ACEPTANDO LOS TÉRMINOS Y LAS CONDICIONES CONTENIDOS
            EN ESTE AVISO Y DECLARA Y OTORGA EXPRESAMENTE SU ACEPTACIÓN Y
            CONSENTIMIENTO UTILIZANDO PARA TAL EFECTO MEDIOS ELECTRÓNICOS, EN
            TÉRMINOS DE LO DISPUESTO POR EL ARTÍCULO 1803 DEL CÓDIGO CIVIL
            FEDERAL.
          </p>
        </li>
        <li>
          <p>
            1.3. SI EL TITULAR NO ACEPTA EN FORMA ABSOLUTA Y COMPLETA LOS
            TÉRMINOS Y CONDICIONES DE ESTE AVISO, DEBERÁ ABSTENERSE DE COMPARTIR
            CUALQUIER TIPO DE INFORMACIÓN A FONYOU POR CUALQUIER MEDIO
            INCLUYENDO EL SISTEMA DE RECARGAS O LA PÁGINA DE INTERNET, ASÍ COMO
            DE UTILIZAR DICHA PÁGINA DE INTERNET O SUS CORRESPONDIENTES
            SERVICIOS WEB.
          </p>
        </li>
        <li>
          <p>
            1.4. PARA EL CASO QUE EL TITULAR CONTINÚE EN EL USO DE LA PÁGINA DE
            INTERNET SEA EN FORMA TOTAL O PARCIAL, ASÍ COMO EL SERVICIO DE
            RECARGAS, DICHA ACCIÓN SE CONSIDERARÁ COMO SU ABSOLUTA Y EXPRESA
            ACEPTACIÓN A LOS TÉRMINOS Y CONDICIONES AQUÍ ESTIPULADOS.
          </p>
        </li>
        <li>
          <p>
            1.5. LA SOLA UTILIZACIÓN DE LA PÁGINA DE INTERNET DE FONYOU O EL USO
            DE SERVICIO DE RECARGAS IMPLICA PARA EL PÚBLICO TITULAR (EN ADELANTE
            REFERIDO COMO EL "TITULAR" O LOS "TITULARES") LA PLENA E
            INCONDICIONAL ACEPTACIÓN DE TODAS Y CADA UNA DE LAS CONDICIONES
            GENERALES Y PARTICULARES INCLUIDAS EN ESTE AVISO DE PRIVACIDAD EN LA
            VERSIÓN PUBLICADA POR FONYOU, EN EL MOMENTO MISMO EN QUE EL TITULAR
            ACCEDA A LA PÁGINA.
          </p>
        </li>
      </ul>
      <p>
        LAS PARTES DECLARAN QUE AL NO EXISTIR, ERROR, DOLO, MALA FE O CUALQUIER
        OTRO VICIO DE LA VOLUNTAD QUE PUDIERA NULIFICAR LA VALIDEZ DEL PRESENTE
        INSTRUMENTO, AMBAS ACUERDAN EN SUJETARSE AL TENOR DE LO ESTIPULADO EN
        LOS SIGUIENTES:
      </p>
      <h3>2.- DEFINICIONES</h3>
      <ul>
        <li>
          <p>
            2.1. TITULAR.- LA PERSONA FÍSICA (TITULAR) A QUIEN IDENTIFICAN O
            CORRESPONDEN LOS DATOS PERSONALES.
          </p>
        </li>
        <li>
          <p>
            2.2. DATOS PERSONALES SENSIBLES.- AQUELLOS DATOS PERSONALES QUE
            AFECTEN A LA ESFERA MÁS ÍNTIMA DE SU TITULAR, O CUYA UTILIZACIÓN
            INDEBIDA PUEDA DAR ORIGEN A DISCRIMINACIÓN O CONLLEVE UN RIESGO
            GRAVE PARA ÉSTE.
          </p>
        </li>
        <li>
          <p>
            2.3. RESPONSABLE.- PERSONA FÍSICA O MORAL (FONYOU) DE CARÁCTER
            PRIVADO QUE DECIDE SOBRE EL TRATAMIENTO DE LOS DATOS PERSONALES.
          </p>
        </li>
        <li>
          <p>
            2.4. ENCARGADO.- LA PERSONA FÍSICA O MORAL QUE SOLA O CONJUNTAMENTE
            CON OTRAS TRATE DATOS PERSONALES POR CUENTA DEL RESPONSABLE.
          </p>
        </li>
        <li>
          <p>
            2.5. TRATAMIENTO.- LA OBTENCIÓN, USO (QUE INCLUYE EL ACCESO, MANEJO,
            APROVECHAMIENTO, TRANSFERENCIA O DISPOSICIÓN DE DATOS PERSONALES),
            DIVULGACIÓN O ALMACENAMIENTO DE DATOS PERSONALES POR CUALQUIER
            MEDIO.
          </p>
        </li>
        <li className="tab">
          <p>
            2.5.1. TRANSFERENCIA.- TODA COMUNICACIÓN DE DATOS REALIZADA A
            PERSONA DISTINTA DEL RESPONSABLE O ENCARGADO DEL TRATAMIENTO.
          </p>
        </li>
        <li>
          <p>
            2.6. DERECHOS ARCO.- DERECHOS DE ACCESO, RECTIFICACIÓN, CANCELACIÓN
            Y OPOSICIÓN.
          </p>
        </li>
        <li>
          <p>
            2.7. CONSENTIMIENTO TÁCITO.- SE ENTENDERÁ QUE EL TITULAR HA
            CONSENTIDO EN EL TRATAMIENTO DE LOS DATOS, CUANDO HABIÉNDOSE PUESTO
            A SU DISPOSICIÓN EL AVISO DE PRIVACIDAD, NO MANIFIESTE SU OPOSICIÓN.
          </p>
        </li>
        <li>
          <p>
            2.8. FINALIDADES PRIMARIAS.- AQUELLAS FINALIDADES PARA LAS CUALES SE
            SOLICITAN PRINCIPALMENTE LOS DATOS PERSONALES Y POR LO QUE SE DA
            ORIGEN A LA RELACIÓN ENTRE FONYOU Y EL TITULAR.
          </p>
        </li>
        <li>
          <p>
            2.9. FINALIDADES SECUNDARIAS.- AQUELLAS FINALIDADES QUE NO SON
            IMPRESCINDIBLES PARA LA RELACIÓN ENTRE FONYOU Y EL TITULAR, PERO QUE
            CON SU TRATAMIENTO CONTRIBUYE AL CUMPLIMIENTO DEL OBJETO SOCIAL DE
            FONYOU.
          </p>
        </li>
      </ul>
      <h3>3.- IDENTIDAD Y DOMICILIO DEL RESPONSABLE</h3>
      <p>
        EL RESPONSABLE DE LA OBTENCIÓN DE LOS DATOS PERSONALES ES FONYOU TELECOM
        SERVICES, S.A. DE C.V. (EN ADELANTE DENOMINADO COMO “FONYOU”), QUIEN SE
        COMPROMETE A RESPETAR LO ESTABLECIDO EN EL PRESENTE AVISO DE PRIVACIDAD
        (EN LO SUCESIVO EL “AVISO”), EL CUAL ESTÁ PUESTO A SU DISPOSICIÓN EN
        CUMPLIMIENTO A LO ESTABLECIDO EN LA LEY FEDERAL DE PROTECCIÓN DE DATOS
        PERSONALES EN POSESIÓN DE LOS PARTICULARES (EN LO SUCESIVO LA “LEY”) Y,
        ES APLICABLE RESPECTO A LOS DATOS PERSONALES DE LAS PERSONAS FÍSICAS,
        QUE FONYOU OBTIENE CON MOTIVO DE LAS ACTIVIDADES QUE REALIZA CON LOS
        CANDIDATOS, TRABAJADORES, VISITANTES A LA PÁGINA DE INTERNET Y CLIENTES
        QUE REALIZAN RECARGAS DE SALDO.
      </p>
      <ul>
        <li>
          <p>
            3.1. EL DOMICILIO QUE PARA LOS EFECTOS DEL PRESENTE AVISO ESTABLECE
            FONYOU, ES EL UBICADO EN LA CALLE PROLONGACIÓN MOLIERE NO. 501,
            COLONIA AMPLIACIÓN GRANADA, CÓDIGO POSTAL 11520, CIUDAD DE MÉXICO,
            D.F., MÉXICO.
          </p>
        </li>
      </ul>
      <h3>4.- DATOS PERSONALES QUE SE RECABAN</h3>
      <ul>
        <li>
          <p>
            4.1. EL TITULAR RECONOCE Y ACEPTA QUE FONYOU OBTENDRÁ DIRECTAMENTE
            Y/O A TRAVÉS DE ENCARGADOS LOS SIGUIENTES DATOS PERSONALES,
            ATENDIENDO A LA RELACIÓN CON CADA TITULAR:
          </p>
          <p className="tab">
            4.1.1. CANDIDATOS: GENERALES: NOMBRE COMPLETO, SEXO, NACIONALIDAD,
            ESTADO CIVIL, FECHA Y LUGAR DE NACIMIENTO, CORREO ELECTRÓNICO,
            NÚMERO DE SEGURIDAD SOCIAL, FIRMA, FOTOGRAFÍA, TELÉFONO LOCAL Y/O
            MÓVIL, CURP, RFC, PASAPORTE, DOMICILIO (CÓDIGO POSTAL, ESTADO, PAÍS,
            CIUDAD O MUNICIPIO, COLONIA, LOCALIDAD, CALLE Y NÚMERO EXTERIOR E
            INTERIOR).
          </p>
          <p className="tab">
            ACADÉMICOS: NIVEL Y COMPROBANTE DE ESTUDIOS, ESCUELA, PERÍODO DE
            ESTUDIOS, TÍTULO RECIBIDO, MANEJO DE IDIOMAS Y EQUIPOS DE CÓMPUTO Y
            OFICINA, COMPETENCIAS Y HABILIDADES.
          </p>
          <p className="tab">
            EXPERIENCIA PROFESIONAL: EMPRESA, GIRO, TELÉFONO, PERÍODO, ÚLTIMO
            SUELDO, NOMBRE Y PUESTO DE ÚLTIMO JEFE INMEDIATO.
          </p>
          <p className="tab">
            REFERENCIAS PERSONALES (DE LAS CUALES, EL TITULAR ASEGURA CONTAR CON
            EL CONSENTIMIENTO PARA COMPARTIR LOS DATOS PERSONALES DE DICHOS
            TERCEROS): NOMBRE, DOMICILIO (CÓDIGO POSTAL, ESTADO, PAÍS, CIUDAD O
            MUNICIPIO, COLONIA, LOCALIDAD, ENTRE QUÉ CALLES, CALLE Y NÚMERO
            EXTERIOR E INTERIOR), TELÉFONO FIJO Y MÓVIL, OCUPACIÓN, PARENTESCO,
            TIEMPO Y MOTIVO DE CONOCERLO. REFERENCIAS LABORALES: TIEMPO DE
            CONOCERLE Y MOTIVO DE CONOCERLE, EMPRESAS DONDE HA LABORADO, NOMBRE
            DE LOS JEFES INMEDIATOS, SUELDOS PERCIBIDOS, TELÉFONOS, MOTIVOS DE
            SALIDA.
          </p>
          <p className="tab">
            DATOS FAMILIARES: NOMBRES, EDAD, ESCOLARIDAD, TELÉFONO Y OCUPACIÓN
            DE PADRES, HERMANOS, HIJOS, CÓNYUGES, DATOS DE CONTACTO EN CASO DE
            EMERGENCIA.
          </p>
          <p className="tab">
            DATOS ECONÓMICOS: ESTUDIO SOCIO-ECONÓMICO, CUENTAS BANCARIAS,
            INGRESOS Y EGRESOS.
          </p>
          <p className="tab">
            DOCUMENTACIÓN EN COPIA: RFC, CURP, IFE O DOCUMENTACIÓN OFICIAL CON
            FOTOGRAFÍA, COMPROBANTE IMSS, COMPROBANTE DE DOMICILIO, ACTA DE
            NACIMIENTO, ANTECEDENTES NO CRIMINALES, CÉDULA PROFESIONAL.
          </p>
          <p className="tab">
            4.1.2. TRABAJADORES: NOMBRE COMPLETO, SEXO, NACIONALIDAD, ESTADO
            CIVIL, EDAD, FOTOGRAFÍA, FIRMA, HUELLA DIGITAL, NÚMERO DE SEGURIDAD
            SOCIAL, AFORE, FECHA Y LUGAR DE NACIMIENTO, TELÉFONOS FIJO Y MÓVIL,
            DOMICILIO (CÓDIGO POSTAL, ESTADO, PAÍS, CIUDAD O MUNICIPIO, COLONIA,
            LOCALIDAD, CALLE Y NÚMERO EXTERIOR E INTERIOR), CORREO ELECTRÓNICO,
            ESTUDIOS ACTUALES, MANEJO DE IDIOMAS Y EQUIPO DE CÓMPUTO Y OFICINA,
            REFERENCIAS PERSONALES Y PROFESIONALES –DE LAS CUALES, EL TITULAR
            ASEGURA CONTAR CON EL CONSENTIMIENTO PARA COMPARTIR LOS DATOS
            PERSONALES DE DICHOS TERCEROS- (NOMBRE, TELÉFONO, TIEMPO DE
            CONOCERLO Y MOTIVO DE CONOCERLO), ESCOLARIDAD, ÚLTIMO GRADO DE
            ESTUDIOS, CÉDULA PROFESIONAL O COMPROBANTE DE ESTUDIOS, DATOS DE
            CUENTA BANCARIA Y DATOS DE CONTACTO EN CASO DE EMERGENCIA.
          </p>
          <p className="tab">
            DOCUMENTACIÓN EN COPIA: CURRICULUM VITAE, IFE O IDENTIFICACIÓN
            OFICIAL CON FOTOGRAFÍA, CURP, COMPROBANTE IMSS, RFC, COMPROBANTE DE
            DOMICILIO, ACTA DE NACIMIENTO, ESTADO DE CUENTA BANCARIA CON CLABE,
            CÉDULA PROFESIONAL O COMPROBANTE DE ESTUDIOS, CERTIFICACIONES,
            CARTAS DE RECOMENDACIÓN.
          </p>
          <p className="tab">
            4.1.3. VISITANTES A LA PÁGINA DE INTERNET DE FONYOU: NOMBRE COMPLETO
            Y DIRECCIÓN DE CORREO ELECTRÓNICO.
          </p>
          <p className="tab">
            4.1.4. CLIENTES DE RECARGAS: NOMBRE COMPLETO, DIRECCIÓN, CÓDIGO
            POSTAL, DIRECCIÓN DE E-MAIL, NÚMERO DE TELÉFONO PARTICULAR, NÚMERO
            DE TELÉFONO CELULAR, NÚMERO DE TARJETA DE CRÉDITO O DÉBITO, FECHA DE
            EXPIRACIÓN DE LA TARJETA, CVV, NOMBRE DEL BANCO EMISOR DE LA
            TARJETA, NÚMERO DE LA CREDENCIAL DE ELECTOR, INFORMACIÓN SOBRE EL
            MODELO Y MARCA DEL EQUIPO CELULAR, INFORMACIÓN DEMOGRÁFICA, IDIOMA,
            O CUALQUIER INFORMACIÓN ADICIONAL QUE FONYOU PUDIERA NECESITAR PARA
            PROCESAR EL PAGO. EL TIPO DE INFORMACIÓN QUE SOLICITAMOS PODRÁ
            CAMBIAR ESPORÁDICAMENTE DE ACUERDO A LOS SERVICIOS QUE PROVEAMOS.
            ADEMÁS PARA FINES DE CALIDAD Y CAPACITACIÓN, O POR SU PROPIA
            PROTECCIÓN, Y DE ACUERDO CON LAS LEYES APLICABLES, FONYOU PUEDE
            MONITOREAR O GRABAR LAS CONVERSACIONES TELEFÓNICAS QUE TENGAMOS CON
            USTED O CON CUALQUIER PERSONA QUE ACTÚE EN SU NOMBRE. AL COMUNICARSE
            CON FONYOU, USTED RECONOCE QUE LA COMUNICACIÓN PODRÍA SER ESCUCHADA
            POR CASUALIDAD, CONTROLADA O GRABADA SIN NOTIFICACIÓN O ADVERTENCIA.
          </p>
          <p>
            4.2. EL TITULAR EN ESTE ACTO, OTORGA SU CONSENTIMIENTO EXPRESO EN
            TÉRMINOS DEL ARTÍCULO 8 DE LA LFPDPPP, PARA QUE FONYOU, TRATE SUS
            DATOS PERSONALES, LOS FINANCIEROS Y/O PATRIMONIALES CONTENIDOS EN
            ÉSTE PUNTO, PARA CUMPLIR CON LAS FINALIDADES QUE ESTABLECE EL
            PRESENTE AVISO DE PRIVACIDAD.
          </p>
          <p>
            4.3. FONYOU MANIFIESTA QUE PODRÁ OBTENER LOS DATOS PERSONALES DE EL
            TITULAR MEDIANTE LAS DENOMINADAS FUENTES DE ACCESO PÚBLICO, A EFECTO
            DE VALIDAR, ACTUALIZAR Y CONTACTAR A EL TITULAR, RESPETANDO EN TODO
            MOMENTO LA EXPECTATIVA RAZONABLE DE PRIVACIDAD, A QUE SE REFIERE EL
            ARTÍCULO 7 DE LA LFPDPPP. EN ESTA POLÍTICA DE PRIVACIDAD UTILIZAMOS
            EL TÉRMINO "INFORMACIÓN PERSONAL" PARA DESCRIBIR LA INFORMACIÓN QUE
            PUEDE ESTAR RELACIONADA CON UNA PERSONA ESPECÍFICA Y UTILIZARSE PARA
            IDENTIFICAR A ESA PERSONA. NO CONSIDERAMOS INFORMACIÓN PERSONAL LA
            INFORMACIÓN QUE SE HAYA PROPORCIONADO DE FORMA ANÓNIMA DE MODO QUE
            NO PUEDA UTILIZARSE COMBINADA CON OTRA INFORMACIÓN O, DE OTRA FORMA,
            PARA IDENTIFICAR A UN USUARIO ESPECÍFICO.
          </p>
          <p>
            4.4. FONYOU USARÁ INFORMACIÓN IP (PROTOCOLO DE INTERNET, POR SUS
            SIGLAS EN INGLÉS INTERNET PROTOCOL) PARA ANALIZAR CUALQUIER TIPO DE
            AMENAZAS A LA PÁGINA DE INTERNET, ASÍ COMO PARA RECABAR INFORMACIÓN
            DEMOGRÁFICA. SIN EMBARGO, LA INFORMACIÓN IP, EN NINGÚN CASO SERÁ
            UTILIZADA PARA IDENTIFICAR A LOS TITULARES, EXCEPTO CUANDO HAYA
            PROBABILIDADES DE ACTIVIDAD FRAUDULENTA.
          </p>
        </li>
      </ul>
      <h3>5.- FINALIDADES DEL TRATAMIENTO DE LOS DATOS PERSONALES</h3>
      <p>
        5.1. FONYOU ACEPTA Y RECONOCE QUE PODRÁ TRATAR DIRECTAMENTE O A TRAVÉS
        DE ENCARGADOS, LOS DATOS PERSONALES DEL TITULAR, DE CONFORMIDAD AL TIPO
        DE RELACIÓN QUE TIENE CON ÉSTE, PARA LAS SIGUIENTES FINALIDADES
        PRIMARIAS:
      </p>
      <ul>
        <li>
          <h5 className="tab">A) TITULAR-CANDIDATO:</h5>
          <p className="tab-2">
            1) PONERSE EN CONTACTO CON EL TITULAR-CANDIDATO POR CUALQUIER MEDIO
            A EFECTO DE DAR SEGUIMIENTO Y EVALUAR LA EXPERIENCIA Y ESTUDIOS DE
            EL TITULAR, PARA CONOCER LA VIABILIDAD DE SER SELECCIONADO EN EL
            PUESTO QUE PRETENDE DESEMPEÑAR.
          </p>
          <p className="tab-2">
            2) REALIZAR ESTUDIOS SOCIO-ECONÓMICOS PARA VERIFICAR LA INFORMACIÓN
            PROPORCIONADA POR EL TITULAR-CANDIDATO.
          </p>
          <p className="tab-2">
            3) CONOCER LA EXPERIENCIA LABORAL Y ANTECEDENTES DEL EL
            TITULAR-CANDIDATO ASÍ COMO SUS CONOCIMIENTOS Y HABILIDADES PARA EL
            PUESTO QUE PRETENDE DESEMPEÑAR.
          </p>
          <p className="tab-2">
            4) EN SU CASO, REALIZAR LOS TRÁMITES NECESARIOS PARA LA CONTRATACIÓN
            DEL CANDIDATO, TALES COMO ALTA, ANTE EL INSTITUTO MEXICANO DEL
            SEGURO SOCIAL Y DEMÁS INSTITUCIONES QUE SE REQUIERA.
          </p>
          <h5 className="tab">B) TITULAR-TRABAJADOR:</h5>
          <p className="tab-2">
            1) REALIZAR UN EXPEDIENTE EN FORMATO FÍSICO Y/O ELECTRÓNICO DEL
            TITULAR-TRABAJADOR QUE SERÁ RESGUARDADO EN LAS OFICINAS DE FONYOU,
            HASTA POR 10-DIEZ AÑOS POSTERIORES A LA TERMINACIÓN DE LA RELACIÓN
            LABORAL POR CUALQUIER CAUSA.
          </p>
          <p className="tab-2">
            2) REALIZAR LOS TRÁMITES NECESARIOS DE ALTA, BAJA O MODIFICACIONES
            AL REGISTRO DEL TRABAJADOR, ANTE EL INSTITUTO MEXICANO DEL SEGURO
            SOCIAL Y/O LAS INSTITUCIONES QUE SE REQUIERA.
          </p>
          <p className="tab-2">
            3) LLEVAR A CABO EL PROCESO DE PAGO DE SALARIO Y DE PRESTACIONES DEL
            TRABAJADOR, ASÍ COMO DESCUENTOS DE PENSIONES ALIMENTICIAS REQUERIDAS
            POR MANDATO JUDICIAL.
          </p>
          <p className="tab-2">
            4) GESTIONAR CON AEROLÍNEAS, ASÍ COMO CON OTROS MEDIOS DE
            TRANSPORTE, Y HOTELES, LOS VIAJES DE NEGOCIOS QUE REQUIERA HACER EL
            TRABAJADOR.
          </p>
          <p className="tab-2">
            5) EXPEDIR LAS CONSTANCIAS LABORALES QUE LE REQUIERA EL TRABAJADOR.
          </p>
          <p className="tab-2">
            6) LLEVAR A CABO EL CONTROL DE ASISTENCIAS Y HORARIOS DE ENTRADA Y
            SALIDA DEL TITULAR- TRABAJADOR AL LUGAR DE TRABAJO DE FONYOU
            MEDIANTE LAS CREDENCIALES DE ACCESO. DICHOS REGISTROS SERÁN
            CONSERVADOS DURANTE TODA LA VIGENCIA DE LA RELACIÓN LABORAL Y HASTA
            POR 1-UN AÑO POSTERIOR A FINALIZADA LA RELACIÓN LABORAL.
          </p>
          <h5 className="tab">
            C)TITULAR-VISITANTES A LA PÁGINA DE INTERNET DE FONYOU:
          </h5>
          <p className="tab-2">
            1) ENVIAR INFORMACIÓN A LOS VISITANTES A LA PÁGINA DE INTERNET DE
            FONYOU, PREVIA SOLICITUD, A TRAVÉS DE LAS HERRAMIENTAS DISPONIBLES
            EN LA PÁGINA DE FONYOU.
          </p>
          <p className="tab-2">
            2) ALMACENAR EN DIFERENTES BASES DE DATOS, EN FORMATO FÍSICO O
            ELECTRÓNICO, LA INFORMACIÓN DE LOS CLIENTES O PROSPECTOS QUE
            CONTACTEN A FONYOU VÍA TELEFÓNICA, POR CORREO ELECTRÓNICO O POR
            REDES SOCIALES, A EFECTO DE REALIZAR CONSULTAS SOBRE LOS PRODUCTOS
            Y/O SERVICIOS COMERCIALIZADOS; DICHOS REGISTROS SERÁN CONSERVADOS
            POR UN TÉRMINO DE 1-UN AÑO CONTADO A PARTIR DE SU OBTENCIÓN, SIEMPRE
            QUE NO SE CONCRETEN LAS OPERACIONES COMERCIALES CON FONYOU
          </p>
          <h5 className="tab">D) TITULAR-CLIENTES RECARGAS:</h5>
          <p className="tab-2">1) PARA FACILITAR Y COMPLETAR LA TRANSACCIÓN.</p>
          <p className="tab-2">
            2) PARA VALIDAR LA INFORMACIÓN DEL CLIENTE O DE LA TARJETA DE
            CRÉDITO O DÉBITO.
          </p>
          <p className="tab-2">
            3) PARA VERIFICAR Y ANEXAR INFORMACIÓN PARA PREVENIR Y COMBATIR
            FRAUDES.
          </p>
          <p className="tab-2">
            4) PARA CUMPLIR CON LAS REGULACIONES GUBERNAMENTALES, DE LAS
            INSTITUCIONES FINANCIERAS Y DE LAS EMISORAS DE TARJETAS DE CRÉDITO Y
            DÉBITO.
          </p>
          <p className="tab-2">
            5) PARA REALIZAR DIVERSAS INVESTIGACIONES Y ANÁLISIS.
          </p>
          <p className="tab-2">
            6) PARA TENER LAS GRABACIONES DE LAS LLAMADAS CON EL PROPÓSITO DE
            ELABORAR REPORTES INTERNOS Y PROPUESTAS DE SOPORTE.
          </p>
          <p className="tab-2">
            7) PARA RECOPILAR Y TRANSFERIR INFORMACIÓN EN EL MOMENTO EN QUE EL
            USUARIO NO SEA IDENTIFICABLE.
          </p>
          <p className="tab-2">
            8) PARA ALMACENAR EN LA BASE DE DATOS, EN FORMATO FÍSICO O
            ELECTRÓNICO, LA INFORMACIÓN DE LOS CLIENTES.
          </p>
        </li>
        <li>
          <p>
            5.2. FONYOU PODRÁ TRATAR LOS DATOS PERSONALES DEL TITULAR PARA LAS
            SIGUIENTES FINALIDADES SECUNDARIAS:
          </p>
          <p className="tab">
            1) PARA EVALUAR LA CALIDAD DE LOS SERVICIOS OFRECIDOS POR FONYOU,
            ASÍ COMO LA CALIDAD DE ATENCIÓN A LOS COMENTARIOS O SOLICITUDES
            EFECTUADOS A TRAVÉS DE LA PÁGINA DE INTERNET, REDES SOCIALES, O
            SERVICIO DE RECARGAS.
          </p>
          <p className="tab">
            2) REALIZAR ESTUDIOS INTERNOS SOBRE HÁBITOS DE CONSUMO DE LOS
            USUARIOS E INFORMES DEMOGRÁFICOS DE LA PÁGINA DE INTERNET.
          </p>
          <p className="tab">
            3) PARA PROPORCIONARLE COMUNICACIONES ADICIONALES, INFORMACIÓN Y
            PROMOCIONES, TALES COMO BOLETINES INFORMATIVOS, NEWSLETTER E
            INVITACIONES A EVENTOS O FELICITACIONES.
          </p>
          <p className="tab">
            4) EN EL CASO DE CANDIDATOS INFORMAR SOBRE OFERTAS DE EMPLEO TANTO
            PARA FONYOU COMO PARA LOS SOCIOS DE NEGOCIOS DE ÉSTE.
          </p>
          <p className="tab">
            5) INFORMAR VÍA CORREO ELECTRÓNICO SOBRE CAMBIOS O NUEVOS SERVICIOS
            QUE ESTÉN RELACIONADOS CON EL PRODUCTO Y/O SERVICIO SOLICITADO POR
            EL TITULAR.
          </p>
          <p className="tab">
            6) PARA INFORMARLE A LOS VISITANTES Y USUARIOS DE ACTUALIZACIONES DE
            LA PÁGINA DE INTERNET, MENSAJES INFORMATIVOS Y RELATIVOS A
            SERVICIOS, INCLUYENDO ACTUALIZACIONES DE SEGURIDAD IMPORTANTES.
          </p>
          <p className="tab">
            7) GENERAR INFORMES INTERNOS SOBRE LA UTILIZACIÓN DE LA PÁGINA DE
            INTERNET.
          </p>
          <p className="tab">
            8) ENVÍOS MASIVOS DE MAIL MARKETING CON FINES PUBLICITARIOS O
            MERCADOTÉCNICOS, A TRAVÉS DE DIFERENTES MEDIOS ELECTRÓNICOS COMO
            CORREOS ELECTRÓNICOS, SMS, REDES SOCIALES Y WHATSAPP.
          </p>
          <p className="tab">
            9) PARA EL CASO DEL TRABAJADOR, PROPORCIONAR REFERENCIAS SOBRE SU
            DESEMPEÑO Y CARACTERÍSTICAS PERSONALES, A FUTUROS EMPLEADORES, HASTA
            POR UN PERIODO DE 2-DOS AÑOS DESPUÉS DE HABERSE TERMINADO LA
            RELACIÓN LABORAL.
          </p>
          <p className="tab">
            10) PARA EL CASO DEL TRABAJADOR, LA CONTRATACIÓN CON TERCEROS DE
            SEGUROS DE VIDA O DE GASTOS MÉDICOS MAYORES QUE SE OFREZCAN A LOS
            TRABAJADORES DE FONYOU.
          </p>
          <p className="tab">
            11) PARA EL CASO DE LOS CLIENTES DE RECARGAS, PARA AUXILIARLOS EN EL
            PROCEDIMIENTO DE RECARGAS Y SOLUCIONAR PROBLEMAS CON RECARGAS
            ERRÓNEAS O INCOMPLETAS.
          </p>
        </li>
        <li>
          <p>
            5.3. EL TITULAR DEBERÁ TENER AÑO AL MENOS 18 AÑOS DE EDAD PARA HACER
            USO DE LOS SERVICIOS OFRECIDOS POR FONYOU. SI EL PADRE O TUTOR SE DA
            CUENTA DE QUE SUS HIJOS MENORES DE EDAD HAN PROPORCIONADO
            INFORMACIÓN PERSONAL SIN SU CONSENTIMIENTO, NOS PODRÁN CONTACTAR A
            TRAVÉS DE LA CUENTA DE CORREO CONTACTO@FONYOU.COM PARA SOLICITAR LA
            ELIMINACIÓN DE ESTA INFORMACIÓN A LA BREVEDAD.
          </p>
        </li>
        <li>
          <p>
            5.4. EN CASO DE QUE EL TITULAR NO DESEE QUE SUS DATOS PERSONALES
            SEAN UTILIZADOS PARA TODAS O ALGUNAS DE LAS FINALIDADES SECUNDARIAS
            QUE SE ESTABLECEN EN EL APARTADO 5.2., DEBERÁ ENVIAR UNA SOLICITUD
            DE ELIMINACIÓN DE SUS DATOS, ESPECIFICANDO LAS FINALIDADES PARA LAS
            QUE DESEA QUE NO SEAN TRATADOS SUS DATOS PERSONALES, AL SIGUIENTE
            CORREO ELECTRÓNICO: CONTACTO@FONYOU.COM.
          </p>
        </li>
        <li>
          <p>
            5.5. ASIMISMO, FONYOU INFORMA AL TITULAR QUE, SALVO LAS EXCEPCIONES
            QUE SE DESCRIBEN EN EL PUNTO 5.1 Y 5.2 DEL PRESENTE AVISO, TODOS LOS
            DATOS PERSONALES ALMACENADOS POR FONYOU DE LOS QUE NO SE ESTABLEZCA
            UN PLAZO DE CONSERVACIÓN DETERMINADO EN ÉSTE APARTADO, SERÁN
            ALMACENADOS HASTA POR 10-DIEZ AÑOS, UNA VEZ QUE CONCLUYA LA
            FINALIDAD PARA LA QUE FUERON RECABADOS, PARA SU POSTERIOR
            DESTRUCCIÓN.
          </p>
        </li>
      </ul>
      <h3>6.- USO DE “COOKIES” Y “WEB BEACONS”</h3>
      <ul>
        <li>
          <p>
            6.1. ES POSIBLE QUE LA PÁGINA DE INTERNET HAGA USO DE "COOKIES" EN
            CONEXIÓN CON CIERTAS CARACTERÍSTICAS O FUNCIONES. LAS COOKIES SON
            TIPOS ESPECÍFICOS DE INFORMACIÓN QUE UN SITIO WEB TRANSMITE AL DISCO
            DURO DE LA COMPUTADORA DEL TITULAR CON EL FIN DE MANTENER LOS
            REGISTROS. LAS COOKIES PUEDEN SERVIR PARA FACILITAR EL USO DE UN
            SITIO WEB, AL GUARDAR CONTRASEÑAS Y PREFERENCIAS MIENTRAS EL TITULAR
            NAVEGA EN INTERNET. LA PÁGINA DE INTERNET NO USA COOKIES PARA
            OBTENER DATOS DE IDENTIFICACIÓN PERSONAL DE LA COMPUTADORA DEL
            TITULAR QUE NO SE HAYAN ENVIADO ORIGINALMENTE COMO PARTE DE LA
            COOKIE.
          </p>
        </li>
        <li>
          <p>
            6.2. POR SU PARTE, LAS “WEB BEACONS” SON IMÁGENES INSERTADAS EN UNA
            PÁGINA DE INTERNET O CORREO ELECTRÓNICO, QUE PUEDE SER UTILIZADO
            PARA MONITOREAR EL COMPORTAMIENTO DE UN VISITANTE, COMO ALMACENAR
            INFORMACIÓN SOBRE LA DIRECCIÓN IP DEL USUARIO, DURACIÓN DEL TIEMPO
            DE INTERACCIÓN EN DICHA PÁGINA Y EL TIPO DE NAVEGADOR UTILIZADO,
            ENTRE OTROS.
          </p>
        </li>
        <li>
          <p>
            6.3. AUNQUE LA MAYORÍA DE LOS NAVEGADORES ACEPTAN “COOKIES” Y “WEB
            BEACONS” AUTOMÁTICAMENTE, EL TITULAR PUEDE CONFIGURAR SU NAVEGADOR
            PARA QUE NO LOS ACEPTE.
          </p>
        </li>
        <li>
          <p>
            6.4. PARA DESACTIVAR LAS “COOKIES”, DEBE SEGUIR LAS SIGUIENTES
            INSTRUCCIONES:
          </p>
          <a
            href="https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies"
            target="_blank"
            rel="noopener noreferrer"
            className="tab"
          >
            A) INTERNET EXPLORER
          </a>
          <a
            href="https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox"
            target="_blank"
            rel="noopener noreferrer"
            className="tab"
          >
            B) FIREFOX
          </a>
          <a
            href="https://help.opera.com/en/latest/web-preferences/#cookies"
            target="_blank"
            rel="noopener noreferrer"
            className="tab"
          >
            C) OPERA
          </a>
          <a href="/" target="_blank" rel="noopener noreferrer" className="tab">
            D) SAFARI IPAD
          </a>
          <a href="/" target="_blank" rel="noopener noreferrer" className="tab">
            E) SAFARI IPHONE
          </a>
          <a
            href="https://support.google.com/accounts/answer/32050?co=GENIE.Platform%3DDesktop&hl=es"
            target="_blank"
            rel="noopener noreferrer"
            className="tab"
          >
            F) CHROME
          </a>
        </li>
      </ul>
      <h3>
        7.- LIMITACIONES PARA EL ACCESO Y DIVULGACIÓN DE LOS DATOS PERSONALES
      </h3>
      <ul>
        <li>
          <p>
            7.1. FONYOU SE COMPROMETE A REALIZAR SU MEJOR ESFUERZO PARA PROTEGER
            LA SEGURIDAD DE LOS DATOS PERSONALES QUE EL TITULAR LE ESTÁ
            ENTREGANDO, MEDIANTE LA CELEBRACIÓN DE ACTOS JURÍDICOS, EL USO DE
            TECNOLOGÍAS QUE CONTROLEN EL ACCESO, USO O DIVULGACIÓN SIN
            AUTORIZACIÓN DE LA INFORMACIÓN PERSONAL; PARA TAL EFECTO, SE
            ALMACENA LA INFORMACIÓN PERSONAL EN BASES DE DATOS CON ACCESO
            LIMITADO QUE SE ENCUENTRAN EN INSTALACIONES CONTROLADAS CON
            MECANISMOS DE SEGURIDAD; FONYOU, SE COMPROMETE A QUE LA INFORMACIÓN
            PROPORCIONADA POR EL TITULAR, SEA CONSIDERADA CON CARÁCTER
            CONFIDENCIAL, Y UTILIZADA BAJO PLENA PRIVACIDAD.
          </p>
        </li>
        <li>
          <p>
            7.2. EN ESTE TENOR, FONYOU SE OBLIGA A TOMAR LAS MEDIDAS NECESARIAS
            PARA GARANTIZAR QUE LOS ENCARGADOS QUE UTILICE CUMPLAN CON LO
            ESTABLECIDO EN EL PRESENTE AVISO DE PRIVACIDAD.
          </p>
        </li>
        <li>
          <p>
            7.3. ADICIONALMENTE, SE LE INFORMA QUE PARA EFECTOS DE EVITAR
            RECIBIR PUBLICIDAD EN GENERAL, PUEDE REALIZAR SU INSCRIPCIÓN EN EL
            REGISTRO PÚBLICO PARA EVITAR PUBLICIDAD. PARA MAYOR INFORMACIÓN
            PODRÁ REVISAR LA PÁGINA DE INTERNET WEB DE LA PROCURADURÍA FEDERAL
            DE CONSUMIDOR (PROFECO).
          </p>
        </li>
        <li>
          <p>
            7.4. DE IGUAL MANERA FONYOU OFRECE AL TITULAR LA POSIBILIDAD DE
            DARSE DE BAJA DE LAS LISTAS DE ENVÍOS A LAS QUE SE HAYA SUSCRITO,
            PARA LO CUAL DEBERÁ DAR CLIC EN LA OPCIÓN QUE SE LE PRESENTE DENTRO
            DE LOS MENSAJES DE CORREO ELECTRÓNICO QUE RECIBA.
          </p>
        </li>
      </ul>
      <h3>8.- RESPONSABLE DE TRAMITAR LAS SOLICITUDES</h3>
      <ul>
        <li>
          <p>
            8.1. EN CASO DE QUE EL TITULAR NECESITE REVOCAR SU CONSENTIMIENTO,
            ASÍ COMO ACCEDER, RECTIFICAR, CANCELAR, OPONERSE AL TRATAMIENTO DE
            LOS DATOS PERSONALES QUE HA PROPORCIONADO, LO DEBERÁ HACER A TRAVÉS
            DE LA PERSONA DESIGNADA POR FONYOU, CUYOS DATOS SE DESCRIBEN A
            CONTINUACIÓN:
          </p>
          <p>DESIGNADO: BETSABÉ URIARTE CORREO</p>
          <p>ELECTRÓNICO:CONTACTO@FONYOU.COM</p>
        </li>
      </ul>
      <h3>9.- MEDIOS PARA REVOCAR EL CONSENTIMIENTO</h3>
      <ul>
        <li>
          <p>
            9.1. EL TITULAR DE LOS DATOS PERSONALES PODRÁ REVOCAR EL
            CONSENTIMIENTO QUE SE OTORGA CON LA ACEPTACIÓN DEL PRESENTE. DICHA
            REVOCACIÓN DEL CONSENTIMIENTO QUE SE OTORGA POR MEDIOS ELECTRÓNICOS,
            SE DEBERÁ DE HACER OBSERVANDO EL SIGUIENTE PROCEDIMIENTO, UTILIZANDO
            EL SIGUIENTE FORMATO:
          </p>
          <p>
            9.2. ENVIAR UNA SOLICITUD O MENSAJE DE DATOS AL CORREO ELECTRÓNICO
            DEL DESIGNADO EN EL PUNTO 8-OCHO DEL PRESENTE AVISO, MEDIANTE EL
            CUAL SERÁN ATENDIDAS DICHAS SOLICITUDES, EN EL QUE SEÑALE:
          </p>
          <p className="tab">
            9.2.1. EL NOMBRE COMPLETO DEL TITULAR, DOMICILIO Y CORREO
            ELECTRÓNICO PARA RECIBIR LA RESPUESTA QUE SE GENERE CON MOTIVO DE SU
            SOLICITUD
          </p>
          <p className="tab">9.2.2. EL MOTIVO DE SU SOLICITUD</p>
          <p className="tab">
            9.2.3. LOS ARGUMENTOS QUE SUSTENTEN SU SOLICITUD O PETICIÓN
          </p>
          <p className="tab">
            9.2.4. DOCUMENTO OFICIAL QUE ACREDITE SU IDENTIDAD Y QUE DEMUESTRE
            QUE ES QUIEN DICE SER
          </p>
          <p className="tab">
            9.2.5. FECHA A PARTIR DE LA CUAL, SE HACE EFECTIVA LA REVOCACIÓN DE
            SU CONSENTIMIENTO
          </p>
          <p></p>
        </li>
        <li>
          <p>
            9.3. FONYOU NOTIFICARÁ A EL TITULAR, EN UN PLAZO MÁXIMO DE 20
            (VEINTE) DÍAS CONTADOS DESDE LA FECHA EN QUE SE RECIBIÓ LA SOLICITUD
            SOBRE LA REVOCACIÓN DE SU CONSENTIMIENTO, LA RESOLUCIÓN ADOPTADA, A
            EFECTO DE QUE, SI RESULTA PROCEDENTE, SE HAGA EFECTIVA LA MISMA
            DENTRO DE LOS 15 (QUINCE) DÍAS SIGUIENTES A LA FECHA EN QUE SE
            COMUNICA LA RESPUESTA, MEDIANTE UN MENSAJE QUE CONTENGA QUE HA
            EJECUTADO DE TODOS LOS ACTOS TENDIENTES A NO TRATAR LOS DATOS
            PERSONALES DE EL TITULAR.
          </p>
        </li>
      </ul>
      <h3>10.- MEDIOS PARA EJERCER LOS DERECHOS ARCO</h3>
      <p>
        EN CASO DE QUE EL TITULAR NECESITE ACCEDER, RECTIFICAR, CANCELAR U
        OPONERSE A LOS DATOS PERSONALES QUE HA PROPORCIONADO A FONYOU, EL
        TITULAR DEBERÁ SEGUIR EL SIGUIENTE PROCEDIMIENTO, UTILIZANDO EL FORMATO
        QUE SE PONE A DISPOSICIÓN:
      </p>
      <ul>
        <li>
          <p>
            10.1. ENVIAR UN CORREO ELECTRÓNICO EN ATENCIÓN AL DESIGNADO EN EL
            PUNTO 8-OCHO DEL PRESENTE AVISO, MEDIANTE EL CUAL SERÁN ATENDIDAS
            DICHAS SOLICITUDES, SEÑALANDO LO SIGUIENTE:
          </p>
          <p className="tab">
            10.1.1. EL NOMBRE COMPLETO DEL TITULAR, DOMICILIO Y CORREO
            ELECTRÓNICO PARA RECIBIR LA RESPUESTA QUE SE GENERE CON MOTIVO DE SU
            SOLICITUD
          </p>
          <p className="tab">10.1.2. EL MOTIVO DE SU SOLICITUD</p>
          <p className="tab">
            10.1.3. LOS ARGUMENTOS QUE SUSTENTEN SU SOLICITUD O PETICIÓN.
          </p>
          <p className="tab">
            10.1.4. DOCUMENTO OFICIAL QUE ACREDITE SU IDENTIDAD Y QUE DEMUESTRE
            QUE ES QUIEN DICE SER
          </p>
          <p className="tab">
            10.1.5. DESCRIPCIÓN CLARA Y PRECISA DE LOS DATOS PERSONALES RESPECTO
            DE LOS QUE SE BUSCA EJERCER ALGUNO DE LOS DERECHOS ARCO, Y CUALQUIER
            OTRO ELEMENTO O DOCUMENTO QUE FACILITE LA LOCALIZACIÓN DE LOS DATOS
            PERSONALES
          </p>
          <p className="tab">
            10.1.6. TRATÁNDOSE DE SOLICITUDES DE RECTIFICACIÓN DE DATOS
            PERSONALES, EL TITULAR DEBERÁ INDICAR, ADEMÁS DE LO SEÑALADO, LAS
            MODIFICACIONES A REALIZARSE Y APORTAR LA DOCUMENTACIÓN QUE SUSTENTE
            SU PETICIÓN
          </p>
        </li>
        <li>
          <p>
            10.2. FONYOU NOTIFICARÁ AL TITULAR, EN UN PLAZO MÁXIMO DE 20
            (VEINTE) DÍAS CONTADOS DESDE LA FECHA EN QUE SE RECIBIÓ LA SOLICITUD
            DE ACCESO, RECTIFICACIÓN, CANCELACIÓN U OPOSICIÓN, LA RESOLUCIÓN
            ADOPTADA, A EFECTO DE QUE, SI RESULTA PROCEDENTE, SE HAGA EFECTIVA
            LA MISMA DENTRO DE LOS 15 (QUINCE) DÍAS SIGUIENTES A LA FECHA EN QUE
            SE COMUNICA LA RESPUESTA. TRATÁNDOSE DE SOLICITUDES DE ACCESO A
            DATOS PERSONALES, PROCEDERÁ LA ENTREGA PREVIA ACREDITACIÓN DE LA
            IDENTIDAD DEL SOLICITANTE O REPRESENTANTE LEGAL, SEGÚN CORRESPONDA.
          </p>
        </li>
      </ul>
      <h3>11.- TRANSFERENCIA DE DATOS PERSONALES</h3>
      <ul>
        <li>
          <p>
            11.1. FONYOU SE OBLIGA A NO TRANSFERIR O COMPARTIR LOS DATOS A QUE
            SE REFIERE EL PRESENTE AVISO, A FAVOR DE TERCEROS, SALVO EN LOS
            SIGUIENTES CASOS QUE RESULTE NECESARIO PARA CUMPLIR CON LAS
            FINALIDADES DEL PRESENTE AVISO
          </p>
          <p className="tab">
            11.1.1. LOS EMPLEADOS, CONTRATISTAS O CLIENTES QUE ASISTAN CON ALGÚN
            PRODUCTO O SERVICIO QUE PROVEAMOS PARA NUESTROS CLIENTES EN EL
            FUTURO.
          </p>
          <p className="tab">
            11.1.2. SERVICIOS PROVISTOS A TERCEROS POR ACTIVIDAD MERCANTIL, ASÍ
            COMO PARA SOLICITAR ALGUNA EVALUACIÓN FINANCIERA DEL CLIENTE.
          </p>
          <p className="tab">
            11.1.3. SERVICIO PROVISTOS A TERCEROS EN RELACIÓN A SOPORTE
            ADMINISTRATIVO A CLIENTES.
          </p>
          <p className="tab">
            11.1.4. LOS BANCOS Y PROCESADORES DE PAGO EN RELACIÓN CON EL
            PROCESAMIENTO DEL PAGO DE SUS CLIENTES Y AJUSTES DE LOS FONDOS.
          </p>
          <p className="tab">
            11.1.5. EN LA PREVENCIÓN DE FRAUDES, CARTERA VENCIDA, SERVICIOS DE
            EMERGENCIA Y PREVENCIÓN DE ACTOS CRIMINALES SE PODRÁ DEVELAR
            INFORMACIÓN DE LOS CLIENTES A LAS COMPAÑÍAS DE RECUPERACIÓN DE
            CARTERA VENCIDA, AGENCIAS DE PREVENCIÓN DE FRAUDES, AGENCIAS DE
            SEGURIDAD, INSTITUCIONES FINANCIERAS, SERVICIOS DE EMERGENCIA,
            AGENCIAS ANTI-CRIMEN Y COMPAÑÍAS DE TELECOMUNICACIONES.
          </p>
          <p className="tab">
            11.1.6. A LOS PROVEEDORES CONTRATADOS PARA PROCESAR DATOS EN NUESTRO
            NOMBRE Y LA RELACIÓN DE LA PRESTACIÓN DEL SERVICIO DE NUESTROS
            CLIENTES.
          </p>
          <p className="tab">
            11.1.7. PROPORCIONAR INFORMACIÓN Y ESTADÍSTICAS A TERCEROS, SIN QUE
            ESTA INFORMACIÓN PUEDA SER UTILIZADA PARA IDENTIFICAR A NADIE EN
            PARTICULAR.
          </p>
          <p className="tab">
            11.1.8. SE PODRÁ DEVELAR INFORMACIÓN PERSONAL OBTENIDA DE LOS
            CLIENTES PARA PERSONAS O EMPRESAS CON LAS CUALES PLANEAMOS LLEVAR A
            CABO PROMOCIONES U OTRA ACTIVIDAD A TRAVÉS DE LAS CUALES TAMBIÉN SE
            HA OBTENIDO ESTA INFORMACIÓN Y EN CUYO CASO SE LE HA PREGUNTADO A
            LOS CLIENTES SI ESTÁN DISPUESTOS A PARTICIPAR.
          </p>
          <p className="tab">
            11.1.9. SE PODRÁ DEVELAR INFORMACIÓN PERSONAL EN RESPUESTA A UN
            REQUERIMIENTO LEGAL DE LAS AUTORIDADES.
          </p>
          <p>
            11.2. ASIMISMO, AL SER FONYOU UNA EMPRESA CON UN ALCANCE GLOBAL
            PODRÁ REMITIR LOS DATOS PERSONALES DE EL TITULAR A FAVOR DE SUS
            EMPRESAS CONTROLADORAS, SUBSIDIARIAS O AFILIADAS BAJO EL CONTROL
            COMÚN DEL RESPONSABLE O A UNA SOCIEDAD MATRIZ O A CUALQUIER SOCIEDAD
            DEL MISMO GRUPO DEL RESPONSABLE. EN ESTOS SUPUESTOS NO SE REQUERIRÁ
            EL CONSENTIMIENTO DEL TITULAR. EN EL CASO DE QUE FONYOU DECIDA
            CAMBIAR EL MECANISMO DE TRANSFERENCIA DE DATOS A TERCEROS EN EL
            FUTURO, FONYOU SE COMPROMETE A PROVEER UN MECANISMO CONSPICUO,
            CLARO, Y FÁCILMENTE DISPONIBLE PARA QUE EL TITULAR OPTE POR NO
            PERMITIR LA DIVULGACIÓN DE LA INFORMACIÓN.
          </p>
          <p>
            11.3. NO OBSTANTE LO ANTERIOR Y, EN CASO DE QUE SE PRESENTEN
            VULNERACIONES DE SEGURIDAD OCURRIDAS EN CUALQUIER FASE DEL
            TRATAMIENTO, QUE AFECTEN DE FORMA SIGNIFICATIVA LOS DERECHOS
            PATRIMONIALES O MORALES DE LOS TITULARES, ÉSTOS SERÁN INFORMADOS POR
            CORREO ELECTRÓNICO, DE FORMA INMEDIATA, A FIN DE QUE ESTOS ÚLTIMOS
            PUEDAN TOMAR LAS MEDIDAS CORRESPONDIENTES A LA DEFENSA DE SUS
            DERECHOS, DESLINDANDO DE CUALQUIER RESPONSABILIDAD A FONYOU, SI LA
            VULNERACIÓN NO ES IMPUTABLE A ÉL.
          </p>
          <p>
            11.4. FONYOU NO GARANTIZA LA SEGURIDAD DE NINGÚN TIPO DE INFORMACIÓN
            TRANSMITIDA POR TITULAR VÍA ONLINE.
          </p>
        </li>
      </ul>
      <h3>12.- MODIFICACIONES</h3>
      <ul>
        <li>
          <p>
            12.1. PODEMOS MODIFICAR ESTA POLÍTICA DE PRIVACIDAD EN CUALQUIER
            MOMENTO MEDIANTE LA PUBLICACIÓN DE UNA VERSIÓN ACTUALIZADA EN
            NUESTRO SITIO WEB. LA VERSIÓN ACTUALIZADA ENTRARÁ EN VIGOR EN EL
            MOMENTO DE LA FECHA EFECTIVA. ADEMÁS, SI LA VERSIÓN ACTUALIZADA
            INCLUYE UN CAMBIO SUSTANCIAL, SE LO NOTIFICAREMOS CON AL MENOS 30
            DÍAS DE ANTICIPACIÓN PUBLICANDO UNA NOTIFICACIÓN DEL CAMBIO EN LA
            PÁGINA EN LA SECCIÓN "ACTUALIZACIONES DEL AVISO DE PRIVACIDAD" DE
            NUESTRO SITIO WEB. TRANSCURRIDO ESTE PERÍODO DE NOTIFICACIÓN DE 30
            DÍAS, SE CONSIDERARÁ QUE USTED HA ACEPTADO EXPRESAMENTE TODOS LOS
            CAMBIOS REALIZADOS EN EL AVISO DE PRIVACIDAD.
          </p>
        </li>
      </ul>
      <h3>13.- AUTORIDAD GARANTE</h3>
      <ul>
        <li>
          <p>
            SI USTED CONSIDERA QUE SU DERECHO A LA PROTECCIÓN DE SUS DATOS
            PERSONALES HA SIDO LESIONADO POR ALGUNA CONDUCTA U OMISIÓN DE
            NUESTRA PARTE, O PRESUME ALGUNA VIOLACIÓN A LAS DISPOSICIONES
            PREVISTAS EN LA LEY FEDERAL DE PROTECCIÓN DE DATOS PERSONALES EN
            POSESIÓN DE LOS PARTICULARES, SU REGLAMENTO Y DEMÁS ORDENAMIENTOS
            APLICABLES, PODRÁ INTERPONER SU INCONFORMIDAD O DENUNCIA ANTE EL
            INSTITUTO NACIONAL DE TRANSPARENCIA, ACCESO A LA INFORMACIÓN Y
            PROTECCIÓN DE DATOS PERSONALES (INAI). PARA MAYOR INFORMACIÓN, LE
            SUGERIMOS VISITAR SU PÁGINA OFICIAL DE INTERNET WWW.IFAI.ORG.MX.
          </p>
        </li>
      </ul>
      <h3>14.- LEY APLICABLE Y JURISDICCIÓN</h3>
      <ul>
        <li>
          <p>
            14.1. LAS PARTES EXPRESAN QUE EL PRESENTE AVISO, SE REGIRÁ POR LAS
            DISPOSICIONES LEGALES APLICABLES EN LA REPÚBLICA MEXICANA, EN
            ESPECIAL, POR LO DISPUESTO EN LA LEY FEDERAL DE DATOS PERSONALES EN
            POSESIÓN DE LOS PARTICULARES Y SU REGLAMENTO.
          </p>
        </li>
        <li>
          <p>
            14.2. PARA EL CASO DE QUE EXISTA UNA DISPUTA O CONTROVERSIA,
            DERIVADA DE LA INTERPRETACIÓN, EJECUCIÓN O CUMPLIMIENTO DEL AVISO DE
            CUALQUIERA DE LOS DOCUMENTOS QUE DEL MISMO SE DERIVEN, O QUE GUARDEN
            RELACIÓN CON ÉSTE, LAS PARTES AMIGABLEMENTE, BUSCARÁN LLEGAR A UN
            ACUERDO DENTRO DE UN PLAZO DE TREINTA (30) DÍAS NATURALES, CONTADOS
            A PARTIR DE LA FECHA EN QUE SURJA CUALQUIER DIFERENCIA Y SE
            NOTIFIQUE POR ESCRITO SOBRE DICHO EVENTO A LA CONTRAPARTE,
            DEDUCIENDO EL PROCESO DE MEDIACIÓN ANTE EL CENTRO DE JUSTICIA
            ALTERNATIVA DEL DISTRITO FEDERAL, LLEVÁNDOSE AL AMPARO DE LA LEY DE
            JUSTICIA ALTERNATIVA DEL TRIBUNAL SUPERIOR DE JUSTICIA DEL DISTRITO
            FEDERAL Y SU REGLAMENTO INTERNO, VIGENTE AL MOMENTO DE QUE SE
            PRESENTE LA CONTROVERSIA.
          </p>
        </li>
        <li>
          <p>
            14.3. EN CASO DE QUE LAS PARTES NO LLEGUEN A UN ACUERDO, CONVIENEN
            EN ESTE ACTO QUE TODAS LAS DESAVENENCIAS QUE DERIVEN DEL PRESENTE
            AVISO O DE CUALQUIERA DE LOS DOCUMENTOS QUE DEL MISMO SE DERIVEN, O
            QUE GUARDEN RELACIÓN CON ÉSTE O CON AQUÉLLOS, SEAN SOMETIDAS DE
            MANERA DEFINITIVA A LA COMPETENCIA Y LEYES DE LAS AUTORIDADES
            ADMINISTRATIVAS FEDERALES O TRIBUNALES DE LA CIUDAD DE MÉXICO,
            DISTRITO FEDERAL RENUNCIANDO EXPRESAMENTE A CUALQUIER FUERO DISTINTO
            QUE POR RAZONES DE SUS DOMICILIOS PRESENTES O FUTUROS PUDIEREN
            CORRESPONDERLES
          </p>
        </li>
      </ul>
    </div>
  )
}
