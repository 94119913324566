import React from 'react'

import ArrowLeft from '../../assets/img/arrowLeft.svg'
import ArrowRight from '../../assets/img/arrow.svg'

const CarouselControls = ({
  currentCategory,
  bundle,
  topup,
  currentPosition,
  handleNextProduct,
  className,
}) => {
  const items = currentCategory === 'bundle' ? bundle : topup

  return (
    <div className={`claro__container__carouselControls ${className ?? ''}`}>
      <img
        className="claro__container__carouselControls__arrow"
        src={ArrowLeft}
        onClick={() => handleNextProduct('prev')}
        alt="arrow left"
      />
      <div className="claro__container__carouselControls__count">
        {items.map((item, index) => (
          <span
            key={item.name + item.amount}
            data-key={item.name}
            className={currentPosition === index ? 'active' : ''}
          ></span>
        ))}
      </div>
      <img
        className="claro__container__carouselControls__arrow"
        src={ArrowRight}
        onClick={() => handleNextProduct('next')}
        alt="arrow right"
      />
    </div>
  )
}

export default CarouselControls
