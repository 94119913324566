import React from 'react'
import CustomButton from '../CustomButton'

const NextButton = ({ textBtn, enabled, onClick }) => {
  return (
    <div className="claro__container__button">
      <CustomButton
        text={textBtn}
        onClick={onClick}
        customClass="claro__container__button__next"
        enabled={enabled}
      />
    </div>
  )
}

export default NextButton
