import React, { useContext, useEffect } from 'react'

import { useHistory } from 'react-router-dom'

import { generarUuid } from '../../components/GenerarUuid'
import PaymentButton from '../../components/PaymentButton'
import SelectedOffer from '../../components/SelectedOffer'

import ClaroPayContext from '../../context/ClaroPayContext'

import localRoutes from '../../constants/routes'
import Country from '../../constants/Country'

import ClaroPayPaymentOptions from '../ClaroPayPaymentOptions'
import ClaroPayPaymentOptionsWithBi from '../ClaroPayPaymentOptionsWithBi'

const ClaroRecurrence = () => {
  const history = useHistory()

  const claroPayContext = useContext(ClaroPayContext)

  const { countryCode } = Country()

  const {
    recurrenceData,
    setProcessingPayment,
    payWithSavedCardRecurrence,
    payWithAccountBiRecurrence,
    payWithNewAccountBi,
    setResponseService,
    isValidatedFieldsToPayWithSavedCard,
    isEnabledPayWithSavedCard,
    payWithNewCardRecurrence,
    isVisibleSectionAddNewCard,
    setIsVisibleSectionAddNewCard,
    setIsVisibleAddNewPaymentMethod,
    isValidatedFieldsToPayWithNewCard,
    isEnabledPayWithNewCard,
    selectAccountBi,
    iIsVisibleAddNewAccountBi,
    setIsVisibleAddNewAccountBi,
    sendMetrics,
    code,
    canClickButton,
    setCanClickButton,
    setCurrentCategoryMetrics,
  } = claroPayContext

  const {
    routesClaroPay: { claroPayPaymentProcess },
  } = localRoutes

  useEffect(() => {
    setCurrentCategoryMetrics('Paquetes-')
    const metricsData = {
      metricType: 'home',
      metricName: 'Home',
    }

    if (code) {
      sendMetrics(metricsData)
    }
  }, [code])

  const metricBotonPagar = () => {
    const metricsData = {
      metricType: 'CheckOut_',
      metricName: 'BotonPagar',
    }
    sendMetrics(metricsData)
  }

  const handlePayment = async () => {
    setCanClickButton(false)
    setResponseService(null)
    const uuid = generarUuid()
    setProcessingPayment(true)
    setIsVisibleSectionAddNewCard(false)
    metricBotonPagar()
    history.push(claroPayPaymentProcess)
    let response
    const isPaymentValid = isVisibleSectionAddNewCard
      ? isValidatedFieldsToPayWithNewCard()
      : isValidatedFieldsToPayWithSavedCard()

    if (isPaymentValid) {
      response = isVisibleSectionAddNewCard
        ? await payWithNewCardRecurrence(uuid)
        : await payWithSavedCardRecurrence(uuid)
    }
    setResponseService(response)
  }

  const metricBotonPagarBi = () => {
    const metricsData = {
      metricType: 'CheckOut_',
      metricName: 'BotonPagar-Cuenta',
    }
    sendMetrics(metricsData)
  }

  const handlePaymentAccountBi = async () => {
    setCanClickButton(false)
    setResponseService(null)
    const uuid = generarUuid()
    setProcessingPayment(true)
    setIsVisibleAddNewPaymentMethod(false)
    metricBotonPagarBi()
    history.push(claroPayPaymentProcess)
    const response = await payWithAccountBiRecurrence(uuid)
    setResponseService(response)
  }

  const handlePaymentNewAccountBi = async () => {
    setIsVisibleAddNewPaymentMethod(false)
    setResponseService(null)
    setProcessingPayment(true)
    setIsVisibleAddNewAccountBi(true)
    metricBotonPagarBi()
    history.push(claroPayPaymentProcess)
    payWithNewAccountBi()
  }

  const pack = recurrenceData.pack
  const currentCategory = 'bundle'
  const indicator = recurrenceData.indicator

  const textBtn = `Pagar ${pack.currency}${pack.amount}`

  return (
    <div className="claro__container__recurrence">
      <SelectedOffer pack={pack} currentCategory={currentCategory} />

      {countryCode === '507' ? (
        <ClaroPayPaymentOptionsWithBi />
      ) : (
        <ClaroPayPaymentOptions />
      )}

      <PaymentButton
        selectAccountBi={selectAccountBi}
        iIsVisibleAddNewAccountBi={iIsVisibleAddNewAccountBi}
        indicator={indicator}
        countryCode={countryCode}
        textBtn={textBtn}
        handlePaymentNewAccountBi={handlePaymentNewAccountBi}
        handlePaymentAccountBi={handlePaymentAccountBi}
        canClickButton={canClickButton}
        handlePayment={handlePayment}
        isVisibleSectionAddNewCard={isVisibleSectionAddNewCard}
        isEnabledPayWithNewCard={isEnabledPayWithNewCard}
        isEnabledPayWithSavedCard={isEnabledPayWithSavedCard}
      />
    </div>
  )
}

export default ClaroRecurrence
