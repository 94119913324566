import React, { useContext, useEffect } from 'react'

import { useHistory } from 'react-router-dom'

import ClaroPayContext from '../../context/ClaroPayContext'

import { clearLocalStorage, getLocalStorage } from '../../services/storage'

import PaymentButton from '../../components/PaymentButton'
import { generarUuid } from '../../components/GenerarUuid'
import SelectedOffer from '../../components/SelectedOffer'
import MoreOptions from '../../components/MoreOptions'

import localRoutes from '../../constants/routes'
import Country from '../../constants/Country'
import { PRODUCT_SELECTED_HOME } from '../../constants/ClaroPayConstants'

import ClaroPayPaymentOptions from '../ClaroPayPaymentOptions'
import ClaroPayPaymentOptionsWithBi from '../ClaroPayPaymentOptionsWithBi'

const ClaroHomePayment = () => {
  const history = useHistory()

  const { countryCode } = Country()

  const {
    routesClaroPay: { claroPayPaymentProcess, claroHome },
  } = localRoutes

  const {
    isLoading,
    homeProduct,
    selectAccountBi,
    iIsVisibleAddNewAccountBi,
    isVisibleSectionAddNewCard,
    isEnabledPayWithNewCard,
    isEnabledPayWithSavedCard,
    setResponseService,
    setIsVisibleAddNewPaymentMethod,
    setIsVisibleAddNewAccountBi,
    payWithAccountBi,
    payWithNewAccountBi,
    setIsVisibleSectionAddNewCard,
    paymentCard,
    setProcessingPayment,
    sendMetrics,
    canClickButton,
    setCanClickButton,
    setOpcionSeleccionada,
    indicator,
    setHomeProduct,
    getCard,
    homeData,
    setCurrentCategoryMetrics,
  } = useContext(ClaroPayContext)

  const validationProductSelected = async () => {
    const productSelectedConExpiracion = JSON.parse(
      getLocalStorage(PRODUCT_SELECTED_HOME),
    )
    if (productSelectedConExpiracion) {
      const ahora = new Date().getTime()
      if (ahora > productSelectedConExpiracion.expiracion) {
        clearLocalStorage(PRODUCT_SELECTED_HOME)
        history.push(claroHome)
      } else {
        setHomeProduct(productSelectedConExpiracion.product)

        const category = productSelectedConExpiracion.product.productType

        setCurrentCategoryMetrics(
          category === 'TOPUP' ? 'Recarga-' : 'Paquetes-',
        )

        setOpcionSeleccionada(category === 'TOPUP' ? 'Recargas' : 'Superpacks')

        if (!homeData) {
          await getCard()
        }
      }
    } else {
      history.push(claroHome)
    }
  }

  useEffect(() => {
    validationProductSelected()
  }, [])

  const metricBotonPagar = () => {
    const metricsData = {
      metricType: 'CheckOut_',
      metricName: 'BotonPagar',
    }
    sendMetrics(metricsData)
  }

  const handlePayment = async () => {
    setCanClickButton(false)
    setResponseService(null)
    const uuid = generarUuid()
    setProcessingPayment(true)
    setIsVisibleSectionAddNewCard(false)
    metricBotonPagar()
    history.push(claroPayPaymentProcess)
    const response = await paymentCard(uuid)
    setResponseService(response)
  }

  const metricBotonPagarBi = () => {
    const metricsData = {
      metricType: 'CheckOut_',
      metricName: 'BotonPagar-Cuenta',
    }
    sendMetrics(metricsData)
  }

  const handlePaymentAccountBi = async () => {
    setCanClickButton(false)
    setResponseService(null)
    const uuid = generarUuid()
    setProcessingPayment(true)
    setIsVisibleAddNewPaymentMethod(false)
    metricBotonPagarBi()
    history.push(claroPayPaymentProcess)
    const response = await payWithAccountBi(uuid)
    setResponseService(response)
  }

  const handlePaymentNewAccountBi = async () => {
    setIsVisibleAddNewPaymentMethod(false)
    setResponseService(null)
    setProcessingPayment(true)
    setIsVisibleAddNewAccountBi(true)
    metricBotonPagarBi()
    history.push(claroPayPaymentProcess)
    payWithNewAccountBi(homeProduct.productId)
  }

  const handleSelectedProduct = () => {
    const metricsData = {
      metricType: 'CheckOut_',
      metricName: 'MasOpciones',
    }
    sendMetrics(metricsData)
    clearLocalStorage(PRODUCT_SELECTED_HOME)
    setHomeProduct({})
    history.push(claroHome)
  }

  const textBtn = `Pagar ${homeProduct.currency}${homeProduct.amount}`
  const currentCategory =
    homeProduct.productType === 'TOPUP' ? 'topup' : 'bundle'

  return (
    <>
      {!isLoading && homeProduct && Object.keys(homeProduct).length > 0 && (
        <div className="claro__container__homePayment">
          <SelectedOffer pack={homeProduct} currentCategory={currentCategory} />

          <MoreOptions handleSelectedProduct={handleSelectedProduct} />

          {countryCode === '507' ? (
            <ClaroPayPaymentOptionsWithBi />
          ) : (
            <ClaroPayPaymentOptions />
          )}

          <PaymentButton
            selectAccountBi={selectAccountBi}
            iIsVisibleAddNewAccountBi={iIsVisibleAddNewAccountBi}
            indicator={indicator}
            countryCode={countryCode}
            textBtn={textBtn}
            handlePaymentNewAccountBi={handlePaymentNewAccountBi}
            handlePaymentAccountBi={handlePaymentAccountBi}
            canClickButton={canClickButton}
            handlePayment={handlePayment}
            isVisibleSectionAddNewCard={isVisibleSectionAddNewCard}
            isEnabledPayWithNewCard={isEnabledPayWithNewCard}
            isEnabledPayWithSavedCard={isEnabledPayWithSavedCard}
          />
        </div>
      )}
    </>
  )
}

export default ClaroHomePayment
