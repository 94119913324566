import React, { useEffect } from 'react'

import { BrowserRouter, Switch, Route } from 'react-router-dom'

import './App.scss'

import PrivateRoute from './components/PrivateRoute'

import ClaroPayLayout from './containers/ClaroPayLayout'
import ClaroMinecoLayout from './containers/ClaroMinecoLayout'
import ClaroHomeLayout from './containers/ClaroHomeLayout'

import Country from './constants/Country'
import localRoutes from './constants/routes'

import ClaroPrivacity from './views/ClaroTyC/Privacity'
import ClaroTyC from './views/ClaroTyC/TyC_Handler'
import ClaroHomeAuth from './views/ClaroHomeAuth'
import ClaroHomeHe from './views/ClaroHomeHe'
import ClaroHome from './views/ClaroHome'
import ClaroHomePayment from './views/ClaroHomePayment'
import ClaroRecurrence from './views/ClaroRecurrence'
import ClaroPayPaymentProcess from './views/ClaroPayPaymentProcess'
import ClaroBillingProcess from './views/ClaroBillingProcess'
import ClaroAdmProcess from './views/ClaroAdmProcess'
import ClaroSessionBi from './views/ClaroSessionBi'
import ClaroWhoCalledMe from './views/ClaroWhoCalledMe'
import ClaroBuyNowPayLater from './views/ClaroBuyNowPayLater'
import ClaroPagaTuPrestamo from './views/ClaroPagaTuPrestamo'
import ClaroBecasMineco from './views/ClaroBecasMineco'
import ClaroCallBackUssd from './views/ClaroCallBackUssd'
import ClaroBilling from './views/ClaroBilling'
import ClaroAdm from './views/ClaroAdm'
import ClaroDeleteAccountBi from './views/ClaroDeleteAccountBi'
import ClaroDemoBi from './views/ClaroDemoBi'
import ClaroFailureBi from './views/ClaroFailureBi'
import ClaroSendARecharge from './views/ClaroSendARecharge'
import ClaroPruebas from './views/ClaroPruebas'

const App = () => {
  window.addEventListener('pageshow', event => {
    if (event.persisted) {
      window.location.reload()
    }
  })

  const { domain } = Country()

  useEffect(() => {
    document.title = domain
    if (
      window.location.protocol === 'http:' &&
      window.location.protocol !== 'https:' &&
      window.location.hostname !== 'localhost'
    ) {
      window.location.href =
        'https://' +
        window.location.host +
        window.location.pathname +
        window.location.search
    }
  }, [domain])

  const {
    routesClaroPay: {
      claroTyC,
      claroPrivacity,
      claroPayPaymentProcess,
      claroBillingProcess,
      claroAdmProcess,
      claroRecurrence,
      claroWhoCalledMe,
      claroBuyNowPayLater,
      claroPagaTuPrestamo,
      claroBecasMineco,
      claroCallBackUssd,
      claroBilling,
      claroAdm,
      claroSessionBi,
      claroDeleteAccountBi,
      claroDemoBi,
      claroFailureBi,
      claroSendARecharge,
      claroHome,
      claroHomeAuth,
      claroHomePayment,
      claroHomeHe,
      claroPruebas,
    },
  } = localRoutes

  const pathname = window.location.pathname
  const isClaroPay = pathname.includes('landing')
  const isBecasMineco = pathname === claroBecasMineco
  const isDemoBi = pathname === claroDemoBi

  const conditionsPathnameShorts = [
    pathname !== '/a',
    pathname !== '/d',
    pathname !== '/e',
    pathname !== '/f',
    pathname !== '/p',
    pathname !== '/r',
    pathname !== '/u',
    pathname !== '/w',
    pathname !== '/s',
  ]

  const routeMapping = {
    a: claroAdm,
    d: claroPagaTuPrestamo,
    e: claroDeleteAccountBi,
    f: claroBilling,
    p: claroBuyNowPayLater,
    r: claroRecurrence,
    u: claroCallBackUssd,
    w: claroWhoCalledMe,
    s: claroSendARecharge,
  }

  let selectedPathname = null

  conditionsPathnameShorts.forEach(condition => {
    if (condition) {
      selectedPathname = pathname.split('/')[1]
    }
  })

  const isPathnameShorts = conditionsPathnameShorts.some(
    condition => !condition,
  )

  return (
    <BrowserRouter>
      {!isClaroPay && !isBecasMineco && !isDemoBi && !isPathnameShorts && (
        <ClaroHomeLayout>
          <Switch>
            <Route path={claroTyC} exact component={ClaroTyC} />
            <Route path={claroPrivacity} exact component={ClaroPrivacity} />
            <Route
              path={claroPayPaymentProcess}
              exact
              component={ClaroPayPaymentProcess}
            />
            <Route
              path={claroBillingProcess}
              exact
              component={ClaroBillingProcess}
            />
            <Route path={claroHomeAuth} exact component={ClaroHomeAuth} />
            <Route path={claroHomeHe} exact component={ClaroHomeHe} />
            <Route path={claroHome} exact component={ClaroHome} />
            <PrivateRoute
              path={claroHomePayment}
              exact
              component={ClaroHomePayment}
            />
            <PrivateRoute path="*" component={ClaroHome} />
          </Switch>
        </ClaroHomeLayout>
      )}

      {(isClaroPay || isDemoBi) && (
        <ClaroPayLayout>
          <Switch>
            <Route path={claroTyC} exact component={ClaroTyC} />
            <Route path={claroPrivacity} exact component={ClaroPrivacity} />
            <Route
              path={claroPayPaymentProcess}
              exact
              component={ClaroPayPaymentProcess}
            />
            <Route
              path={claroBillingProcess}
              exact
              component={ClaroBillingProcess}
            />
            <Route path={claroAdmProcess} exact component={ClaroAdmProcess} />
            <Route path={claroRecurrence} exact component={ClaroRecurrence} />
            <Route path={claroWhoCalledMe} exact component={ClaroWhoCalledMe} />
            <Route
              path={claroBuyNowPayLater}
              exact
              component={ClaroBuyNowPayLater}
            />
            <Route
              path={claroPagaTuPrestamo}
              exact
              component={ClaroPagaTuPrestamo}
            />
            <Route
              path={claroCallBackUssd}
              exact
              component={ClaroCallBackUssd}
            />
            <Route path={claroBilling} exact component={ClaroBilling} />
            <Route path={claroAdm} exact component={ClaroAdm} />
            <Route path={claroSessionBi} exact component={ClaroSessionBi} />
            <Route
              path={claroDeleteAccountBi}
              exact
              component={ClaroDeleteAccountBi}
            />
            <Route path={claroFailureBi} exact component={ClaroFailureBi} />
            <Route
              path={claroSendARecharge}
              exact
              component={ClaroSendARecharge}
            />
            <Route path={claroDemoBi} exact component={ClaroDemoBi} />
            <Route path={claroPruebas} exact component={ClaroPruebas} />
          </Switch>
        </ClaroPayLayout>
      )}

      {isBecasMineco && (
        <ClaroMinecoLayout>
          <Switch>
            <Route path={claroTyC} exact component={ClaroTyC} />
            <Route path={claroPrivacity} exact component={ClaroPrivacity} />
            <Route path={claroBecasMineco} exact component={ClaroBecasMineco} />
            <Route
              path={claroPayPaymentProcess}
              exact
              component={ClaroPayPaymentProcess}
            />
            <Route
              path={claroBillingProcess}
              exact
              component={ClaroBillingProcess}
            />
          </Switch>
        </ClaroMinecoLayout>
      )}

      {isPathnameShorts && (
        <Switch>
          <Route
            path={`/${selectedPathname}/`}
            exact
            render={props => {
              const parametrosURL = props.location.search.substring(1)
              const selectedRoute = routeMapping[selectedPathname]
              const urlDestino = `${selectedRoute}?code=${parametrosURL}`
              window.location.href = urlDestino
              return null
            }}
          />
        </Switch>
      )}
    </BrowserRouter>
  )
}

export default App
