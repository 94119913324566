import React from 'react'

import { labels } from '../../constants/ClaroPayConstants'

import logoBi from '../../assets/img/logoBiAzul.png'
import circleAccepted from '../../assets/img/circle_accepted.svg'
import circleNotAccepted from '../../assets/img/circle_not_accepted.svg'

const SavedAccount = ({ item, isSelected, handleSelectAccountBi }) => {
  const { CUENTA_BI } = labels
  return (
    <div
      className="claro__container__payment__optionsWithBi__account"
      onClick={() => handleSelectAccountBi(item)}
    >
      <img
        src={isSelected ? circleAccepted : circleNotAccepted}
        alt={`${CUENTA_BI}: ${item.alias}`}
      />
      {`${CUENTA_BI}: ${
        item.alias.length > 12 ? item.alias.slice(0, 12) + '...' : item.alias
      }`}
      <div className="claro__container__payment__optionsWithBi__account__logo">
        <img src={logoBi} alt="logoBi" />
      </div>
    </div>
  )
}

export default SavedAccount
