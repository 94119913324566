const Metrics = {
'ADM_Carrousel_Flecha-Derecha' : '136',
'ADM_Carrousel_Flecha-Izquierda' : '135',
'Botón_Aceptar' : '089',
'Botón_IrPáginaPrincipal' : '091',
'Botón_Paquetes_Continuar-A' : '017',
'Botón_Paquetes_Continuar-B' : '017',
'Botón_Paquetes_Continuar' : '017',
'Botón_Recargas_Continuar-A' : '018',
'Botón_Recargas_Continuar-B' : '018',
'Botón_Recargas_Continuar' : '018',
'Cerrar Sesión' : '010',
'CheckOut_Combo-BotonPagar-Cuenta' : '137',
'CheckOut_Combo-BotonPagar' : '062',
'CheckOut_Combo-CerrarSesión' : '039',
'CheckOut_Combo-MasOpcionesPago' : '037',
'CheckOut_Combo-NuevaCuenta-1' : '034',
'CheckOut_Combo-NuevaCuenta-2' : '035',
'CheckOut_Combo-NuevaCuenta-3' : '036',
'CheckOut_Combo-NuevaTarjeta-1' : '031',
'CheckOut_Combo-NuevaTarjeta-2' : '032',
'CheckOut_Combo-NuevaTarjeta-3' : '033',
'CheckOut_Combo-PagarBanco-1' : '028',
'CheckOut_Combo-PagarBanco-2' : '029',
'CheckOut_Combo-PagarBanco-3' : '030',
'CheckOut_Combo-PagarTC-1' : '025',
'CheckOut_Combo-PagarTC-2' : '026',
'CheckOut_Combo-PagarTC-3' : '027',
'CheckOut_Combo-SaveCard-1' : '022',
'CheckOut_Combo-SaveCard-2' : '023',
'CheckOut_Combo-SaveCard-3' : '024',
'CheckOut_Combo-Seleccion' : '121',
'CheckOut_Combo-TCFecha-1' : '019',
'CheckOut_Combo-TCFecha-2' : '020',
'CheckOut_Combo-TCFecha-3' : '021',
'CheckOut_Combo-VerDetalle' : '099',
'CheckOut_Combo-VerTusOpcionesdePago' : '038',
'CheckOut_Paquetes-BotonPagar_A' : '062',
'CheckOut_Paquetes-BotonPagar_B' : '062',
'CheckOut_Paquetes-BotonPagar-Cuenta_A' : '137',
'CheckOut_Paquetes-BotonPagar-Cuenta_B' : '137',
'CheckOut_Paquetes-BotonPagar-Cuenta' : '137',
'CheckOut_Paquetes-BotonPagar' : '062',
'CheckOut_Paquetes-CerrarSesión_A' : '039',
'CheckOut_Paquetes-CerrarSesión_B' : '039',
'CheckOut_Paquetes-CerrarSesión' : '039',
'CheckOut_Paquetes-MasOpciones_A' : '064',
'CheckOut_Paquetes-MasOpciones_B' : '064',
'CheckOut_Paquetes-MasOpciones' : '064',
'CheckOut_Paquetes-MasOpcionesPago_A' : '037',
'CheckOut_Paquetes-MasOpcionesPago_B' : '037',
'CheckOut_Paquetes-MasOpcionesPago' : '037',
'CheckOut_Paquetes-NuevaCuenta-1_A' : '034',
'CheckOut_Paquetes-NuevaCuenta-1_B' : '034',
'CheckOut_Paquetes-NuevaCuenta-1' : '034',
'CheckOut_Paquetes-NuevaCuenta-2_A' : '035',
'CheckOut_Paquetes-NuevaCuenta-2_B' : '035',
'CheckOut_Paquetes-NuevaCuenta-2' : '035',
'CheckOut_Paquetes-NuevaCuenta-3_A' : '036',
'CheckOut_Paquetes-NuevaCuenta-3_B' : '036',
'CheckOut_Paquetes-NuevaCuenta-3' : '036',
'CheckOut_Paquetes-NuevaTarjeta-1_A' : '031',
'CheckOut_Paquetes-NuevaTarjeta-1_B' : '031',
'CheckOut_Paquetes-NuevaTarjeta-1' : '031',
'CheckOut_Paquetes-NuevaTarjeta-2_A' : '032',
'CheckOut_Paquetes-NuevaTarjeta-2_B' : '032',
'CheckOut_Paquetes-NuevaTarjeta-2' : '032',
'CheckOut_Paquetes-NuevaTarjeta-3_A' : '033',
'CheckOut_Paquetes-NuevaTarjeta-3_B' : '033',
'CheckOut_Paquetes-NuevaTarjeta-3' : '033',
'CheckOut_Paquetes-PagarBanco-1_A' : '028',
'CheckOut_Paquetes-PagarBanco-1_B' : '028',
'CheckOut_Paquetes-PagarBanco-1' : '028',
'CheckOut_Paquetes-PagarBanco-2_A' : '029',
'CheckOut_Paquetes-PagarBanco-2_B' : '029',
'CheckOut_Paquetes-PagarBanco-2' : '029',
'CheckOut_Paquetes-PagarBanco-3_A' : '030',
'CheckOut_Paquetes-PagarBanco-3_B' : '030',
'CheckOut_Paquetes-PagarBanco-3' : '030',
'CheckOut_Paquetes-PagarTC-1_A' : '025',
'CheckOut_Paquetes-PagarTC-1_B' : '025',
'CheckOut_Paquetes-PagarTC-1' : '025',
'CheckOut_Paquetes-PagarTC-2_A' : '026',
'CheckOut_Paquetes-PagarTC-2_B' : '026',
'CheckOut_Paquetes-PagarTC-2' : '026',
'CheckOut_Paquetes-PagarTC-3_A' : '027',
'CheckOut_Paquetes-PagarTC-3_B' : '027',
'CheckOut_Paquetes-PagarTC-3' : '027',
'CheckOut_Paquetes-SaveCard-1_A' : '022',
'CheckOut_Paquetes-SaveCard-1_B' : '022',
'CheckOut_Paquetes-SaveCard-1' : '022',
'CheckOut_Paquetes-SaveCard-2_A' : '023',
'CheckOut_Paquetes-SaveCard-2_B' : '023',
'CheckOut_Paquetes-SaveCard-2' : '023',
'CheckOut_Paquetes-SaveCard-3_A' : '024',
'CheckOut_Paquetes-SaveCard-3_B' : '024',
'CheckOut_Paquetes-SaveCard-3' : '024',
'CheckOut_Paquetes-TCFecha-1_A' : '019',
'CheckOut_Paquetes-TCFecha-1_B' : '019',
'CheckOut_Paquetes-TCFecha-1' : '019',
'CheckOut_Paquetes-TCFecha-2_A' : '020',
'CheckOut_Paquetes-TCFecha-2_B' : '020',
'CheckOut_Paquetes-TCFecha-2' : '020',
'CheckOut_Paquetes-TCFecha-3_A' : '021',
'CheckOut_Paquetes-TCFecha-3_B' : '021',
'CheckOut_Paquetes-TCFecha-3' : '021',
'CheckOut_Paquetes-VerTusOpcionesdePago_A' : '038',
'CheckOut_Paquetes-VerTusOpcionesdePago_B' : '038',
'CheckOut_Paquetes-VerTusOpcionesdePago' : '038',
'CheckOut_Recarga-BotonPagar_A' : '063',
'CheckOut_Recarga-BotonPagar_B' : '063',
'CheckOut_Recarga-BotonPagar-Cuenta_A' : '138',
'CheckOut_Recarga-BotonPagar-Cuenta_B' : '138',
'CheckOut_Recarga-BotonPagar-Cuenta' : '138',
'CheckOut_Recarga-BotonPagar' : '063',
'CheckOut_Recarga-CerrarSesión_A' : '061',
'CheckOut_Recarga-CerrarSesión_B' : '061',
'CheckOut_Recarga-CerrarSesión' : '061',
'CheckOut_Recarga-MasOpciones_A' : '040',
'CheckOut_Recarga-MasOpciones_B' : '040',
'CheckOut_Recarga-MasOpciones' : '040',
'CheckOut_Recarga-MasOpcionesPago_A' : '059',
'CheckOut_Recarga-MasOpcionesPago_B' : '059',
'CheckOut_Recarga-MasOpcionesPago' : '059',
'CheckOut_Recarga-NuevaCuenta-1_A' : '056',
'CheckOut_Recarga-NuevaCuenta-1_B' : '056',
'CheckOut_Recarga-NuevaCuenta-1' : '056',
'CheckOut_Recarga-NuevaCuenta-2_A' : '057',
'CheckOut_Recarga-NuevaCuenta-2_B' : '057',
'CheckOut_Recarga-NuevaCuenta-2' : '057',
'CheckOut_Recarga-NuevaCuenta-3_A' : '058',
'CheckOut_Recarga-NuevaCuenta-3_B' : '058',
'CheckOut_Recarga-NuevaCuenta-3' : '058',
'CheckOut_Recarga-NuevaTarjeta-1_A' : '053',
'CheckOut_Recarga-NuevaTarjeta-1_B' : '053',
'CheckOut_Recarga-NuevaTarjeta-1' : '053',
'CheckOut_Recarga-NuevaTarjeta-2_A' : '054',
'CheckOut_Recarga-NuevaTarjeta-2_B' : '054',
'CheckOut_Recarga-NuevaTarjeta-2' : '054',
'CheckOut_Recarga-NuevaTarjeta-3_A' : '055',
'CheckOut_Recarga-NuevaTarjeta-3_B' : '055',
'CheckOut_Recarga-NuevaTarjeta-3' : '055',
'CheckOut_Recarga-PagarBanco-1_A' : '050',
'CheckOut_Recarga-PagarBanco-1_B' : '050',
'CheckOut_Recarga-PagarBanco-1' : '050',
'CheckOut_Recarga-PagarBanco-2_A' : '051',
'CheckOut_Recarga-PagarBanco-2_B' : '051',
'CheckOut_Recarga-PagarBanco-2' : '051',
'CheckOut_Recarga-PagarBanco-3_A' : '052',
'CheckOut_Recarga-PagarBanco-3_B' : '052',
'CheckOut_Recarga-PagarBanco-3' : '052',
'CheckOut_Recarga-PagarTC-1_A' : '047',
'CheckOut_Recarga-PagarTC-1_B' : '047',
'CheckOut_Recarga-PagarTC-1' : '047',
'CheckOut_Recarga-PagarTC-2_A' : '048',
'CheckOut_Recarga-PagarTC-2_B' : '048',
'CheckOut_Recarga-PagarTC-2' : '048',
'CheckOut_Recarga-PagarTC-3_A' : '049',
'CheckOut_Recarga-PagarTC-3_B' : '049',
'CheckOut_Recarga-PagarTC-3' : '049',
'CheckOut_Recarga-SaveCard-1_A' : '044',
'CheckOut_Recarga-SaveCard-1_B' : '044',
'CheckOut_Recarga-SaveCard-1' : '044',
'CheckOut_Recarga-SaveCard-2_A' : '045',
'CheckOut_Recarga-SaveCard-2_B' : '045',
'CheckOut_Recarga-SaveCard-2' : '045',
'CheckOut_Recarga-SaveCard-3_A' : '046',
'CheckOut_Recarga-SaveCard-3_B' : '046',
'CheckOut_Recarga-SaveCard-3' : '046',
'CheckOut_Recarga-Seleccion' : '122',
'CheckOut_Recarga-TCFecha-1_A' : '041',
'CheckOut_Recarga-TCFecha-1_B' : '041',
'CheckOut_Recarga-TCFecha-1' : '041',
'CheckOut_Recarga-TCFecha-2_A' : '042',
'CheckOut_Recarga-TCFecha-2_B' : '042',
'CheckOut_Recarga-TCFecha-2' : '042',
'CheckOut_Recarga-TCFecha-3_A' : '043',
'CheckOut_Recarga-TCFecha-3_B' : '043',
'CheckOut_Recarga-TCFecha-3' : '043',
'CheckOut_Recarga-VerDetalle' : '098',
'CheckOut_Recarga-VerTusOpcionesdePago_A' : '060',
'CheckOut_Recarga-VerTusOpcionesdePago_B' : '060',
'CheckOut_Recarga-VerTusOpcionesdePago' : '060',
'CintaGris_ADM' : '132',
'CintaGris_EnviaRecarga' : '134',
'CintaGris_PagarDeuda' : '133',
'CintaGris_Recargas' : '131',
'CintaGris_Superpacks' : '130',
'CompletadoDatosAnterior' : '087',
'Completar Datos_Error' : '084',
'Completar Datos_OK' : '086',
'Completar Datos_Proceso' : '085',
'Completar Datos' : '083',
'ConfirmacionBajaCuenta' : '119',
'CuentaAfiliada-Combo' : '105',
'CuentaAfiliada-Paquetes_A' : '105',
'CuentaAfiliada-Paquetes_B' : '105',
'CuentaAfiliada-Paquetes' : '105',
'CuentaAfiliada-Recarga_A' : '106',
'CuentaAfiliada-Recarga_B' : '106',
'CuentaAfiliada-Recarga' : '106',
'CuentaAfiliadaOK_PagoOK_A' : '117',
'CuentaAfiliadaOK_PagoOK_B' : '117',
'CuentaAfiliadaOK_PagoOK' : '117',
'DeudaOk' : '093',
'EliminacionCuentaOK' : '120',
'Error_Afiliación-Combo' : '108',
'Error_Afiliación-Paquetes_A' : '108',
'Error_Afiliación-Paquetes_B' : '108',
'Error_Afiliación-Paquetes' : '108',
'Error_Afiliación-Recarga_A' : '109',
'Error_Afiliación-Recarga_B' : '109',
'Error_Afiliación-Recarga' : '109',
'Error_BAN_A' : '116',
'Error_BAN_B' : '116',
'Error_BAN' : '116',
'Error_CuentaAfiliadaOK_ErrorBanco_A' : '115',
'Error_CuentaAfiliadaOK_ErrorBanco_B' : '115',
'Error_CuentaAfiliadaOK_ErrorBanco' : '115',
'Error_CuentaAfiliadaOK_ProcesoBanco_A' : '114',
'Error_CuentaAfiliadaOK_ProcesoBanco_B' : '114',
'Error_CuentaAfiliadaOK_ProcesoBanco' : '114',
'Error_CuentaAfiliadaOK_SinFondos_A' : '113',
'Error_CuentaAfiliadaOK_SinFondos_B' : '113',
'Error_CuentaAfiliadaOK_SinFondos' : '113',
'Error_CuentaInactiva-Invalida_A' : '110',
'Error_CuentaInactiva-Invalida_B' : '110',
'Error_CuentaInactiva-Invalida' : '110',
'Error_CuentaSinFondos_A' : '111',
'Error_CuentaSinFondos_B' : '111',
'Error_CuentaSinFondos' : '111',
'Error_DeudaYaPagada-BotónRegresarCompra' : '101',
'Error_DeudaYaPagada' : '100',
'Error_ErrorBanco_A' : '112',
'Error_ErrorBanco_B' : '112',
'Error_ErrorBanco' : '112',
'Error_ExisteProblema_A' : '080',
'Error_ExisteProblema_B' : '080',
'Error_ExisteProblema_BotónReintentar_A' : '081',
'Error_ExisteProblema_BotónReintentar_B' : '081',
'Error_ExisteProblema_BotónReintentar' : '081',
'Error_ExisteProblema' : '080',
'Error_General-BotónRegresarCompra_A' : '068',
'Error_General-BotónRegresarCompra_B' : '068',
'Error_General-BotónRegresarCompra' : '068',
'Error_General-TimeOut_A' : '067',
'Error_General-TimeOut_B' : '067',
'Error_General-TimeOut' : '067',
'Error_TCFechaInvalida_A' : '073',
'Error_TCFechaInvalida_B' : '073',
'Error_TCFechaInvalida-BotónIngresarFecha_A' : '074',
'Error_TCFechaInvalida-BotónIngresarFecha_B' : '074',
'Error_TCFechaInvalida-BotónIngresarFecha' : '074',
'Error_TCFechaInvalida' : '073',
'Error_TCRechazada_A' : '076',
'Error_TCRechazada_B' : '076',
'Error_TCRechazada_BotónRegresarCompra_A' : '077',
'Error_TCRechazada_BotónRegresarCompra_B' : '077',
'Error_TCRechazada_BotónRegresarCompra' : '077',
'Error_TCRechazada' : '076',
'Error_VariosIntentos_A' : '078',
'Error_VariosIntentos_B' : '078',
'Error_VariosIntentos_BotónRegresarCompra_A' : '079',
'Error_VariosIntentos_BotónRegresarCompra_B' : '079',
'Error_VariosIntentos_BotónRegresarCompra' : '079',
'Error_VariosIntentos' : '078',
'Exitoso_FEL' : '145',
'ExitosoP_FEL-CompletarDatos_Enviar' : '142',
'ExitosoP_FEL-CompletarDatos_Error' : '140',
'ExitosoP_FEL-CompletarDatos_Regresar' : '144',
'ExitosoR_FEL-CompletarDatos_Enviar' : '141',
'ExitosoR_FEL-CompletarDatos_Error' : '139',
'ExitosoR_FEL-CompletarDatos_Regresar' : '143',
'ExpiradoTiempo' : '088',
'Home' : '009',
'InstalarAPP_A' : '102',
'InstalarAPP_B' : '102',
'InstalarAPP' : '102',
'IrAPP_A' : '103',
'IrAPP_B' : '103',
'IrAPP_Regresar_A' : '104',
'IrAPP_Regresar_B' : '104',
'IrAPP_Regresar' : '104',
'IrAPP' : '103',
'Login_BotonContinuar' : '002',
'Login_Error' : '003',
'Login_HE-Error' : '008',
'Login_HE' : '007',
'Login_Token-BotonValidar' : '004',
'Login_Token-Error' : '006',
'Login_Token-Reenvio' : '005',
'Login' : '001',
'Menu_ADM' : '126',
'Menu_CerrarSesion' : '129',
'Menu_DespliegueMenuPrincipal' : '123',
'Menu_EnviaRecarga' : '128',
'Menu_PagarDeuda' : '127',
'Menu_Recargas' : '125',
'Menu_Superpacks' : '124',
'PagarDeuda' : '096',
'PagoExitoso_OkCombo_IrPaginaPrincipal' : '072',
'PagoExitoso_OkCombo' : '070',
'PagoExitoso_OkPaquete_A' : '070',
'PagoExitoso_OkPaquete_B' : '070',
'PagoExitoso_OkPaquete_IrPaginaPrincipal_A' : '072',
'PagoExitoso_OkPaquete_IrPaginaPrincipal_B' : '072',
'PagoExitoso_OkPaquete_IrPaginaPrincipal' : '072',
'PagoExitoso_OkPaquete' : '070',
'PagoExitoso_OkRecarga_A' : '069',
'PagoExitoso_OkRecarga_B' : '069',
'PagoExitoso_OkRecarga_IrPaginaPrincipal_A' : '071',
'PagoExitoso_OkRecarga_IrPaginaPrincipal_B' : '071',
'PagoExitoso_OkRecarga_IrPaginaPrincipal' : '071',
'PagoExitoso_OkRecarga' : '069',
'PagoPendiente' : '095',
'Paquetes_Carrousel_Flecha-Derecha-A' : '014',
'Paquetes_Carrousel_Flecha-Derecha-B' : '014',
'Paquetes_Carrousel_Flecha-Derecha' : '014',
'Paquetes_Carrousel_Flecha-Izquierda-A' : '013',
'Paquetes_Carrousel_Flecha-Izquierda-B' : '013',
'Paquetes_Carrousel_Flecha-Izquierda' : '013',
'Proceso_BAN_A' : '107',
'Proceso_BAN_B' : '107',
'Proceso_BAN' : '107',
'Proceso_OtrasOpciones' : '066',
'Proceso_Pago_A' : '065',
'Proceso_Pago_B' : '065',
'Proceso_Pago' : '065',
'Proceso_Validación' : '075',
'Recargas_Carrousel_Flecha-Derecha-A' : '016',
'Recargas_Carrousel_Flecha-Derecha-B' : '016',
'Recargas_Carrousel_Flecha-Derecha' : '016',
'Recargas_Carrousel_Flecha-Izquierda-A' : '015',
'Recargas_Carrousel_Flecha-Izquierda-B' : '015',
'Recargas_Carrousel_Flecha-Izquierda' : '015',
'Recargas-A' : '012',
'Recargas-B' : '012',
'Recargas' : '012',
'SelecionCuenta' : '118',
'Superpacks-A' : '011',
'Superpacks-B' : '011',
'Superpacks' : '011',
}

export default Metrics
