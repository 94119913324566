import React, { useRef, useState, useEffect } from 'react'

import { Swiper, SwiperSlide } from 'swiper/react'
import { EffectCoverflow, Pagination, Navigation } from 'swiper/modules'

import 'swiper/css'
import 'swiper/css/effect-coverflow'
import 'swiper/css/navigation'

const CarouselSwiper = ({
  selectedProduct,
  handleSlideChange,
  pack,
  packs,
  currentCategory,
}) => {
  const [slideWidth, setSlideWidth] = useState(0)
  const swiperRef = useRef(null)

  useEffect(() => {
    if (swiperRef.current) {
      const slide = swiperRef.current.querySelector('.swiper-slide')
      if (slide) {
        setSlideWidth(slide.offsetWidth)
      }
    }

    const handleResize = () => {
      if (swiperRef.current) {
        const slide = swiperRef.current.querySelector('.swiper-slide')
        if (slide) {
          setSlideWidth(slide.offsetWidth)
        }
      }
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    const swiperPagination = document.querySelector('.swiper-pagination')
    const swiperButtonPrev = document.querySelector('.swiper-button-prev')
    const swiperButtonNext = document.querySelector('.swiper-button-next')
    const swiperControlsContainer = document.querySelector(
      '.swiper-controls-container',
    )

    if (
      swiperPagination &&
      swiperButtonPrev &&
      swiperButtonNext &&
      swiperControlsContainer
    ) {
      swiperButtonPrev.parentNode.insertBefore(
        swiperPagination,
        swiperButtonPrev.nextSibling,
      )

      swiperButtonPrev.parentNode.insertBefore(
        swiperButtonNext,
        swiperPagination.nextSibling,
      )

      swiperControlsContainer.appendChild(swiperButtonPrev)
      swiperControlsContainer.appendChild(swiperPagination)
      swiperControlsContainer.appendChild(swiperButtonNext)
    }
  }, [selectedProduct])

  useEffect(() => {
    const swiperInstance = swiperRef.current.swiper
    if (swiperInstance) {
      if (packs.length < 4) {
        swiperInstance.params.speed = 0
        swiperInstance.slideNext()
        const timeout = setTimeout(() => {
          swiperInstance.slidePrev()
          swiperInstance.params.speed = 300
        }, 1)
        return () => clearTimeout(timeout)
      }
    }
  }, [packs])

  return (
    <div className="claro__container__carousel">
      <Swiper
        touchStartPreventDefault={false}
        touchStartForcePreventDefault={false}
        effect="coverflow"
        grabCursor
        centeredSlides
        loop
        slidesPerView={2}
        coverflowEffect={{
          rotate: -2,
          stretch: 0,
          depth: 220,
          modifier: 4,
          slideShadows: false,
        }}
        navigation
        pagination={{ clickable: true }}
        modules={[EffectCoverflow, Pagination, Navigation]}
        onSlideChange={handleSlideChange}
        ref={swiperRef}
      >
        {packs.map(packDetail => (
          <SwiperSlide key={packDetail.productId}>
            <div
              className="claro__container__carousel__content"
              style={{
                marginLeft: `calc((${slideWidth}px - 170px) / 2)`,
                marginRight: `calc((${slideWidth}px - 170px) / 2)`,
              }}
            >
              <div
                className={`claro__container__carousel__content__header ${
                  currentCategory === 'topup' ? 'topup' : 'bundle'
                } ${pack.productId === packDetail.productId ? 'active' : ''}`}
              >
                {currentCategory === 'bundle' ? (
                  <>
                    <div className="claro__container__carousel__content__header__title">
                      {packDetail.title}
                    </div>
                    <div className="claro__container__carousel__content__header__subtitle">
                      {packDetail.subtitle} - {packDetail.validity}
                    </div>
                  </>
                ) : (
                  <div className="claro__container__carousel__content__header__subtitle">
                    {packDetail.title}
                  </div>
                )}
              </div>

              <div className="claro__container__carousel__content__body">
                <div className="claro__container__carousel__content__body__amount">
                  {packDetail.currency}
                  {packDetail.amount}
                </div>

                {currentCategory === 'bundle' && (
                  <div className="claro__container__carousel__content__body__description">
                    {packDetail.mainDescription}
                  </div>
                )}
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="swiper-controls-container" />
    </div>
  )
}

export default CarouselSwiper
