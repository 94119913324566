import React from 'react'
import CustomButton from '../CustomButton'

const setCookie = (name, value, minutes) => {
  const expires = new Date(Date.now() + minutes * 60 * 1000).toUTCString()
  document.cookie = `${name}=${value}; expires=${expires}; path=/`
}

const getCookie = name => {
  const cookies = document.cookie.split('; ')
  for (const cookie of cookies) {
    const [key, value] = cookie.split('=')
    if (key === name) return value
  }
  return null
}

const incrementCookie = (name, limit, minutes) => {
  const currentValue = parseInt(getCookie(name)) || 0
  if (currentValue >= limit) return false
  setCookie(name, currentValue + 1, minutes)
  return true
}

const Login = ({
  authMsisdn,
  valueMsisdn,
  valueMsisdnValidated,
  valueCode,
  handleChangeMsisdn,
  handleChangeCode,
  confirmMsisdn,
  fixPhoneNumber,
  resendCode,
  confirmCode,
  isButtonEnabledMsisdn,
  isButtonEnabledCode,
  errorMessageMsisdn,
  errorMessageCode,
  responseDescriptionMsisdn,
  setResponseDescriptionMsisdn,
  responseDescriptionCode,
  setResponseDescriptionCode,
  responseDescriptionMsisdnResend,
  setResponseDescriptionMsisdnResend,
}) => {
  const TOO_MANY_REQUESTS =
    'Te has logueado varias veces. Por tu seguridad, debes esperar 15 minutos'

  const reset = () => {
    window.location.reload()
  }

  const handleConfirmMsisdn = () => {
    const isAllowed = incrementCookie('confirmMsisdnCount', 10, 15)
    if (!isAllowed) {
      setResponseDescriptionMsisdn(TOO_MANY_REQUESTS)
      const timeoutId = setTimeout(() => {
        reset()
      }, 5000)
      return () => clearTimeout(timeoutId)
    }
    confirmMsisdn()
  }

  const handleResendCode = () => {
    const isAllowed = incrementCookie('resendCodeCount', 10, 15)
    if (!isAllowed) {
      setResponseDescriptionMsisdnResend(TOO_MANY_REQUESTS)
      const timeoutId = setTimeout(() => {
        reset()
      }, 5000)
      return () => clearTimeout(timeoutId)
    }
    resendCode()
  }

  const handleConfirmCode = () => {
    const isAllowed = incrementCookie('confirmCodeCount', 10, 15)
    if (!isAllowed) {
      setResponseDescriptionCode(TOO_MANY_REQUESTS)
      const timeoutId = setTimeout(() => {
        reset()
      }, 5000)
      return () => clearTimeout(timeoutId)
    }
    confirmCode()
  }

  return (
    <div className="claro__container__login">
      <div className="claro__container__login__header">
        <div className="claro__container__login__header__text">Hola!!!</div>
        <div className="claro__container__login__header__image">
          <img src={authMsisdn} alt="authMsisdn" />
        </div>
      </div>

      <div className="claro__container__login__body">
        <div
          className={`claro__container__login__body__text ${
            valueMsisdnValidated ? 'disabled' : ''
          }`}
        >
          Para continuar con tu compra, es necesario que ingreses tu número de
          teléfono para la validación de tus datos:
        </div>

        <div className="claro__container__login__body__input">
          <input
            className={`claro__container__login__body__input__form ${
              valueMsisdnValidated ? 'disabled' : ''
            }`}
            id="phoneNumberInput"
            type="text"
            inputMode="numeric"
            pattern="[0-9]{0,9}"
            placeholder="Teléfono"
            value={valueMsisdn}
            onChange={handleChangeMsisdn}
            onClick={handleChangeMsisdn}
            maxLength={8}
            disabled={valueMsisdnValidated}
            autoFocus={!valueMsisdnValidated}
          />
          {responseDescriptionMsisdn && (
            <span className="claro__container__login__body__input__errorMessage">
              {responseDescriptionMsisdn}
            </span>
          )}
          {errorMessageMsisdn && (
            <div className="claro__container__login__body__input__errorMessage">
              {errorMessageMsisdn}
            </div>
          )}
          <CustomButton
            text={!valueMsisdnValidated ? 'Continuar' : 'Corregir # Teléfono'}
            onClick={
              !valueMsisdnValidated ? handleConfirmMsisdn : fixPhoneNumber
            }
            customClass={
              !valueMsisdnValidated
                ? 'claro__container__login__body__input__button'
                : 'claro__container__login__body__input__button__resend'
            }
            enabled={
              !valueMsisdnValidated
                ? isButtonEnabledMsisdn && !responseDescriptionMsisdn
                : true
            }
          />
        </div>

        <div
          className={`claro__container__login__body__text ${
            !valueMsisdnValidated ? 'disabled' : ''
          }`}
        >
          Hemos enviado un SMS con un código de verificación a tu teléfono:
        </div>

        <div className="claro__container__login__body__input">
          <input
            className={`claro__container__login__body__input__form ${
              !valueMsisdnValidated ? 'disabled' : ''
            }`}
            type="text"
            inputMode="numeric"
            pattern="[0-9]{0,9}"
            placeholder="Código de verificación"
            value={valueCode}
            onChange={handleChangeCode}
            onClick={handleChangeCode}
            maxLength={6}
            disabled={!valueMsisdnValidated}
          />
          {responseDescriptionCode && (
            <span className="claro__container__login__body__input__errorMessage">
              {responseDescriptionCode}
            </span>
          )}
          {errorMessageCode && (
            <div className="claro__container__login__body__input__errorMessage">
              {errorMessageCode}
            </div>
          )}

          {valueMsisdnValidated && (
            <>
              <CustomButton
                text="Reenviar código"
                onClick={handleResendCode}
                customClass={
                  'claro__container__login__body__input__button__resend'
                }
                enabled
              />
              {responseDescriptionMsisdnResend && (
                <span className="claro__container__login__body__input__resendMessage">
                  {responseDescriptionMsisdnResend}
                </span>
              )}
              <CustomButton
                text="Continuar"
                onClick={handleConfirmCode}
                customClass="claro__container__login__body__input__button"
                enabled={isButtonEnabledCode && !responseDescriptionCode}
              />
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default Login
