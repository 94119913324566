import { labels } from '../../constants/ClaroPayConstants'

const MoreOptions = ({ handleSelectedProduct }) => {
  const { VIEW_MORE_OPTIONS } = labels

  return (
    <div className="claro__container__moreOptions">
      <u
        className="claro__container__moreOptions__text"
        onClick={handleSelectedProduct}
      >
        {VIEW_MORE_OPTIONS}
      </u>
    </div>
  )
}

export default MoreOptions
