import React from 'react'

import processingImage from '../../assets/img/processingPayment.svg'

const LoadingScreen = () => {
  return (
    <div className="claro__container__process">
      <div className="claro__container__process__waiting">
        <img
          className="claro__container__process__image"
          src={processingImage}
          alt="waiting"
        />
        <p className="claro__container__process__generalMessage">
          Espera un momento… Estamos buscando la mejor opción para ti{' '}
          <span role="img" aria-label="Estrella">
            🌟
          </span>
        </p>
      </div>
    </div>
  )
}

export default LoadingScreen
